import React from 'react'
import { motion } from 'framer-motion'

const ServiceBanner = () => {
    return (
        <div
        className="inner_banner service-banner"
        style={{ backgroundImage: "url(/assets/images/site/service_bg.png)" }}
      >
        <div className="wrapper">
          <div className="banner_detail_1">
            <div className="details-ser">
              <h1 id="heading_line">WEB DEVELOPMENT</h1>
              <h2 id="sub_heading1">
              At Codeflix Web LLC, we don't just build websites – we craft digital experiences that connect with your audience and drive real business growth. Whether you're launching a new business or enhancing an existing one, our web development services are tailored to fit your unique needs.
              </h2>
            </div>
            <div className="arrows">
              <img src="/assets/images/new/arrows-ser.svg" alt="arrows" />
            </div>
            <div class="slider-block">
              <img src="/assets/images/new/sercive_banner.png" alt="" />
            </div>
            {/* <CardSwiper/> */}
          </div>
        </div>
      </div>
    )
}

export default ServiceBanner