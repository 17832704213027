import React, { useEffect, useState } from "react";
import { getBlogs } from "../services/api/api";
import BlogBanner from "../component/BlogBanner"; 
import BlogArticle from "../component/BlogArticle";
import BlogSlider from "../component/BlogSlider";
import { useParams } from "react-router-dom";

const BlogPage = () => {
  
  const [blog, setBlogs] = useState([]);
  let { tag } = useParams();
  console.log(useParams());
  useEffect(() => {
    getBlogs(`tag=${tag??''}`).then((res) => {
      setBlogs(res.data.result.data);
     
    });
  }, []);
 
  const items = [
    {
      id: 1,
      image: "./assets/images/blogs/blog1.jpg",
      title: "Best Web Development Company: Transforming Your Digital Presence Globally",
      name: 'Best Web Development Company: Transforming Your Digital Presence Globally',
      logo:[
       {
        url: "./assets/images/blogs/blog1.jpg",
       }
      ],
      url:"/best-web-development-company",
      description: "In today’s digital-first world, having a strong and functional online presence is crucial for businesses of all sizes. Whether you’re a small startup or an established enterprise, your website serves as the foundation for engaging customers, building brand recognition, and driving sales. That’s where our expert web development services come in. At CODEFLIX WEB LLC, we are recognized as one of the  best web development companies  with a proven track record of delivering custom solutions. We specialize in web design & development services,  web app development services, and ecommerce web development services  tailored to your business needs. Whether you're looking for a new website or an advanced custom  web app development service , our experienced team can make your vision a reality.",
      status: '',
      author: 'codeflixweb',
      date: 'July 29, 2024',
      tags: [
          {
            id: '1',
            name: 'Web Development'
          }, 
        ],
        blogs: [
          {
            id: 1,
            image: './assets/images/blogs/blog1.jpg',
            page_content: "In a fast-paced digital landscape, having a strong online presence is no longer optional it’s essential. At the heart of every successful online strategy lies effective web development. Whether you're a small business, a global corporation, or a personal brand, your website serves as the digital face of your organization. At our company, a trusted leader in the IT sector, we deliver the best web development services in India. Let’s explore why web development is crucial and how it can transform your online presence.",
            tags: [
              {
                id: '1',
                name: 'tag 1'
              },
            ],
          }
        ]
    },{
      id: 8,
      image: "./assets/images/blogs/blog9.jpg",
      title: "We Provide the Best UI/UX Design Solutions for Exceptional User Experiences",
      name: 'We Provide the Best UI/UX Design Solutions for Exceptional User Experiences  ',
      logo:[
       {
        url: "./assets/images/blogs/blog9.jpg",
       }
      ],
      status:'',
      author: 'codeflix',
      date: 'Iune 29, 2024',
      url:"/best-UI-UX-design",
      page_content:"<h2>What is UI Design?</h2> <b> While  UI/UX design are often viewed as separate, they work hand in hand. UI design focuses on visual presentation, whereas UX design ensures the product is functional, usable, and enjoyable. Together, they create cohesive digital experiences, whether it’s for a  UI/UX design website, an app interface design, or responsive web development.",
      description: "While  UI/UX design  are often viewed as separate, they work hand in hand. UI design focuses on visual presentation, whereas  UX design ensures the product is functional, usable, and enjoyable. Together, they create cohesive digital experiences, whether it’s for a UI/UX design website, an app interface design , or responsive web development.",
      tags: [
          {
            id: '5',
            name: 'UI & UX'
          }, 
        ],
        blogs: [
          {
            id: 3,
            image: './assets/images/blogs/blog9.jpg',
            page_content: "We Provide the Best UI/UX Design Solutions for Exceptional User Experiences. In today’s digital world, creating a website UI design or an app UI design...",
            tags: [
              {
                id: '5',
                name: 'UI & UX'
              }, 
            ],
          }
        ]
    },{
      id: 11,
      image: "./assets/images/blogs/blog16.jpg",
      title: "Elevate Your Business with Custom Mobile App Development Services",
      name: 'Elevate Your Business with Custom Mobile App Development Services',
      logo:[
       {
        url: "./assets/images/blogs/blog16.jpg",
       }
      ],
      url: '/custom-mobile-app-development-services',
      status:'',
      author: 'codeflix',
      date: 'October 10, 2024',
      page_content:"<h2>Elevate Your Business with Custom Mobile App Development Services</h2> <p>In the modern digital era, mobile applications are more than just tools they are integral to business growth and customer engagement. From enhancing user experiences to driving revenue, custom mobile app development has become a cornerstone of successful digital strategies. Let’s explore how mobile app development can transform your business and why partnering with the right mobile development company is essential.</p> ",
      description: "In the modern digital era, mobile applications are more than just tools they are integral to business growth and customer engagement. From enhancing user experiences to driving revenue",
      tags: [
          {
            id: '6',
            name: 'App Development'
          }, 
        ],
        blogs: [
          {
            id: 3,
            image: './assets/images/blogs/blog16.jpg',
            page_content: "In the modern digital era, mobile applications are more than just tools—they are integral to business growth and customer engagement. From enhancing user experiences to driving revenue",
            tags: [
              {
                 id: '6',
            name: 'App Development'
              }, 
            ],
          }
        ]
    },
    {
      id: 10,
      image: "./assets/images/blogs/blog15.jpg",
      title: "Leading PHP Development Company in India: Unlocking Your Digital Potential",
      name: 'Leading PHP Development Company in India: Unlocking Your Digital Potential',
      logo:[
       {
        url: "./assets/images/blogs/blog15.jpg",
       }
      ],
      url: '/leading-php-company',
      status:'',
      author: 'codeflix',
      date: 'May 05, 2024',
      page_content:"<h2>Leading PHP Development Company in India: Unlocking Your Digital Potential</h2> ",
      description: "In today’s fast paced digital landscape, businesses require dynamic and scalable web solutions to stay ahead of the competition. Custom PHP development has emerged as a preferred choice for building robust, tailored web applications...",
      tags: [
          {
            id: '3',
            name: 'php Development'
          }, 
        ],
        blogs: [
          {
            id: 3,
            image: './assets/images/blogs/blog15.jpg',
            page_content: "In today’s fast paced digital landscape, businesses require dynamic and scalable web solutions to stay ahead of the competition. Custom PHP development has emerged as a preferred choice for building robust, tailored web applications...",
            tags: [
              {
                id: '3',
                 name: 'php Development'
              }, 
            ],
          }
        ]
    },
    ,{
      id: 9,
      image: "./assets/images/blogs/blog8.jpg",
      title: "Key Principles of UX Design",
      name: 'Key Principles of UX Design',
      logo:[
       {
        url: "./assets/images/blogs/blog8.jpg",
       }
      ],
      status:'',
      author: 'codeflix',
      date: 'November 18, 2024',
      url:"/best-UI-UX-design",
      page_content:"<h2>Key Principles of UX Design</h2> ",
      description: "UX design (User Experience Design) focuses on the overall experience users have with a product or service, ensuring that the product not only looks good but also works well for the user.",
      tags: [
          {
             id: '5',
                name: 'UI & UX',
          }, 
        ],
        blogs: [
          {
            id: 3,
            image: './assets/images/blogs/blog8.jpg',
            page_content: "UX design (User Experience Design) focuses on the overall experience users have with a product or service, ensuring that the product not only looks good but also works well for the user.",
            tags: [
              {
                id: '5',
                name: 'UI & UX',
              }, 
            ],
          }
        ]
    },
     
  ];
  return (
    <>  
    
      {items.length !== 0 ? (
        <>
          <BlogBanner items={items} />
          <BlogSlider items={items} />  
          <BlogArticle items={items} /> 
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default BlogPage;
