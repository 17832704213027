import React from "react";
import { motion } from "framer-motion";

const UIUXDevFlow = () => {
  return (
    <div className="serivces_flow serivces_flow6 ulux_flow6" id="wrap">
      <div className="titles">
        <div className="wrapper">
          {/* <a href="javascript:void(0)" className="btn-blue">
            HEIGHTEN YOUR SPLENDOUR
          </a>
          <h3>Technology We Use</h3>
          <p>
            Reference site about Lorem Ipsum, giving informationon its origins,
            as well as a random Lipsum generator. Reference site about Lorem
            Ipsum, giving informationon its origins, as well as a random Lipsum
            generator.
          </p> */}
        </div>
      </div>
      <div className="wrapper">
        <div className="service_flow_wrap">
          <svg
            width="830"
            height="1547"
            viewBox="0 0 830 1547"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_i_109_225)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 208.501C109.048 208.708 106.351 208.812 103.629 208.812C46.4674 208.812 0.128906 162.474 0.128906 105.312C0.128906 48.151 46.4674 1.8125 103.629 1.8125C124.168 1.8125 143.31 7.79531 159.407 18.1133C173.432 27.1034 188.868 34.8125 205.527 34.8125H554.129C602.178 34.8125 641.129 73.7637 641.129 121.812C641.129 169.861 602.178 208.812 554.129 208.812H119.129C116.632 208.812 114.16 208.707 111.717 208.501Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 208.003L111.718 208L111.678 208.003C109.022 208.208 106.338 208.312 103.629 208.312C46.7436 208.312 0.628906 162.198 0.628906 105.312C0.628906 48.4271 46.7436 2.3125 103.629 2.3125C124.07 2.3125 143.118 8.26633 159.137 18.5342C173.181 27.5359 188.718 35.3125 205.527 35.3125H554.129C601.902 35.3125 640.629 74.0398 640.629 121.812C640.629 169.585 601.902 208.312 554.129 208.312H119.129C116.646 208.312 114.188 208.208 111.759 208.003Z"
              stroke="#00E1DB"
            />
            <circle
              cx="103.629"
              cy="105.312"
              r="82.5"
              fill="url(#paint0_linear_109_225)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="105.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip0_109_225)">
              <path
                d="M104.552 105.771C106.306 104.269 107.421 102.041 107.421 99.556C107.421 95.0434 103.749 91.3721 99.2373 91.3721C97.0511 91.3721 94.996 92.2231 93.4503 93.7689C91.9045 95.3147 91.0534 97.3698 91.0534 99.556C91.0534 101.742 91.9045 103.797 93.4503 105.343C93.6011 105.493 93.7579 105.636 93.9181 105.773C92.1031 106.726 90.5578 108.158 89.4688 109.932C91.8998 112.566 95.3788 114.218 99.2373 114.218C103.095 114.218 106.574 112.566 109.005 109.932C107.916 108.157 106.369 106.724 104.552 105.771Z"
                fill="#1375B2"
              />
              <path
                d="M122.846 100.92C122.846 94.6147 120.39 88.6862 115.931 84.2271C111.472 79.7683 105.544 77.3125 99.2378 77.3125C92.9321 77.3125 87.0036 79.7683 82.5444 84.2271C78.0857 88.6858 75.6299 94.6147 75.6299 100.92C75.6299 107.227 78.0853 113.155 82.5444 117.614C87.0036 122.073 92.9321 124.529 99.2378 124.529C105.544 124.529 111.472 122.073 115.931 117.614C120.39 113.155 122.846 107.227 122.846 100.92ZM99.2378 117.499C90.0964 117.499 82.6594 110.062 82.6594 100.92C82.6594 91.7791 90.0964 84.342 99.2378 84.342C108.379 84.342 115.816 91.7791 115.816 100.92C115.816 110.062 108.38 117.499 99.2378 117.499Z"
                fill="#1375B2"
              />
              <path
                d="M129.865 123.014L122.04 115.188C120.972 116.888 119.705 118.48 118.251 119.934C116.797 121.388 115.205 122.654 113.506 123.722L121.332 131.548C122.508 132.724 124.053 133.312 125.598 133.312C127.144 133.312 128.689 132.724 129.866 131.548C132.218 129.195 132.218 125.367 129.865 123.014Z"
                fill="#1375B2"
              />
            </g>
            <g filter="url(#filter1_i_109_225)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 645.501C109.048 645.708 106.351 645.812 103.629 645.812C46.4674 645.812 0.128906 599.474 0.128906 542.312C0.128906 485.151 46.4674 438.812 103.629 438.812C124.168 438.812 143.31 444.795 159.407 455.113C173.432 464.103 188.868 471.812 205.527 471.812H554.129C602.178 471.812 641.129 510.764 641.129 558.812C641.129 606.861 602.178 645.812 554.129 645.812H119.129C116.632 645.812 114.16 645.707 111.717 645.501Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 645.003L111.718 645L111.678 645.003C109.022 645.208 106.338 645.312 103.629 645.312C46.7436 645.312 0.628906 599.198 0.628906 542.312C0.628906 485.427 46.7436 439.312 103.629 439.312C124.07 439.312 143.118 445.266 159.137 455.534C173.181 464.536 188.718 472.312 205.527 472.312H554.129C601.902 472.312 640.629 511.04 640.629 558.812C640.629 606.585 601.902 645.312 554.129 645.312H119.129C116.646 645.312 114.188 645.208 111.759 645.003Z"
              stroke="#00E1DB"
            />
            <circle
              cx="103.629"
              cy="542.312"
              r="82.5"
              fill="url(#paint1_linear_109_225)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="542.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip1_109_225)">
              <path
                d="M112.938 535.866L107.895 530.822C107.587 530.515 107.17 530.342 106.734 530.342C106.299 530.342 105.882 530.515 105.574 530.822L76.1105 560.286C75.8027 560.594 75.6299 561.011 75.6299 561.446C75.6299 561.881 75.8027 562.299 76.1105 562.606L81.1539 567.65C81.4742 567.97 81.8941 568.13 82.3139 568.13C82.7337 568.13 83.1537 567.97 83.4739 567.65L112.938 538.186C113.579 537.545 113.579 536.507 112.938 535.866Z"
                fill="#0051C4"
              />
              <path
                d="M81.4521 530.999C84.5313 530.999 87.0364 533.504 87.0364 536.583C87.0364 537.489 87.771 538.223 88.677 538.223C89.5831 538.223 90.3176 537.489 90.3176 536.583C90.3176 533.504 92.8227 530.999 95.9018 530.999C96.8079 530.999 97.5424 530.264 97.5424 529.358V529.34C97.5424 528.434 96.8079 527.699 95.9018 527.699C92.8228 527.699 90.3176 525.194 90.3176 522.115C90.3176 521.209 89.5831 520.475 88.677 520.475C87.771 520.475 87.0364 521.209 87.0364 522.115C87.0364 525.194 84.5314 527.699 81.4521 527.699C80.5461 527.699 79.8115 528.434 79.8115 529.34V529.358C79.8115 530.264 80.546 530.999 81.4521 530.999Z"
                fill="#0051C4"
              />
              <path
                d="M129.99 525.707C125.815 525.707 122.419 522.31 122.419 518.136C122.419 517.23 121.684 516.495 120.778 516.495C119.872 516.495 119.138 517.23 119.138 518.136C119.138 522.31 115.741 525.707 111.566 525.707C110.66 525.707 109.926 526.441 109.926 527.348V527.37C109.926 528.277 110.66 529.011 111.566 529.011C115.741 529.011 119.138 532.408 119.138 536.582C119.138 537.488 119.872 538.223 120.778 538.223C121.684 538.223 122.419 537.488 122.419 536.582C122.419 532.408 125.815 529.011 129.99 529.011C130.896 529.011 131.631 528.277 131.631 527.37V527.348C131.631 526.441 130.896 525.707 129.99 525.707Z"
                fill="#0051C4"
              />
              <path
                d="M120.777 552.748C117.893 552.748 115.547 550.402 115.547 547.518C115.547 546.612 114.812 545.877 113.906 545.877C113 545.877 112.265 546.612 112.265 547.518C112.265 550.402 109.919 552.748 107.035 552.748C106.129 552.748 105.395 553.483 105.395 554.389V554.406C105.395 555.312 106.129 556.046 107.035 556.046C109.919 556.046 112.265 558.393 112.265 561.277C112.265 562.183 113 562.917 113.906 562.917C114.812 562.917 115.547 562.183 115.547 561.277C115.547 558.393 117.893 556.046 120.777 556.046C121.683 556.046 122.418 555.312 122.418 554.406V554.389C122.418 553.483 121.683 552.748 120.777 552.748Z"
                fill="#0051C4"
              />
            </g>
            <g filter="url(#filter2_i_109_225)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 1079.5C109.048 1079.71 106.351 1079.81 103.629 1079.81C46.4674 1079.81 0.128906 1033.47 0.128906 976.312C0.128906 919.151 46.4674 872.812 103.629 872.812C124.168 872.812 143.31 878.795 159.407 889.113C173.432 898.103 188.868 905.812 205.527 905.812H554.129C602.178 905.812 641.129 944.764 641.129 992.812C641.129 1040.86 602.178 1079.81 554.129 1079.81H119.129C116.632 1079.81 114.16 1079.71 111.717 1079.5Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 1079L111.718 1079L111.678 1079C109.022 1079.21 106.338 1079.31 103.629 1079.31C46.7436 1079.31 0.628906 1033.2 0.628906 976.312C0.628906 919.427 46.7436 873.312 103.629 873.312C124.07 873.312 143.118 879.266 159.137 889.534C173.181 898.536 188.718 906.312 205.527 906.312H554.129C601.902 906.312 640.629 945.04 640.629 992.812C640.629 1040.59 601.902 1079.31 554.129 1079.31H119.129C116.646 1079.31 114.188 1079.21 111.759 1079Z"
              stroke="url(#paint2_linear_109_225)"
            />
            <circle
              cx="103.629"
              cy="976.312"
              r="82.5"
              fill="url(#paint3_linear_109_225)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="976.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip2_109_225)">
              <path
                d="M83.9531 972.609H99.2656V975.891H83.9531V972.609Z"
                fill="#1BB738"
              />
              <path
                d="M83.9531 985.734H99.2656V989.016H83.9531V985.734Z"
                fill="#1BB738"
              />
              <path
                d="M106.078 958.078C106.333 957.925 106.624 957.844 106.922 957.844H124.969V952.922C124.969 950.208 122.761 948 120.047 948H80.6719C77.958 948 75.75 950.208 75.75 952.922V961.125H100.999L106.078 958.078ZM83.9531 956.203C83.0471 956.203 82.3125 955.469 82.3125 954.562C82.3125 953.656 83.0471 952.922 83.9531 952.922C84.8592 952.922 85.5938 953.656 85.5938 954.562C85.5938 955.469 84.8592 956.203 83.9531 956.203ZM90.5156 956.203C89.6096 956.203 88.875 955.469 88.875 954.562C88.875 953.656 89.6096 952.922 90.5156 952.922C91.4217 952.922 92.1562 953.656 92.1562 954.562C92.1562 955.469 91.4217 956.203 90.5156 956.203ZM97.0781 956.203C96.1721 956.203 95.4375 955.469 95.4375 954.562C95.4375 953.656 96.1721 952.922 97.0781 952.922C97.9842 952.922 98.7188 953.656 98.7188 954.562C98.7188 955.469 97.9842 956.203 97.0781 956.203Z"
                fill="#1BB738"
              />
              <path
                d="M128.469 969H115.344C113.532 969 112.062 970.469 112.062 972.281V995.797H131.75V972.281C131.75 970.469 130.281 969 128.469 969ZM124.094 977.203H119.719C118.813 977.203 118.078 976.469 118.078 975.562C118.078 974.656 118.813 973.922 119.719 973.922H124.094C125 973.922 125.734 974.656 125.734 975.562C125.734 976.469 125 977.203 124.094 977.203Z"
                fill="#1BB738"
              />
              <path
                d="M112.062 1000.72C112.062 1002.53 113.532 1004 115.344 1004H128.469C130.281 1004 131.75 1002.53 131.75 1000.72V999.078H112.062V1000.72Z"
                fill="#1BB738"
              />
              <path
                d="M124.969 965.719V961.125H107.376L102.297 964.172C102.042 964.325 101.751 964.406 101.453 964.406H75.75V992.297C75.75 995.011 77.958 997.219 80.6719 997.219H108.781V972.281C108.781 968.663 111.725 965.719 115.344 965.719H124.969ZM102.547 990.656C102.547 991.562 101.812 992.297 100.906 992.297H82.3125C81.4064 992.297 80.6719 991.562 80.6719 990.656V984.094C80.6719 983.188 81.4064 982.453 82.3125 982.453H100.906C101.812 982.453 102.547 983.188 102.547 984.094V990.656ZM102.547 977.531C102.547 978.437 101.812 979.172 100.906 979.172H82.3125C81.4064 979.172 80.6719 978.437 80.6719 977.531V970.969C80.6719 970.063 81.4064 969.328 82.3125 969.328H100.906C101.812 969.328 102.547 970.063 102.547 970.969V977.531Z"
                fill="#1BB738"
              />
            </g>
            <g filter="url(#filter3_i_109_225)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 1515.5C109.048 1515.71 106.351 1515.81 103.629 1515.81C46.4674 1515.81 0.128906 1469.47 0.128906 1412.31C0.128906 1355.15 46.4674 1308.81 103.629 1308.81C124.168 1308.81 143.31 1314.8 159.407 1325.11C173.432 1334.1 188.868 1341.81 205.527 1341.81H554.129C602.178 1341.81 641.129 1380.76 641.129 1428.81C641.129 1476.86 602.178 1515.81 554.129 1515.81H119.129C116.632 1515.81 114.16 1515.71 111.717 1515.5Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 1515L111.718 1515L111.678 1515C109.022 1515.21 106.338 1515.31 103.629 1515.31C46.7436 1515.31 0.628906 1469.2 0.628906 1412.31C0.628906 1355.43 46.7436 1309.31 103.629 1309.31C124.07 1309.31 143.118 1315.27 159.137 1325.53C173.181 1334.54 188.718 1342.31 205.527 1342.31H554.129C601.902 1342.31 640.629 1381.04 640.629 1428.81C640.629 1476.59 601.902 1515.31 554.129 1515.31H119.129C116.646 1515.31 114.188 1515.21 111.759 1515Z"
              stroke="url(#paint4_linear_109_225)"
            />
            <circle
              cx="103.629"
              cy="1412.31"
              r="82.5"
              fill="url(#paint5_linear_109_225)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="1412.31"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip3_109_225)">
              <path
                d="M75.6299 1384.31H79.7282V1388.41H75.6299V1384.31Z"
                fill="#14A99F"
              />
              <path
                d="M75.6299 1436.21H79.7282V1440.31H75.6299V1436.21Z"
                fill="#14A99F"
              />
              <path
                d="M127.531 1384.31H131.63V1388.41H127.531V1384.31Z"
                fill="#14A99F"
              />
              <path
                d="M127.531 1436.21H131.63V1440.31H127.531V1436.21Z"
                fill="#14A99F"
              />
              <path
                d="M129.582 1417.23H125.068V1407.39H129.582V1391.69H124.251V1386.36H108.552V1390.88H98.7082V1386.36H83.0096V1391.69H77.6787V1407.39H82.1926V1417.23H77.6787V1432.93H83.0096V1438.39H98.7082V1433.75H108.552V1438.39H124.251V1432.93H129.582V1417.23ZM115.935 1420.45L109.714 1426.54H91.3254V1404.65L97.8923 1398.08H107.841V1410.14H115.935V1420.45Z"
                fill="#14A99F"
              />
              <path
                d="M104.56 1401.36H99.2509L95.9434 1404.67H101.169V1416.7H109.372V1422.29L112.654 1419.07V1413.42H104.56V1401.36Z"
                fill="#14A99F"
              />
              <path
                d="M97.8877 1419.98C97.8877 1417.15 97.8877 1410.72 97.8877 1407.95H94.6064V1423.26H106.091C106.091 1423.1 106.091 1420.24 106.091 1419.98C104.593 1419.98 99.4701 1419.98 97.8877 1419.98Z"
                fill="#14A99F"
              />
              <path
                d="M105.271 1384.31H101.989V1387.59H105.271V1384.31Z"
                fill="#14A99F"
              />
              <path
                d="M128.349 1410.67V1413.95H131.63V1410.67C130.86 1410.67 129.101 1410.67 128.349 1410.67Z"
                fill="#14A99F"
              />
              <path
                d="M78.9111 1413.95V1410.67C78.1649 1410.67 76.3934 1410.67 75.6299 1410.67V1413.95H78.9111Z"
                fill="#14A99F"
              />
              <path
                d="M101.989 1440.31H105.271C105.271 1439.52 105.271 1437.82 105.271 1437.03H101.989V1440.31Z"
                fill="#14A99F"
              />
            </g>
            <g filter="url(#filter4_i_109_225)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M715.541 862.501C718.21 862.708 720.907 862.812 723.629 862.812C780.79 862.812 827.129 816.474 827.129 759.312C827.129 702.151 780.79 655.812 723.629 655.812C703.09 655.812 683.948 661.795 667.851 672.113C653.825 681.103 638.39 688.812 621.731 688.812H273.129C225.08 688.812 186.129 727.764 186.129 775.812C186.129 823.861 225.08 862.812 273.129 862.812H708.129C710.626 862.812 713.098 862.707 715.541 862.501Z"
                fill="white"
              />
            </g>
            <path
              d="M715.499 862.003L715.539 862L715.58 862.003C718.236 862.208 720.92 862.312 723.629 862.312C780.514 862.312 826.629 816.198 826.629 759.312C826.629 702.427 780.514 656.312 723.629 656.312C703.188 656.312 684.139 662.266 668.121 672.534C654.077 681.536 638.54 689.312 621.731 689.312H273.129C225.356 689.312 186.629 728.04 186.629 775.812C186.629 823.585 225.356 862.312 273.129 862.312H708.129C710.612 862.312 713.07 862.208 715.499 862.003Z"
              stroke="url(#paint6_radial_109_225)"
            />
            <circle
              cx="82.5"
              cy="82.5"
              r="82.5"
              transform="matrix(-1 0 0 1 806.129 676.812)"
              fill="url(#paint7_linear_109_225)"
            />
            <circle
              opacity="0.9"
              cx="59.1949"
              cy="59.1949"
              r="59.1949"
              transform="matrix(-1 0 0 1 782.823 700.117)"
              fill="white"
            />
            <path
              d="M725.94 749.781L723.498 745.887L721.056 749.781C720.832 750.14 720.476 750.398 720.066 750.501L715.607 751.62L718.557 755.145C718.829 755.47 718.964 755.887 718.935 756.31L718.621 760.895L722.886 759.18C723.279 759.022 723.717 759.022 724.11 759.18L728.375 760.895L728.061 756.31C728.032 755.887 728.167 755.47 728.439 755.145L731.388 751.62L726.93 750.501C726.52 750.398 726.165 750.14 725.94 749.781Z"
              fill="#DDCA27"
            />
            <path
              d="M723.499 731C701.168 731.117 692.975 760.473 711.938 772.151V773.302C711.938 774.327 712.191 775.295 712.639 776.145H734.359C734.807 775.295 735.06 774.327 735.06 773.302V772.151C754.032 760.465 745.817 731.113 723.499 731ZM735.585 751.718L731.38 756.744L731.828 763.282C731.866 763.846 731.612 764.39 731.155 764.722C730.697 765.055 730.1 765.127 729.579 764.917L723.499 762.47L717.419 764.917C716.895 765.127 716.299 765.054 715.842 764.722C715.385 764.39 715.131 763.846 715.17 763.282L715.617 756.744L711.412 751.718C711.05 751.285 710.936 750.695 711.11 750.158C711.285 749.621 711.724 749.211 712.272 749.074L718.628 747.479L722.109 741.927C722.409 741.448 722.934 741.158 723.499 741.158C724.064 741.158 724.589 741.448 724.889 741.927L728.37 747.479L734.726 749.074C735.882 749.343 736.363 750.821 735.585 751.718Z"
              fill="#DDCA27"
            />
            <path
              d="M714.935 780.875C714.935 784.252 717.682 787 721.06 787H725.939C729.316 787 732.064 784.252 732.064 780.875V779.427H714.935V780.875Z"
              fill="#DDCA27"
            />
            <g filter="url(#filter5_i_109_225)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M715.541 1296.5C718.21 1296.71 720.907 1296.81 723.629 1296.81C780.79 1296.81 827.129 1250.47 827.129 1193.31C827.129 1136.15 780.79 1089.81 723.629 1089.81C703.09 1089.81 683.948 1095.8 667.851 1106.11C653.825 1115.1 638.39 1122.81 621.731 1122.81H273.129C225.08 1122.81 186.129 1161.76 186.129 1209.81C186.129 1257.86 225.08 1296.81 273.129 1296.81H708.129C710.626 1296.81 713.098 1296.71 715.541 1296.5Z"
                fill="white"
              />
            </g>
            <path
              d="M715.499 1296L715.539 1296L715.58 1296C718.236 1296.21 720.92 1296.31 723.629 1296.31C780.514 1296.31 826.629 1250.2 826.629 1193.31C826.629 1136.43 780.514 1090.31 723.629 1090.31C703.188 1090.31 684.139 1096.27 668.121 1106.53C654.077 1115.54 638.54 1123.31 621.731 1123.31H273.129C225.356 1123.31 186.629 1162.04 186.629 1209.81C186.629 1257.59 225.356 1296.31 273.129 1296.31H708.129C710.612 1296.31 713.07 1296.21 715.499 1296Z"
              stroke="url(#paint8_linear_109_225)"
            />
            <circle
              cx="82.5"
              cy="82.5"
              r="82.5"
              transform="matrix(-1 0 0 1 806.129 1110.81)"
              fill="url(#paint9_linear_109_225)"
            />
            <circle
              opacity="0.9"
              cx="59.1949"
              cy="59.1949"
              r="59.1949"
              transform="matrix(-1 0 0 1 782.823 1134.12)"
              fill="white"
            />
            <g clip-path="url(#clip4_109_225)">
              <path
                d="M699.141 1221H722.109V1197.92H699.141V1221ZM706.863 1208.25L708.984 1210.37L714.387 1204.96L716.707 1207.28L708.984 1215.01L704.543 1210.57L706.863 1208.25Z"
                fill="#1ED38D"
              />
              <path
                d="M708.984 1191.36C716.233 1191.36 722.109 1185.48 722.109 1178.23C722.109 1170.99 716.233 1165 708.984 1165C701.736 1165 695.75 1170.99 695.75 1178.23C695.75 1185.48 701.736 1191.36 708.984 1191.36ZM704.062 1178.23C704.062 1180.95 706.27 1183.16 708.984 1183.16C711.698 1183.16 713.906 1180.95 713.906 1178.23H717.188C717.188 1182.76 713.507 1186.44 708.984 1186.44C704.461 1186.44 700.781 1182.76 700.781 1178.23H704.062Z"
                fill="#1ED38D"
              />
              <path
                d="M738.516 1165C731.267 1165 725.391 1170.99 725.391 1178.23C725.391 1185.48 731.267 1191.36 738.516 1191.36C745.764 1191.36 751.75 1185.48 751.75 1178.23C751.75 1170.99 745.764 1165 738.516 1165ZM743.438 1184.8C743.438 1182.08 741.23 1179.88 738.516 1179.88C735.802 1179.88 733.594 1182.08 733.594 1184.8H730.312C730.312 1180.27 733.993 1176.59 738.516 1176.59C743.039 1176.59 746.719 1180.27 746.719 1184.8H743.438Z"
                fill="#1ED38D"
              />
              <path
                d="M725.391 1197.92H748.359V1221H725.391V1197.92Z"
                fill="#1ED38D"
              />
            </g>
            <g filter="url(#filter6_i_109_225)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M717.541 425.501C720.21 425.708 722.907 425.812 725.629 425.812C782.79 425.812 829.129 379.474 829.129 322.312C829.129 265.151 782.79 218.812 725.629 218.812C705.09 218.812 685.948 224.795 669.851 235.113C655.825 244.103 640.39 251.812 623.731 251.812H275.129C227.08 251.812 188.129 290.764 188.129 338.812C188.129 386.861 227.08 425.812 275.129 425.812H710.129C712.626 425.812 715.098 425.707 717.541 425.501Z"
                fill="white"
              />
            </g>
            <path
              d="M717.499 425.003L717.539 425L717.58 425.003C720.236 425.208 722.92 425.312 725.629 425.312C782.514 425.312 828.629 379.198 828.629 322.312C828.629 265.427 782.514 219.312 725.629 219.312C705.188 219.312 686.139 225.266 670.121 235.534C656.077 244.536 640.54 252.312 623.731 252.312H275.129C227.356 252.312 188.629 291.04 188.629 338.812C188.629 386.585 227.356 425.312 275.129 425.312H710.129C712.612 425.312 715.07 425.208 717.499 425.003Z"
              stroke="#00E1DB"
            />
            <circle
              cx="724.629"
              cy="322.312"
              r="82.5"
              fill="url(#paint10_linear_109_225)"
            />
            <circle
              opacity="0.9"
              cx="724.629"
              cy="322.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip5_109_225)">
              <path
                d="M750.988 297.849H729.194C728.288 297.849 727.553 298.583 727.553 299.489V302.413H716.707C715.952 298.944 712.859 296.337 709.167 296.337C704.912 296.337 701.45 299.799 701.45 304.054C701.45 308.309 704.912 311.771 709.167 311.771C712.859 311.771 715.952 309.164 716.707 305.695H727.553V308.619C727.553 309.525 728.288 310.259 729.194 310.259H738.45V316.798C734.981 317.552 732.374 320.645 732.374 324.338C732.374 328.03 734.981 331.123 738.45 331.878V340.442H721.705V337.518C721.705 336.612 720.97 335.878 720.064 335.878H710.808V330.03H720.064C720.97 330.03 721.705 329.295 721.705 328.389V319.26C721.705 318.354 720.97 317.619 720.064 317.619H698.27C697.363 317.619 696.629 318.354 696.629 319.26V328.389C696.629 329.295 697.363 330.03 698.27 330.03H707.526V335.878H698.27C697.363 335.878 696.629 336.612 696.629 337.518V346.648C696.629 347.554 697.363 348.288 698.27 348.288H720.064C720.97 348.288 721.705 347.554 721.705 346.648V343.724H740.091C740.997 343.724 741.732 342.989 741.732 342.083V331.877C745.201 331.123 747.808 328.03 747.808 324.338C747.808 320.645 745.201 317.552 741.732 316.798V310.259H750.988C751.894 310.259 752.629 309.525 752.629 308.619V299.489C752.629 298.583 751.894 297.849 750.988 297.849Z"
                fill="#E04713"
              />
            </g>
            <path
              d="M282.434 10.6465H555.434C615.633 10.6465 664.434 59.4474 664.434 119.646C664.434 179.846 615.633 228.646 555.434 228.646H269.434C209.235 228.646 160.434 277.447 160.434 337.646C160.434 397.846 209.235 446.646 269.434 446.646H555.434C615.633 446.646 664.434 495.447 664.434 555.646C664.434 615.846 615.633 664.646 555.434 664.646H269.434C209.235 664.646 160.434 713.447 160.434 773.646C160.434 833.846 209.235 882.646 269.434 882.646H555.434C615.633 882.646 664.434 931.447 664.434 991.646C664.434 1051.85 615.633 1100.65 555.434 1100.65H269.434C209.235 1100.65 160.434 1149.45 160.434 1209.65C160.434 1269.85 209.235 1318.65 269.434 1318.65H552.738C612.937 1318.65 661.738 1367.45 661.738 1427.65C661.738 1487.85 612.937 1536.65 552.738 1536.65H266.738"
              stroke="url(#paint11_linear_109_225)"
              stroke-width="20"
              stroke-linecap="round"
              id="filling_path"
            />
            <defs>
              <filter
                id="filter0_i_109_225"
                x="0.128906"
                y="1.8125"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_109_225"
                />
              </filter>
              <filter
                id="filter1_i_109_225"
                x="0.128906"
                y="438.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_109_225"
                />
              </filter>
              <filter
                id="filter2_i_109_225"
                x="0.128906"
                y="872.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_109_225"
                />
              </filter>
              <filter
                id="filter3_i_109_225"
                x="0.128906"
                y="1308.81"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_109_225"
                />
              </filter>
              <filter
                id="filter4_i_109_225"
                x="186.129"
                y="655.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_109_225"
                />
              </filter>
              <filter
                id="filter5_i_109_225"
                x="186.129"
                y="1089.81"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_109_225"
                />
              </filter>
              <filter
                id="filter6_i_109_225"
                x="188.129"
                y="218.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_109_225"
                />
              </filter>
              <linearGradient
                id="paint0_linear_109_225"
                x1="103.629"
                y1="22.8125"
                x2="103.629"
                y2="187.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#1B91DA" />
                <stop offset="1" stop-color="#0E4D74" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_109_225"
                x1="103.629"
                y1="459.812"
                x2="103.629"
                y2="624.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#0065F4" />
                <stop offset="1" stop-color="#0F4798" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_109_225"
                x1="665.536"
                y1="872.812"
                x2="9.05439"
                y2="872.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#21D342" />
                <stop offset="1" stop-color="#258136" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_109_225"
                x1="103.629"
                y1="893.812"
                x2="103.629"
                y2="1058.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#21D342" />
                <stop offset="1" stop-color="#258136" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_109_225"
                x1="665.536"
                y1="1308.81"
                x2="9.05439"
                y2="1308.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#21D3C8" />
                <stop offset="1" stop-color="#25817C" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_109_225"
                x1="103.629"
                y1="1329.81"
                x2="103.629"
                y2="1494.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#21D3C8" />
                <stop offset="1" stop-color="#25817C" />
              </linearGradient>
              <radialGradient
                id="paint6_radial_109_225"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(506.629 759.312) rotate(90) scale(103.5 320.5)"
              >
                <stop stop-color="#FFE81E" />
                <stop offset="1" stop-color="#C4B214" />
              </radialGradient>
              <linearGradient
                id="paint7_linear_109_225"
                x1="82.5"
                y1="0"
                x2="82.5"
                y2="165"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFE81E" />
                <stop offset="1" stop-color="#C4B214" />
              </linearGradient>
              <linearGradient
                id="paint8_linear_109_225"
                x1="506.629"
                y1="1089.81"
                x2="506.629"
                y2="1296.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#1EFFA8" />
                <stop offset="1" stop-color="#14C480" />
              </linearGradient>
              <linearGradient
                id="paint9_linear_109_225"
                x1="82.5"
                y1="0"
                x2="82.5"
                y2="165"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#1EFFA8" />
                <stop offset="1" stop-color="#14C480" />
              </linearGradient>
              <linearGradient
                id="paint10_linear_109_225"
                x1="724.629"
                y1="239.812"
                x2="724.629"
                y2="404.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FC490B" />
                <stop offset="1" stop-color="#C7471A" />
              </linearGradient>
              <linearGradient
                id="paint11_linear_109_225"
                x1="409.738"
                y1="2190.65"
                x2="409.738"
                y2="3062.65"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#09C2C1" />
                <stop offset="1" stop-color="#1D85D8" />
              </linearGradient>
              <clipPath id="clip0_109_225">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.6299 77.3125)"
                />
              </clipPath>
              <clipPath id="clip1_109_225">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.6299 514.312)"
                />
              </clipPath>
              <clipPath id="clip2_109_225">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.75 948)"
                />
              </clipPath>
              <clipPath id="clip3_109_225">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.6299 1384.31)"
                />
              </clipPath>
              <clipPath id="clip4_109_225">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(695.75 1165)"
                />
              </clipPath>
              <clipPath id="clip5_109_225">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(696.629 294.312)"
                />
              </clipPath>
            </defs>
          </svg>

          <div className="content_detail">
            <div className="col_detail figma-">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/uiflow1.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                User Research & Analysis
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#004875" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Gain a deep understanding of your audience to design experiences
                that resonate.
              </p>
            </div>
            <div className="col_detail prototipe-">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/uiflow2.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Wireframing & Prototyping
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#c7471a" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Visualize the flow and structure of your digital products with
                detailed mockups.
              </p>
            </div>
            <div className="col_detail ps-">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/uiflow3.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Custom UI Design
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#0f4798" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Create visually stunning, user-friendly interfaces that
                represent your brand.
              </p>
            </div>
            <div className="col_detail adobe-">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/uiflow4.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Brand Identity Development
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#c3b214" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Comprehensive branding solutions, including logo design, color
                schemes, and typography.
              </p>
            </div>
            <div className="col_detail ps-">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/uiflow5.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Mobile-First Design
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#21D342" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Ensure responsive, optimized designs for all devices.
              </p>
            </div>
            <div className="col_detail adobe">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/uiflow6.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                User Experience Optimization
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#1EFFA8" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Design smooth, intuitive interactions that keep users engaged.
              </p>
            </div>
            <div className="col_detail">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/uiflow7.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Logo Design
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#21D3C8" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Unique, memorable logos that capture your brand’s essence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UIUXDevFlow;
