import React from 'react'
import Career from '../component/Career'
import Culture from '../component/Culture'
import WorkBenefit from '../component/WorkBenefit'
import Testimonial from '../component/Testimonial'
import Why from '../component/Why'
import ScrollAnimation from '../component/ScrollAnimation'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import CurrentOpening from '../component/CurrentOpening' 
import CurrentOpeningForm from '../component/CurrentOpeningForm'

const OurWork = () => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Ensures the animation occurs only once
        threshold: 0.2, // Change as needed; it's the percentage of the element's visibility to trigger the animation
      });

    
    const imagePopUpAnimation = {
        hidden: {
            opacity: 0,
            scale: 0,
            // y: 100,
        },
        show: {
            opacity: 1,
            scale: 1,
            // x: 0,
            // y: 0,
        }
    }

    const headingAnimation = {
        hidden: {
            opacity: 0,
            x: -100,
            // y: 50,
        },
        show: {
            opacity: 1,
            x: 0
        }
    }

    return (
        <>
            <Career />
            <CurrentOpening />
            
            <Culture />
            <WorkBenefit />
            {/* <Why /> */}
            {/* <ScrollAnimation/>   */}
            <div className="team_bg"  >
                <div className="wrapper">
                <div className="team_wrap"> 
                    <motion.div 
                    variants={headingAnimation}
                    initial="hidden"
                    animate={inView ? 'show' : 'hidden'}
                    transition={{
                        // delay: 2,
                        duration: 1,
                        // delayChildren: 0.3,
                    }}
                    className="left_col"
                    >
                        <h3>Become a <span>Codeflix Web Team </span>Member Today</h3>
                    </motion.div>
                    <div className="right_col" style={{backgroundImage: 'url(./assets/images/new/team_bg.svg)'}}>
                        <motion.img 
                        ref={ref}
                        variants={imagePopUpAnimation}
                        initial="hidden"
                        animate={inView ? 'show' : 'hidden'}
                        transition={{
                            delay: 1,
                            duration: 1,
                            // delayChildren: 0.3,
                        }}
                        src="./assets/images/new/team.png"
                        alt=""/>
                    </div>
                </div>

            </div>
        </div>
        </>
    )
}

export default OurWork
