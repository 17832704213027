const BlogBanner = ({ items }) => {
  //console.log(items);
  return (
    <div
      className="blog_banner"
      style={{ backgroundImage: 'url("./assets/images/new/blog_banner.png")' }}
    >
      <div className="wrapper">
        <div className="title">
          <a data-aos="zoom-out-up" href="javascript:void(0)" className="  button-container-1">
            <span className="mas">
                Blogs
            </span>
            <span className="mask_btn">
                Blogs
            </span>
          </a>
          <h1 data-aos="zoom-out-up">
            Latest <span>Tech Updates</span>
          </h1>

          <div className="related_topic">
            <p>Related Topics: </p>
            {items.slice(0, 5).map((item, index) => { 
              return <a href="javascript:void(0)" >{item.tags[0].name}</a>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};    
export default BlogBanner;
