import React from 'react'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const WorkBenefit = () => {

        const [ref, inView] = useInView({
            triggerOnce: true, // Ensures the animation occurs only once
            threshold: 0.2, // Change as needed; it's the percentage of the element's visibility to trigger the animation
        });

    const item1 = {
        hidden: { y: 200, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.5,
                // fade: 'fadeIn'
            }
        }
    };
   
    const item2 = {
        hidden: { y: 200, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 0.5,
                duration: 0.5,
                // fade: 'fadeIn'
            }
        }
    };
    const item3 = {
        hidden: { y: 200, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 1,
                duration: 0.5,
                // fade: 'fadeIn'
            }
        }
    };
    const item4 = {
        hidden: { y: 200, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 1.5,
                duration: 0.5,
                // fade: 'fadeIn'
            }
        }
    };

    return (
        <div className="work_benifit">
            <div className="wrapper">
                <div className="left_col">
                    <div className="benifit_list" ref={ref}>
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={item1}
                            className="benifit_col">
                            <img src="./assets/images/new/b1.png" data-aos="zoom-out-up" alt="" />
                            <h3 data-aos="zoom-out-up">Expanding Horizons</h3>
                            <p data-aos="zoom-out-up">Opportunities for cross-functional learning.</p>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={item2} className="benifit_col">
                            <img src="./assets/images/new/b2.png" data-aos="zoom-out-up" alt="" />
                            <h3 data-aos="zoom-out-up">Project Engagement</h3>
                            <p data-aos="zoom-out-up">Direct involvement in impactful projects.</p>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={item3}  className="benifit_col">
                            <img data-aos="zoom-out-up" src="./assets/images/new/b3.png" alt="" />
                            <h3 data-aos="zoom-out-up">Tailored Growth</h3>
                            <p data-aos="zoom-out-up">Personalized career growth plans.</p>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={item4}  
                            className="benifit_col">
                            <img src="./assets/images/new/b4.png" data-aos="zoom-out-up" alt="" />
                            <h3 data-aos="zoom-out-up">Work Flexibility</h3>
                            <p data-aos="zoom-out-up">Flexibility in work arrangements.</p>
                        </motion.div>
                    </div>
                </div>
                <div className="right_col">
                    <div className="title">
                        <a data-aos="zoom-out-up" href="javascript:void(0)" className="  button-container-1">
                            <span className="mas">
                                 Benefits
                            </span>
                            <span className="mask_btn">
                                Benefits
                            </span>
                        </a>
                        <h2 data-aos="zoom-out-up">Perks of Working with us</h2>
                        <p data-aos="zoom-out-up">Codeflix Web offers an array of exceptional perks for its team. Enjoy flexible work arrangements, continuous skill development, and a vibrant culture that champions creativity and collaboration. Elevate your career with us and experience the advantages of a dynamic and enriching professional journey.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkBenefit
