import React, { useEffect } from "react";
import MobileDevBanner from "../component/MobileDevBanner";
import $ from "jquery"; // Import jQuery
import MobileDevFlow from "../component/MobileDevFlow";
import CustomNeed from "../component/CustomNeed";

const MobileDev = () => {
  useEffect(() => {
    const win = window;

    function isElementVisible($el) {
      const viewTop = win.scrollY;
      const viewBottom = viewTop + window.innerHeight;
      const elTop = $el.offset().top;
      const elBottom = elTop + $el.height();
      return elBottom <= viewBottom && elTop >= viewTop;
    }

    function addAnimation($el, delay) {
      setTimeout(() => {
        $el.addClass("come-in");
        $el.attr("class", "webflow_circle come-in");
      }, delay);
    }

    const allMods1 = Array.from(document.querySelectorAll(".webflow_circle"));
    const allMods2 = Array.from(document.querySelectorAll(".html_circle"));
    const allMods3 = Array.from(document.querySelectorAll(".css_circle"));
    const allMods4 = Array.from(document.querySelectorAll(".js_circle"));

    win.addEventListener("scroll", () => {
      allMods1.forEach((el, i) => {
        const $el = $(el);
        if (isElementVisible($el)) {
          addAnimation($el, i * 200);
        }
      });

      allMods2.forEach((el, i) => {
        const $el = $(el);
        if (isElementVisible($el)) {
          addAnimation($el, i * 200);
        }
      });

      allMods3.forEach((el, i) => {
        const $el = $(el);
        if (isElementVisible($el)) {
          addAnimation($el, i * 200);
        }
      });

      allMods4.forEach((el, i) => {
        const $el = $(el);
        if (isElementVisible($el)) {
          addAnimation($el, i * 200);
        }
      });
    });

    const handleScroll = () => {
      const offset = window.pageYOffset;
      const wheight = window.innerHeight;
      const html = document.documentElement;
      const docheight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      let progress = (offset / (docheight - wheight)) * 3000;

      // Give the line a CSS gradient based on scroll position
      progress = 3000 - progress * 1.6;

      // Update the SVG element with the new stroke-dashoffset
      const fillingPath = document.getElementById("filling_path");
      if (fillingPath) {
        fillingPath.setAttribute("stroke-dashoffset", progress);
      }
    };

    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <MobileDevBanner />
      <MobileDevFlow />
      {/* <CustomNeed /> */}
      <div className="why_us">
        <div className="wrapper">
          <h2>Why Choose Us?</h2>
          <div className="col_row">
            <div
              className="col_item"
              style={{
                backgroundImage: `url(./assets/images/portfolio/usbg1.svg)`,
              }}
            >
              <div className="icon">
                <img src="./assets/images/portfolio/us5.svg" alt="" />
              </div>
              <hr />
              <h3>Expert Mobile Developers</h3>
              <p>
                Our team of experienced mobile app developers keeps up with the
                latest trends and technologies, ensuring your app is
                future-ready and highly competitive.
              </p>
            </div>
            <div
              className="col_item"
              style={{
                backgroundImage: `url(./assets/images/portfolio/usbg2.svg)`,
              }}
            >
              <div className="icon">
                <img src="./assets/images/portfolio/us6.svg" alt="" />
              </div>
              <hr />
              <h3>Client-Centered Approach</h3>
              <p>
                We work closely with you to understand your goals, ensuring that
                every aspect of the app aligns with your vision and delivers
                measurable results.
              </p>
            </div>
            <div
              className="col_item"
              style={{
                backgroundImage: `url(./assets/images/portfolio/usbg3.svg)`,
              }}
            >
              <div className="icon">
                <img src="./assets/images/portfolio/us7.svg" alt="" />
              </div>
              <hr />
              <h3>On-Time Delivery</h3>
              <p>
                We respect deadlines and ensure that your app is launched on
                time, without compromising on quality.
              </p>
            </div>
            <div
              className="col_item"
              style={{
                backgroundImage: `url(./assets/images/portfolio/usbg4.svg)`,
              }}
            >
              <div className="icon">
                <img src="./assets/images/portfolio/us8.svg" alt="" />
              </div>
              <hr />
              <h3>Security-First Development</h3>
              <p>
                We prioritize security in every app we develop, ensuring that
                sensitive user data and business information remain protected at
                all times.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileDev;
