import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { useEffect, useState } from "react";
import Aos from "aos";
import Home from "./pages/Home";
import BlogPage from "./pages/BlogPage";
import WebDev from "./pages/WebDev";
import About from "./pages/About";
import Service from "./pages/Service";
import ContactUs from "./component/ContactUs";
import OurWork from "./pages/OurWork";
import { ToastContainer, toast } from "react-toast";
import CustomPhpDev from "./pages/CustomPhpDev";
import UIUXDev from "./pages/UIUXDev";
import MobileDev from "./pages/MobileDev";
import SEO from "./pages/SEO";
import BlogDetail from "./pages/BlogDetail";
import BestwebCompany from './pages/BestwebCompany';
import BestUIUXDesign from './pages/BestUIUXDesign';
import LeadingPhpCompany from './pages/LeadingPhpCompany';
import CustomAppServices from './pages/CustomAppServices';
import OurWorkDetail from './pages/OurWorkDetail';

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });

    const blob = document.querySelector("#blob");
    const body = document.querySelector("body");

    const handleMouseMove = (e) => {
      let half = blob.offsetWidth / 2;
      blob.style.left = `${e.clientX - half}px`;
      blob.style.top = `${e.clientY - half}px`;
    };

    body.addEventListener("mousemove", handleMouseMove);

    return () => {
      body.removeEventListener("mousemove", handleMouseMove);
    };

    toast("Thank you for contacting us, we will get in thouch soon.", {
      backgroundColor: "#323131",
      color: "#ffffff",
    });
  }, []);

  return (
    <Router>
      <div id="full_wrapper" className="home_page">
        <div id="overlay">
          <ToastContainer position="bottom-right" delay={6000} />
        </div>
        <div id="blob"></div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          {/* <Route path="/service" element={<Service/>} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/our-work" element={<OurWork />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/blogs/:tag" element={<BlogPage />} />
          <Route path="/blog/:blogId" element={<BlogDetail />} />
          <Route path="/web-dev" element={<Service />} />
          <Route path="/custom-php-development" element={<CustomPhpDev />} />
          <Route path="/ui-ux" element={<UIUXDev />} />
          <Route path="/mobile-dev" element={<MobileDev />} />
          <Route path="/seo" element={<SEO />} />
          <Route path="/our-work-detail" element={<OurWorkDetail />} />
          <Route path="/best-web-development-company" element={<BestwebCompany />} />
          <Route path="/best-UI-UX-design" element={<BestUIUXDesign />} />
          <Route path="/leading-php-company" element={<LeadingPhpCompany />} />
          <Route path="/custom-mobile-app-development-services" element={<CustomAppServices />} />
          <Route path="/resource-allocation" element={<CustomPhpDev />} />
          <Route path="/maintenance" element={<WebDev />} />
        </Routes>
        <ContactUs />
        {/* <ToastContainer delay={8000} /> */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
