import React from "react";

const WebDevBanner = () => {
  return (
    <div
      className="inner_banner service-banner"
      style={{ backgroundImage: "url(/assets/images/site/service_bg.png)" }}
    >
      <div className="wrapper">
        <div className="banner_detail_1">
          <div className="details-ser">
            <h1 id="heading_line">Web Maintenance Services: Ensuring Peak Performance, Always</h1>
            <h2 id="sub_heading1">
            A website is an evolving entity that needs regular care to stay secure, updated, and optimized. At Codeflix Web LLC, we provide ongoing web maintenance to keep your digital presence flawless and high-performing.
            </h2>
          </div>
          <div className="arrows">
            <img src="/assets/images/new/arrows-ser.svg" alt="arrows" />
          </div>
          <div class="slider-block">
            <img src="/assets/images/new/sercive_banner.png" alt="" />
          </div>
          {/* <CardSwiper/> */}
        </div>
      </div>
    </div>
  );
};

export default WebDevBanner;
