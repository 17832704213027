import React from "react";

const SEOBanner = () => {
  return (
    <div
      class="inner_banner service-banner"
      style={{ backgroundImage: "url(/assets/images/site/searchbanner.png)" }}
    >
      <div class="wrapper">
        <div class="banner_detail_1">
          <div class="details-ser">
            <h1 id="heading_line">SEO, Digital Marketing & Branding: Elevating Your Business Online</h1>
            <h2 id="sub_heading1">
            In a competitive online world, a strong presence is key. At Codeflix Web LLC, we offer a complete suite of SEO, digital marketing, and branding services to help your business stand out, reach the right audience, and convert leads into loyal customers.
            </h2>
          </div>
          <div class="arrows">
            <img src="/assets/images/new/arrows-ser.svg" alt="arrows" />
          </div>
          <div class="slider-block">
            <img src="/assets/images/new/sercive_banner.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SEOBanner;
