import React from 'react'; 
import Lottie from "lottie-react";
import groovyWalkAnimation from "./Codi.json";
const Logo = () => {

    // const svgPath0 = {
    //     hidden: {
    //         opacity: 0
    //     },
    //     visible: {
    //         opacity: 1,
    //         transition: {
    //             duration: 1,
    //         }

    //     }
    // }
    // const svgPath1 = {
    //     hidden: {
    //         opacity: 0
    //     },
    //     visible: {
    //         opacity: 1,
    //         transition: {
    //             // delay: 1,
    //             duration: 1,
    //             ease: 'easeInOut'
    //         }
    //     }
    // }
    
  

    return (
        <>
            <Lottie  animationData={groovyWalkAnimation} loop={true} />
        </>
    )
}

export default Logo
