import React from "react";
import { motion } from "framer-motion";

const CustomePhpServiceFlow = () => {
  return (
    <div className="serivces_flow serivces_flow6" id="wrap">
      <div className="titles">
        <div className="wrapper">
          {/* <a href="javascript:void(0)" className="btn-blue">
            HEIGHTEN YOUR SPLENDOUR
          </a>
          <h3>Technology We Use</h3>
          <p>
            Reference site about Lorem Ipsum, giving informationon its origins,
            as well as a random Lipsum generator. Reference site about Lorem
            Ipsum, giving informationon its origins, as well as a random Lipsum
            generator.
          </p> */}
        </div>
      </div>
      <div className="wrapper">
        <div className="service_flow_wrap">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="830"
            height="1112"
            viewBox="0 0 830 1112"
            fill="none"
          >
            <g filter="url(#filter0_i_112_434)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 207.501C109.048 207.708 106.351 207.812 103.629 207.812C46.4674 207.812 0.128906 161.474 0.128906 104.312C0.128906 47.151 46.4674 0.8125 103.629 0.8125C124.168 0.8125 143.31 6.79531 159.407 17.1133C173.432 26.1034 188.868 33.8125 205.527 33.8125H554.129C602.178 33.8125 641.129 72.7637 641.129 120.812C641.129 168.861 602.178 207.812 554.129 207.812H119.129C116.632 207.812 114.16 207.707 111.717 207.501Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 207.003L111.718 207L111.678 207.003C109.022 207.208 106.338 207.312 103.629 207.312C46.7436 207.312 0.628906 161.198 0.628906 104.312C0.628906 47.4271 46.7436 1.3125 103.629 1.3125C124.07 1.3125 143.118 7.26633 159.137 17.5342C173.181 26.5359 188.718 34.3125 205.527 34.3125H554.129C601.902 34.3125 640.629 73.0398 640.629 120.812C640.629 168.585 601.902 207.312 554.129 207.312H119.129C116.646 207.312 114.188 207.208 111.759 207.003Z"
              stroke="#00E1DB"
            />
            <circle
              cx="103.629"
              cy="104.312"
              r="82.5"
              fill="url(#paint0_linear_112_434)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="104.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip0_112_434)">
              <path
                d="M112.38 116.104H111.286C111.286 120.325 107.852 123.76 103.63 123.76C99.4082 123.76 95.9736 120.325 95.9736 116.104H94.8799C87.9443 116.104 82.3018 121.746 82.3018 128.682V131.088C82.3018 131.994 83.0363 132.729 83.9424 132.729H123.317C124.223 132.729 124.958 131.994 124.958 131.088V128.682C124.958 121.746 119.315 116.104 112.38 116.104Z"
                fill="#1375B2"
              />
              <path
                d="M99.2559 114.463V116.104C99.2559 118.52 101.215 120.479 103.631 120.479C106.047 120.479 108.006 118.52 108.006 116.104V114.463H99.2559Z"
                fill="#1375B2"
              />
              <path
                d="M103.631 83.291C104.537 83.291 105.271 82.5565 105.271 81.6504V78.3691C105.271 77.4631 104.537 76.7285 103.631 76.7285C102.725 76.7285 101.99 77.4631 101.99 78.3691V81.6504C101.99 82.5565 102.725 83.291 103.631 83.291Z"
                fill="#1375B2"
              />
              <path
                d="M91.2728 82.1203L92.9134 84.962C93.3665 85.7467 94.3699 86.0155 95.1545 85.5625C95.9392 85.1095 96.208 84.106 95.755 83.3214L94.1144 80.4797C93.6613 79.6951 92.6579 79.4262 91.8733 79.8793C91.0886 80.3323 90.8198 81.3357 91.2728 82.1203Z"
                fill="#1375B2"
              />
              <path
                d="M83.8664 90.7276L86.7081 92.3683C87.4928 92.8213 88.4962 92.5525 88.9492 91.7678C89.4022 90.9831 89.1334 89.9797 88.3487 89.5267L85.5071 87.8861C84.7224 87.4331 83.719 87.7019 83.266 88.4866C82.8129 89.2712 83.0818 90.2746 83.8664 90.7276Z"
                fill="#1375B2"
              />
              <path
                d="M121.754 87.8861L118.912 89.5267C118.128 89.9797 117.859 90.9831 118.312 91.7678C118.765 92.5525 119.768 92.8213 120.553 92.3683L123.395 90.7276C124.179 90.2746 124.448 89.2712 123.995 88.4866C123.542 87.7019 122.539 87.4331 121.754 87.8861Z"
                fill="#1375B2"
              />
              <path
                d="M112.108 85.5625C112.892 86.0155 113.896 85.7467 114.349 84.962L115.989 82.1203C116.442 81.3357 116.174 80.3323 115.389 79.8793C114.604 79.4262 113.601 79.6951 113.148 80.4797L111.507 83.3214C111.054 84.106 111.323 85.1095 112.108 85.5625Z"
                fill="#1375B2"
              />
              <path
                d="M103.631 86.5723C96.9862 86.5723 91.5996 91.9589 91.5996 98.6035C91.5996 102.879 93.8337 106.627 97.1958 108.758C98.0972 109.33 98.7527 110.192 99.06 111.182H101.99V102.564L99.1895 99.7637C98.5488 99.1229 98.5488 98.0842 99.1895 97.4435C99.8302 96.8028 100.869 96.8028 101.51 97.4435L103.631 99.5646L105.752 97.4435C106.393 96.8028 107.431 96.8028 108.072 97.4435C108.713 98.0842 108.713 99.1229 108.072 99.7637L105.271 102.564V111.182H108.202C108.509 110.192 109.165 109.33 110.066 108.758C113.428 106.627 115.662 102.879 115.662 98.6035C115.662 91.9589 110.275 86.5723 103.631 86.5723Z"
                fill="#1375B2"
              />
            </g>
            <g filter="url(#filter1_i_112_434)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 644.501C109.048 644.708 106.351 644.812 103.629 644.812C46.4674 644.812 0.128906 598.474 0.128906 541.312C0.128906 484.151 46.4674 437.812 103.629 437.812C124.168 437.812 143.31 443.795 159.407 454.113C173.432 463.103 188.868 470.812 205.527 470.812H554.129C602.178 470.812 641.129 509.764 641.129 557.812C641.129 605.861 602.178 644.812 554.129 644.812H119.129C116.632 644.812 114.16 644.707 111.717 644.501Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 644.003L111.718 644L111.678 644.003C109.022 644.208 106.338 644.312 103.629 644.312C46.7436 644.312 0.628906 598.198 0.628906 541.312C0.628906 484.427 46.7436 438.312 103.629 438.312C124.07 438.312 143.118 444.266 159.137 454.534C173.181 463.536 188.718 471.312 205.527 471.312H554.129C601.902 471.312 640.629 510.04 640.629 557.812C640.629 605.585 601.902 644.312 554.129 644.312H119.129C116.646 644.312 114.188 644.208 111.759 644.003Z"
              stroke="#00E1DB"
            />
            <circle
              cx="103.629"
              cy="541.312"
              r="82.5"
              fill="url(#paint1_linear_112_434)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="541.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip1_112_434)">
              <path
                d="M80.666 542.953H77.2754C76.3686 542.953 75.6348 543.687 75.6348 544.594V567.672C75.6348 568.579 76.3686 569.312 77.2754 569.312H80.666C83.38 569.312 85.5879 567.105 85.5879 564.391V547.875C85.5879 545.161 83.38 542.953 80.666 542.953Z"
                fill="#0051C4"
              />
              <path
                d="M103.635 513.312C93.6837 513.312 85.5879 521.518 85.5879 531.469C85.5879 534.862 86.5338 538.125 88.2734 540.938C90.4774 539.708 92.9259 539.028 95.467 539.028C98.956 539.028 102.481 540.249 105.472 542.953H113.588C114.775 542.953 115.869 543.306 116.831 543.859C119.896 540.623 121.791 536.267 121.791 531.469C121.791 521.518 113.586 513.312 103.635 513.312ZM103.635 526.547C106.349 526.547 108.557 528.755 108.557 531.469C108.557 533.451 107.378 535.231 105.553 536.003C105.462 536.041 105.367 536.056 105.276 536.089V538.031C105.276 538.938 104.542 539.672 103.635 539.672C102.728 539.672 101.994 538.938 101.994 538.031V536.093C101.143 535.807 100.317 535.326 99.5766 534.609C98.9262 533.979 98.9101 532.941 99.5414 532.289C100.171 531.639 101.212 531.624 101.861 532.254C102.658 533.024 103.537 533.292 104.273 532.981C104.881 532.723 105.276 532.13 105.276 531.469C105.276 530.564 104.54 529.828 103.635 529.828C100.921 529.828 98.7131 527.62 98.7131 524.906C98.7129 522.844 100.014 520.986 101.951 520.281C101.965 520.276 101.98 520.278 101.994 520.273V518.234C101.994 517.328 102.728 516.594 103.635 516.594C104.542 516.594 105.276 517.328 105.276 518.234V520.254C106.357 520.619 107.39 521.341 108.276 522.397C108.86 523.091 108.77 524.126 108.075 524.708C107.382 525.292 106.344 525.203 105.764 524.506C104.896 523.474 103.917 523.059 103.074 523.363C102.428 523.599 101.994 524.219 101.994 524.906C101.994 525.811 102.73 526.547 103.635 526.547Z"
                fill="#0051C4"
              />
              <path
                d="M130.29 543.576C128.944 542.592 127.074 542.789 125.958 544.003L117.099 555.028C116.476 555.684 115.294 556.078 114.671 556.078H103.635C102.716 556.078 101.994 555.356 101.994 554.437C101.994 553.518 102.716 552.796 103.635 552.796C110.985 552.796 105.418 552.796 113.588 552.796C115.393 552.796 116.869 551.32 116.869 549.515C116.869 547.71 115.393 546.234 113.588 546.234C105.883 546.234 113.123 546.234 104.882 546.234C104.064 546.234 103.656 545.715 103.011 545.151C102.027 544.265 100.911 543.609 99.7629 543.149C96.2072 541.697 92.0987 542.137 88.8691 544.383C88.8691 550.357 88.8691 559.258 88.8691 566.031H113.588C116.672 566.031 119.626 564.445 121.463 561.984L130.979 548.203C132.061 546.759 131.766 544.659 130.29 543.576Z"
                fill="#0051C4"
              />
            </g>
            <g filter="url(#filter2_i_112_434)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 1078.5C109.048 1078.71 106.351 1078.81 103.629 1078.81C46.4674 1078.81 0.128906 1032.47 0.128906 975.312C0.128906 918.151 46.4674 871.812 103.629 871.812C124.168 871.812 143.31 877.795 159.407 888.113C173.432 897.103 188.868 904.812 205.527 904.812H554.129C602.178 904.812 641.129 943.764 641.129 991.812C641.129 1039.86 602.178 1078.81 554.129 1078.81H119.129C116.632 1078.81 114.16 1078.71 111.717 1078.5Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 1078L111.718 1078L111.678 1078C109.022 1078.21 106.338 1078.31 103.629 1078.31C46.7436 1078.31 0.628906 1032.2 0.628906 975.312C0.628906 918.427 46.7436 872.312 103.629 872.312C124.07 872.312 143.118 878.266 159.137 888.534C173.181 897.536 188.718 905.312 205.527 905.312H554.129C601.902 905.312 640.629 944.04 640.629 991.812C640.629 1039.59 601.902 1078.31 554.129 1078.31H119.129C116.646 1078.31 114.188 1078.21 111.759 1078Z"
              stroke="url(#paint2_linear_112_434)"
            />
            <circle
              cx="103.629"
              cy="975.312"
              r="82.5"
              fill="url(#paint3_linear_112_434)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="975.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip2_112_434)">
              <path
                d="M92.8018 991.062C90.0878 991.062 87.8799 988.855 87.8799 986.141V975.75H77.2705C76.3644 975.75 75.6299 976.485 75.6299 977.391V1001.67C75.6299 1002.58 76.3644 1003.31 77.2705 1003.31H101.552C102.458 1003.31 103.192 1002.58 103.192 1001.67V991.062H92.8018Z"
                fill="#1BB738"
              />
              <path
                d="M129.989 947.312H92.8018C91.8957 947.312 91.1611 948.047 91.1611 948.953V986.141C91.1611 987.047 91.8957 987.781 92.8018 987.781H129.989C130.895 987.781 131.63 987.047 131.63 986.141V948.953C131.63 948.047 130.895 947.312 129.989 947.312ZM125.067 962.078C125.067 962.984 124.333 963.719 123.427 963.719C122.521 963.719 121.786 962.984 121.786 962.078V959.476L103.325 977.938H105.927C106.833 977.938 107.567 978.672 107.567 979.578C107.567 980.484 106.833 981.219 105.927 981.219H99.3643C98.4582 981.219 97.7236 980.484 97.7236 979.578V973.016C97.7236 972.11 98.4582 971.375 99.3643 971.375C100.27 971.375 101.005 972.11 101.005 973.016V975.617L119.466 957.156H116.864C115.958 957.156 115.224 956.422 115.224 955.516C115.224 954.61 115.958 953.875 116.864 953.875H123.427C124.333 953.875 125.067 954.61 125.067 955.516V962.078Z"
                fill="#1BB738"
              />
            </g>
            <g filter="url(#filter3_i_112_434)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M715.541 861.501C718.21 861.708 720.907 861.812 723.629 861.812C780.79 861.812 827.129 815.474 827.129 758.312C827.129 701.151 780.79 654.812 723.629 654.812C703.09 654.812 683.948 660.795 667.851 671.113C653.825 680.103 638.39 687.812 621.731 687.812H273.129C225.08 687.812 186.129 726.764 186.129 774.812C186.129 822.861 225.08 861.812 273.129 861.812H708.129C710.626 861.812 713.098 861.707 715.541 861.501Z"
                fill="white"
              />
            </g>
            <path
              d="M715.499 861.003L715.539 861L715.58 861.003C718.236 861.208 720.92 861.312 723.629 861.312C780.514 861.312 826.629 815.198 826.629 758.312C826.629 701.427 780.514 655.312 723.629 655.312C703.188 655.312 684.139 661.266 668.121 671.534C654.077 680.536 638.54 688.312 621.731 688.312H273.129C225.356 688.312 186.629 727.04 186.629 774.812C186.629 822.585 225.356 861.312 273.129 861.312H708.129C710.612 861.312 713.07 861.208 715.499 861.003Z"
              stroke="url(#paint4_radial_112_434)"
            />
            <circle
              cx="82.5"
              cy="82.5"
              r="82.5"
              transform="matrix(-1 0 0 1 806.129 675.812)"
              fill="url(#paint5_linear_112_434)"
            />
            <circle
              opacity="0.9"
              cx="59.1949"
              cy="59.1949"
              r="59.1949"
              transform="matrix(-1 0 0 1 782.823 699.117)"
              fill="white"
            />
            <g clip-path="url(#clip3_112_434)">
              <path
                d="M740.297 762.253L738.108 760.989C738.302 760.046 738.394 759.175 738.394 758.312C738.394 757.45 738.302 756.579 738.108 755.636L740.298 754.372C740.674 754.154 740.949 753.796 741.062 753.376C741.175 752.955 741.116 752.507 740.898 752.131L737.617 746.447C737.164 745.663 736.16 745.394 735.376 745.847L733.206 747.1C731.846 745.946 730.268 745.044 728.55 744.44V741.906C728.55 741 727.816 740.266 726.91 740.266H720.347C719.441 740.266 718.706 741 718.706 741.906V744.44C716.989 745.044 715.411 745.946 714.051 747.1L711.881 745.847C711.096 745.394 710.093 745.663 709.64 746.447L706.359 752.131C706.141 752.507 706.082 752.955 706.195 753.376C706.307 753.796 706.582 754.154 706.959 754.372L709.149 755.636C708.955 756.579 708.863 757.45 708.863 758.312C708.863 759.175 708.955 760.046 709.149 760.989L706.959 762.253C706.582 762.471 706.307 762.829 706.195 763.249C706.082 763.67 706.141 764.118 706.358 764.494L709.64 770.178C710.093 770.962 711.096 771.231 711.881 770.778L714.051 769.525C715.41 770.679 716.989 771.581 718.706 772.185V774.719C718.706 775.625 719.441 776.359 720.347 776.359H726.909C727.815 776.359 728.55 775.625 728.55 774.719V772.185C730.267 771.581 731.846 770.679 733.206 769.525L735.375 770.778C736.16 771.231 737.163 770.962 737.617 770.178L740.898 764.494C741.115 764.118 741.174 763.67 741.062 763.249C740.949 762.829 740.674 762.471 740.297 762.253ZM723.628 763.234C720.914 763.234 718.706 761.026 718.706 758.312C718.706 755.599 720.914 753.391 723.628 753.391C726.342 753.391 728.55 755.599 728.55 758.312C728.55 761.026 726.342 763.234 723.628 763.234Z"
                fill="#DDCA27"
              />
              <path
                d="M746.066 741.552L743.746 734.592C743.459 733.732 742.53 733.267 741.671 733.554C740.811 733.841 740.347 734.77 740.633 735.629L740.83 736.221C739.358 735.101 737.772 734.113 736.101 733.284C732.182 731.34 727.868 730.312 723.628 730.312C716.189 730.312 709.169 733.236 703.86 738.545C698.552 743.854 695.628 750.874 695.628 758.313C695.628 761.748 696.35 765.268 697.773 768.773C698.032 769.411 698.646 769.797 699.294 769.797C699.499 769.797 699.708 769.758 699.91 769.676C700.75 769.336 701.154 768.379 700.813 767.539C699.55 764.427 698.909 761.323 698.909 758.313C698.909 744.683 709.998 733.594 723.628 733.594C728.631 733.594 733.681 735.258 737.877 738.144C737.075 737.98 736.258 738.436 735.993 739.232C735.706 740.092 736.171 741.021 737.03 741.307L743.991 743.628C744.578 743.823 745.229 743.673 745.67 743.231C746.109 742.792 746.263 742.142 746.066 741.552Z"
                fill="#DDCA27"
              />
              <path
                d="M749.483 747.852C749.142 747.012 748.185 746.608 747.346 746.949C746.506 747.29 746.102 748.247 746.443 749.086C747.706 752.198 748.347 755.303 748.347 758.313C748.347 771.943 737.258 783.032 723.628 783.032C718.625 783.032 713.575 781.367 709.379 778.481C710.181 778.646 710.998 778.19 711.263 777.393C711.55 776.534 711.085 775.605 710.226 775.318L703.265 772.998C702.676 772.801 702.025 772.955 701.586 773.394C701.147 773.834 700.993 774.484 701.19 775.073L703.51 782.034C703.739 782.721 704.379 783.156 705.066 783.156C705.238 783.156 705.413 783.129 705.585 783.071C706.445 782.785 706.909 781.856 706.623 780.996L706.426 780.405C707.898 781.525 709.484 782.513 711.154 783.341C715.074 785.285 719.388 786.313 723.628 786.313C731.067 786.313 738.087 783.389 743.395 778.08C748.704 772.772 751.628 765.751 751.628 758.313C751.628 754.877 750.906 751.358 749.483 747.852Z"
                fill="#DDCA27"
              />
            </g>
            <g filter="url(#filter4_i_112_434)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M717.541 424.501C720.21 424.708 722.907 424.812 725.629 424.812C782.79 424.812 829.129 378.474 829.129 321.312C829.129 264.151 782.79 217.812 725.629 217.812C705.09 217.812 685.948 223.795 669.851 234.113C655.825 243.103 640.39 250.812 623.731 250.812H275.129C227.08 250.812 188.129 289.764 188.129 337.812C188.129 385.861 227.08 424.812 275.129 424.812H710.129C712.626 424.812 715.098 424.707 717.541 424.501Z"
                fill="white"
              />
            </g>
            <path
              d="M717.499 424.003L717.539 424L717.58 424.003C720.236 424.208 722.92 424.312 725.629 424.312C782.514 424.312 828.629 378.198 828.629 321.312C828.629 264.427 782.514 218.312 725.629 218.312C705.188 218.312 686.139 224.266 670.121 234.534C656.077 243.536 640.54 251.312 623.731 251.312H275.129C227.356 251.312 188.629 290.04 188.629 337.812C188.629 385.585 227.356 424.312 275.129 424.312H710.129C712.612 424.312 715.07 424.208 717.499 424.003Z"
              stroke="#00E1DB"
            />
            <circle
              cx="724.629"
              cy="321.312"
              r="82.5"
              fill="url(#paint6_linear_112_434)"
            />
            <circle
              opacity="0.9"
              cx="724.629"
              cy="321.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip4_112_434)">
              <path
                d="M750.988 318.578H739.941C739.035 318.578 738.301 319.313 738.301 320.219V321.312C738.301 328.851 732.168 334.984 724.629 334.984C717.09 334.984 710.957 328.851 710.957 321.312V320.219C710.957 319.313 710.222 318.578 709.316 318.578H698.27C697.363 318.578 696.629 319.313 696.629 320.219V326.804C696.629 327.657 697.283 328.368 698.133 328.439L700.545 328.64C701.592 328.727 702.457 329.359 702.859 330.33C703.262 331.3 703.096 332.359 702.418 333.161L700.854 335.009C700.303 335.66 700.344 336.625 700.947 337.228L708.713 344.995C709.316 345.598 710.281 345.638 710.933 345.087L712.78 343.524C713.582 342.845 714.641 342.68 715.612 343.082C716.582 343.484 717.214 344.349 717.301 345.396L717.502 347.808C717.573 348.658 718.284 349.312 719.137 349.312H730.121C730.974 349.312 731.685 348.658 731.755 347.808L731.956 345.396C732.044 344.349 732.675 343.484 733.646 343.082C734.617 342.68 735.675 342.845 736.477 343.524L738.325 345.087C738.976 345.638 739.942 345.598 740.545 344.995L748.311 337.228C748.914 336.625 748.954 335.66 748.403 335.008L746.84 333.161C746.161 332.359 745.996 331.3 746.398 330.329C746.8 329.359 747.666 328.727 748.713 328.64L751.125 328.439C751.975 328.368 752.629 327.657 752.629 326.804V320.219C752.629 319.313 751.894 318.578 750.988 318.578Z"
                fill="#E04713"
              />
              <path
                d="M706.61 306.835C706.92 307.687 707.861 308.125 708.713 307.816C709.564 307.506 710.003 306.564 709.693 305.713L708.932 303.621L717.621 307.672C720.732 309.123 722.752 312.114 722.956 315.534C720.448 316.259 718.614 318.571 718.614 321.312C718.614 324.635 721.307 327.328 724.629 327.328C727.952 327.328 730.645 324.635 730.645 321.312C730.645 318.571 728.81 316.259 726.302 315.534C726.507 312.114 728.526 309.123 731.638 307.672L740.327 303.621L739.565 305.713C739.255 306.564 739.694 307.506 740.546 307.816C741.397 308.125 742.339 307.687 742.649 306.835L744.765 301.021C745.075 300.169 744.636 299.228 743.784 298.918L737.97 296.802C737.119 296.492 736.177 296.931 735.867 297.783C735.558 298.634 735.996 299.575 736.848 299.885L738.94 300.647L730.251 304.698C728.735 305.405 727.384 306.404 726.27 307.631V298.914L727.844 300.488C728.485 301.129 729.524 301.129 730.164 300.488C730.805 299.847 730.805 298.809 730.164 298.168L725.789 293.793C725.149 293.152 724.11 293.152 723.469 293.793L719.094 298.168C718.454 298.809 718.454 299.847 719.094 300.488C719.735 301.129 720.774 301.129 721.414 300.488L722.989 298.914V307.631C721.874 306.404 720.524 305.405 719.008 304.698L710.319 300.647L712.411 299.885C713.262 299.575 713.701 298.634 713.391 297.782C713.081 296.931 712.14 296.492 711.289 296.802L705.474 298.918C704.623 299.228 704.184 300.169 704.494 301.021L706.61 306.835Z"
                fill="#E04713"
              />
            </g>
            <path
              d="M285.129 11.8125H558.129C618.328 11.8125 667.129 60.6135 667.129 120.812C667.129 181.012 618.328 229.812 558.129 229.812H272.129C211.93 229.812 163.129 278.613 163.129 338.812C163.129 399.012 211.93 447.812 272.129 447.812H558.129C618.328 447.812 667.129 496.613 667.129 556.812C667.129 617.012 618.328 665.812 558.129 665.812H272.129C211.93 665.812 163.129 714.613 163.129 774.812C163.129 835.012 211.93 883.812 272.129 883.812H558.129C618.328 883.812 667.129 932.613 667.129 992.812C667.129 1053.01 618.328 1101.81 558.129 1101.81H272.129"
              id="filling_path"
              stroke="url(#paint7_linear_112_434)"
              stroke-width="20"
              stroke-linecap="round"
            />
            <defs>
              <filter
                id="filter0_i_112_434"
                x="0.128906"
                y="0.8125"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_112_434"
                />
              </filter>
              <filter
                id="filter1_i_112_434"
                x="0.128906"
                y="437.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_112_434"
                />
              </filter>
              <filter
                id="filter2_i_112_434"
                x="0.128906"
                y="871.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_112_434"
                />
              </filter>
              <filter
                id="filter3_i_112_434"
                x="186.129"
                y="654.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_112_434"
                />
              </filter>
              <filter
                id="filter4_i_112_434"
                x="188.129"
                y="217.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_112_434"
                />
              </filter>
              <linearGradient
                id="paint0_linear_112_434"
                x1="103.629"
                y1="21.8125"
                x2="103.629"
                y2="186.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#1B91DA" />
                <stop offset="1" stop-color="#0E4D74" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_112_434"
                x1="103.629"
                y1="458.812"
                x2="103.629"
                y2="623.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#0065F4" />
                <stop offset="1" stop-color="#0F4798" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_112_434"
                x1="665.536"
                y1="871.812"
                x2="9.05439"
                y2="871.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#21D342" />
                <stop offset="1" stop-color="#258136" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_112_434"
                x1="103.629"
                y1="892.812"
                x2="103.629"
                y2="1057.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#21D342" />
                <stop offset="1" stop-color="#258136" />
              </linearGradient>
              <radialGradient
                id="paint4_radial_112_434"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(506.629 758.312) rotate(90) scale(103.5 320.5)"
              >
                <stop stop-color="#FFE81E" />
                <stop offset="1" stop-color="#C4B214" />
              </radialGradient>
              <linearGradient
                id="paint5_linear_112_434"
                x1="82.5"
                y1="0"
                x2="82.5"
                y2="165"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFE81E" />
                <stop offset="1" stop-color="#C4B214" />
              </linearGradient>
              <linearGradient
                id="paint6_linear_112_434"
                x1="724.629"
                y1="238.812"
                x2="724.629"
                y2="403.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FC490B" />
                <stop offset="1" stop-color="#C7471A" />
              </linearGradient>
              <linearGradient
                id="paint7_linear_112_434"
                x1="412.434"
                y1="2191.81"
                x2="412.434"
                y2="3063.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#09C2C1" />
                <stop offset="1" stop-color="#1D85D8" />
              </linearGradient>
              <clipPath id="clip0_112_434">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.6299 76.7285)"
                />
              </clipPath>
              <clipPath id="clip1_112_434">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.6299 513.312)"
                />
              </clipPath>
              <clipPath id="clip2_112_434">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.6299 947.312)"
                />
              </clipPath>
              <clipPath id="clip3_112_434">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(695.628 730.312)"
                />
              </clipPath>
              <clipPath id="clip4_112_434">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(696.629 293.312)"
                />
              </clipPath>
            </defs>
          </svg>
          <div className="content_detail">
            <div className="col_detail  ">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/resfllow1.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Expert Developers
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#004875;" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Specialists in technologies like PHP, Laravel, React, Node.js,
                Angular, Python, and more.
              </p>
            </div>
            <div className="col_detail  ">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/resfllow2.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Flexible Models
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#C7471A;" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Hire developers on a monthly, hourly, or project-based
                arrangement.
              </p>
            </div>
            <div className="col_detail  ">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/resfllow3.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Cost-Effective Solutions
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#0F4798;" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Avoid recruitment hassles and save on operational costs.
              </p>
            </div>
            <div className="col_detail  ">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/resfllow4.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Direct Communication
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#004875;" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Seamless collaboration with real-time updates.
              </p>
            </div>
            <div className="col_detail  ">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/seoflow5.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Scalability
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#C7471A;" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Easily scale your team up or down based on project needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomePhpServiceFlow;
