import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import Animate from "./Animate";
import { visitStore } from "../services/api/api";
import axios from "axios";
import Loading from './loading';

const Header = () => {

  const [isHeaderFixed, setHeaderFixed] = useState(false);
  const [isButtonMenuActive, setButtonMenuActive] = useState(false);
  const [isMenuListActive, setMenuListActive] = useState(false);
  const [ipAddress, setIpAddress] = useState("");

  const navigate = useNavigate();
  let location = useLocation();
  const { pathname } = useLocation();
  useEffect(() =>{
    setButtonMenuActive(false);
    setMenuListActive(false);
  }, [pathname])


  const [isLoading, setIsLoading] = useState(true);
    
  useEffect(() => {
    // Simulate an API call
    setTimeout(() => {
        setIsLoading(false);
    }, 2000);
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    // getData();
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      if (scroll >= 100) {
        setHeaderFixed(true);
      } else {
        setHeaderFixed(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array for componentDidMount

  const NavbarList = [
    { url: "home", name: "Home", hasChild: false },
    {
      name: "Services",
      hasChild: true,
      url:'/web-dev',
      child: [
        { url: "/mobile-dev", name: "Mobile App Development" },
        { url: "/web-dev", name: "Web Development" },
        { url: "/ui-ux", name: "UI / UX Design" },
        { url: "/resource-allocation", name: "Dedicated Developer Resource" },
        { url: "/maintenance", name: "Maintenance" },
        { url: "/seo", name: "SEO and Digital Marketing" },
      ],
    },
    { url: "our-work-detail", name: "Portfolio", hasChild: false },
    { url: "about", name: "About Us", hasChild: false },
    { url: "our-work", name: "Career", hasChild: false },
    { url: "blogs", name: "Blog", hasChild: false },
    { url: "contact-us", name: "Contact Us", hasChild: false },
  ];
  const webDevExists = NavbarList[1].child.some(
    (item) => item.url === location.pathname
  );
  const isServiceHeader = webDevExists;
  let headerClass = isHeaderFixed ? "fixed" : "";
  headerClass = isServiceHeader ? `${headerClass} white_header` : headerClass;

  const handleButtonMenuClick = () => {
    setButtonMenuActive(!isButtonMenuActive);
    setMenuListActive(!isMenuListActive);
  };


  if (isLoading) {
    return <Loading />;
  }

  return (
    <header className={headerClass}>
      <div className="wrapper">
        <a onClick={() => navigate("/")} className="logo">
          <Logo />
        </a>
        <nav>
          <ul className={`menu-list ${isMenuListActive ? "active" : ""}`}>
            {NavbarList.map((item, index) => (
              <>
                <li key={index}>
                  <a
                    class={location.pathname === `/${item.url}` ? "active" : ""}
                    onClick={() => navigate(item.url)}
                  >
                    {item.name}
                  </a>
                  {item.hasChild && (
                    <ul class="submenu">
                      {item.child.map((subItem) => (
                        <li>
                          <a onClick={() => navigate(subItem.url)}>
                            {subItem.name}
                          </a>
                        </li>
                        // <li><a href="#">Ui / Ux</a></li>
                        // <li><a href="#">Costom PHP Development</a></li>
                        // <li><a href="#">Mobile App Development</a></li>
                        // <li><a href="#">Search Engine Optimization</a></li>
                      ))}
                    </ul>
                  )}
                </li>
              </>
            ))}
          </ul>
          <button
            className={`button_menu ${isButtonMenuActive ? "active" : ""}`}
            onClick={handleButtonMenuClick}
          >
            <div></div>
            <div></div>
            <div></div>
          </button>
        </nav>
      </div>
    </header>
  );
};

export default Header;
