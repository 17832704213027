import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./Testimonial.css"; // Import your CSS file for styling (if needed)
import { getClients } from "../services/api/api";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

const Testimonial = ({ clientLogos, clientLists }) => {
  // const [clients, setClients] = useState([]);

  // useEffect(() => {
  //   getClients().then((res) => {
  //     setClients(res.data.result);
  //   });
  // }, []);
  const clientLogoList = [
    "./assets/images/site/company4.png",
    "./assets/images/site/company11.png",
    "./assets/images/site/company10.png",
    "./assets/images/site/company12.png",
    "./assets/images/site/company13.png",
    "./assets/images/site/company14.png",
    "./assets/images/site/company15.png",
    "./assets/images/site/company16.png",
    "./assets/images/site/company17.png",
  ];

  const clients = [
    {
      image: "./assets/images/site/client1.png",
      position: "Dr. Ravi",
      companyName: "Chief Physical Therapist",
      description:
        "Big shoutout to CodeFlix for bringing my vision to life with the Movement On Physio website! The design is clean, professional, and user-friendly—exactly what I wanted. Their team was super responsive and made the entire process so smooth. If you need a website that truly stands out, CodeFlix is the one to go for!",
    },
    {
      image: "./assets/images/site/client3.svg",
      position: "Mr. Mohhamad",
      companyName: "CEO-Leza Solutions",
      description:
        "Working with Codeflix Web LLC over the past five years has been nothing short of exceptional. Their expertise and commitment have enabled us to deliver hundreds of successful projects with precision and excellence. As a software company, we trust them as our go-to partner for delivering top-notch solutions, and they consistently exceed our expectations.",
    }, {
      image: "./assets/images/site/client3.jpg",
      position: "Mr David",
      companyName: "CEO - HIR Industries",
      description:
        "Partnering with this team was a total win for us. The staff management app they built has made a huge difference in how we track and manage our team. The app is super customizable, and the attention to detail really stands out. If you're looking for something reliable and innovative, they’re the ones to work with",
    },  
    // Add more items as needed
  ];

  const settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    draggable: true,
    arrows: true,
    className: "client_list",
    focusOnSelect: true,
    variableWidth: false,
    adaptiveHeight: false,
    prevArrow: (
      <div className="slickbtn slick-prev">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.41394 8.00003L13.9999 8.00003L13.9999 6.00003L4.41394 6.00003L8.70694 1.70703L7.29294 0.293032L0.585938 7.00003L7.29294 13.707L8.70694 12.293L4.41394 8.00003Z"
            fill="black"
          />
        </svg>
      </div>
    ),
    nextArrow: (
      <div className="slickbtn slick-next">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.58606 5.99997L6.2693e-05 5.99997L6.26056e-05 7.99997L9.58606 7.99997L5.29306 12.293L6.70706 13.707L13.4141 6.99997L6.70706 0.292968L5.29306 1.70697L9.58606 5.99997Z"
            fill="black"
          />
        </svg>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <div className="testimonial">
      <div className="wrapper">
        <div className="testimonial_wrap">
          <div className="left_col">
            <div className="title">
              <a href="javascript:void(0)" data-aos="zoom-out-up" className="button-container-1">
                <span className="mas"> 
                  Testimonial
                </span>
                <span className="mask_btn">
                  
                  Testimonial
                </span>
              </a>
              <h2
                data-aos="zoom-out-up"
                dangerouslySetInnerHTML={{
                  __html: "They Trust Us",
                }}
              ></h2>
              <p
                data-aos="zoom-out-up"
                dangerouslySetInnerHTML={{
                  __html:
                    "From ideas to execution, we’ve been privileged to work with these remarkable brands, delivering value-driven solutions.",
                }}
              ></p>
            </div>
          </div>
          <div className="right_col">
            <div className="client_logo_list">
              {clientLogoList.map((item, index) => (
                <div className="image" data-aos="zoom-out-up" key={index}>
                  <img src={item} alt={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="client_slider">
          <Slider {...settings}>
            {clients.map((item, index) => (
              <div className="client_item" key={index}>
                <div className="client_col">
                  <div className="detail">
                    <div className="icon">
                      <img src="./assets/images/site/quote.svg" alt="" />
                    </div>
                    <div className="client_position">
                      <div className="c_company_name">
                        <span
                          className="position"
                          dangerouslySetInnerHTML={{ __html: item.position }}
                        ></span>
                        <span
                          className="company_name"
                          dangerouslySetInnerHTML={{ __html: item.companyName }}
                        ></span>
                      </div>
                      <div className="c_image">
                        <img
                          src={item.image}
                          alt={item.image}
                          style={{
                            height: 88,
                            width: 90,
                            borderRadius: 10,
                          }}
                        />
                      </div>
                    </div>
                    <p
                      style={{ overflow: "hidden", textWrap: "wrap" }}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
         
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
