import React from 'react'; 
    
    function Loading() {
      return (
        <div className="loader-container">
          <div className="loader">
{/* <svg width="157" height="68" viewBox="0 0 157 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9412 18.8897L33.8166 0L44.6795 18.8897H22.9412Z" fill="#494949"/>
<path d="M33.7905 37.6909L44.666 18.8013L55.5288 37.6909H33.7905Z" fill="#494949"/>
<path d="M55.5288 0L44.666 18.8897L33.7905 0H55.5288Z" fill="#333333"/>
<path d="M44.6678 18.7759L33.7923 37.6655L22.9294 18.7759H44.6678Z" fill="#333333"/>
<path d="M32.5887 39.5771L21.7258 58.4795L10.8503 39.5771H32.5887Z" fill="#0A9C9E"/>
<path d="M21.7383 20.6118L10.8755 39.5015L0 20.6118H21.7383Z" fill="#0A9C9E"/>
<path d="M0 58.4795L10.8755 39.5771L21.7383 58.4795H0Z" fill="#01DFE1"/>
<path d="M10.876 39.5269L21.7388 20.6372L32.6143 39.5269H10.876Z" fill="#01DFE1"/>
<path d="M49.8729 42.5146H40.39C40.39 42.5146 33.4014 43.0337 33.4014 50.5921C33.4014 58.1505 40.8078 58.6696 40.8078 58.6696H61.6473C61.6473 58.6696 68.4967 58.2518 68.9271 50.5921C68.9271 50.5921 68.9018 48.0473 68.0282 46.4648H64.2553C64.2553 46.4648 66.0152 47.4523 66.1165 50.5921C66.2051 53.7193 63.7363 55.5551 61.8625 55.783C59.9887 56.0109 40.8205 55.783 40.8205 55.783C40.8205 55.783 36.5665 55.6817 36.1487 50.5921C36.1487 50.5921 35.8702 46.009 40.5926 45.3633H49.8475L49.8729 42.5146Z" fill="#01DFE1"/>
<path d="M53.8978 54.6308H50.3528C50.3528 54.6308 48.4917 51.339 49.5932 47.7308C50.6947 44.1225 54.4802 42.7045 56.0374 42.5146C57.5947 42.3247 80.2193 42.5146 80.2193 42.5146C80.2193 42.5146 82.245 42.9071 82.7261 43.4388V47.1231C82.7261 47.1231 81.9031 45.5911 79.3583 45.2873C76.8135 44.9834 56.316 45.2873 56.316 45.2873C56.316 45.2873 52.3279 46.1862 52.1506 49.7058C51.9734 53.2381 53.8978 54.6308 53.8978 54.6308Z" fill="#01DFE1"/>
<path d="M69.6987 46.5027H72.3955V55.7577H79.5361C79.5361 55.7577 84.2965 55.4791 83.9293 50.1237V42.5146H98.3877V45.4139H86.6766L86.74 50.4275C86.74 50.4275 86.74 54.7575 84.0432 56.7199C84.0432 56.7199 81.853 58.6696 79.6247 58.6696C77.3964 58.6696 69.5215 58.6696 69.5215 58.6696L69.6987 46.5027Z" fill="#01DFE1"/>
<path d="M97.6282 49.25H88.8291V51.6682H97.6282V49.25Z" fill="#01DFE1"/>
<path d="M83.9631 58.3279C83.9631 58.3279 86.2041 56.5554 86.6852 55.2134V55.8337H98.3963V58.6571H83.9631V58.3279Z" fill="#01DFE1"/>
<path d="M100.661 42.5269V58.6565H103.421V45.3122H114.018V42.5269H100.661Z" fill="#333333"/>
<path d="M113.499 49.6172H105.497V51.9594H113.499V49.6172Z" fill="#333333"/>
<path d="M116.097 42.5273V58.657H129.403V55.8843H118.781V42.5273H116.097Z" fill="#333333"/>
<path d="M131.631 42.5273V58.7203H134.289V42.5273H131.631Z" fill="#333333"/>
<path d="M137.262 42.5273H140.984L146.884 48.5664L152.809 42.5146H156.253L148.568 50.3642L157 58.7203H153.164L146.504 52.1114L139.933 58.7203H136.375L144.833 50.3516L137.262 42.5273Z" fill="#333333"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M84.8909 66.9114H60.6711V64.709H84.8909V66.9114Z" fill="#01DFE1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M129.383 66.9114H105.15V64.709H129.383V66.9114Z" fill="#01DFE1"/>
<path d="M103.271 65.6201C103.828 65.5189 103.815 64.7466 103.815 64.7466C103.815 63.7464 102.764 63.6704 102.764 63.6704H99.4727H99.4094V67.8484C99.4094 67.8484 102.613 67.8484 102.802 67.8484C102.98 67.8484 103.942 67.5825 103.942 66.7216C103.929 65.8734 103.271 65.6201 103.271 65.6201ZM102.549 67.1268H100.156V64.4174H102.549C102.549 64.4174 103.081 64.4427 103.081 64.9238C103.081 65.3923 102.575 65.4302 102.575 65.4302H100.688V66.0506H102.638C102.638 66.0506 103.144 66.038 103.17 66.5824C103.17 67.1268 102.549 67.1268 102.549 67.1268Z" fill="#333333"/>
<path d="M94.4873 63.6831H98.1969V64.4048H95.1963V67.1268H98.1969V67.8611H94.4873V63.6831Z" fill="#333333"/>
<path d="M98.0324 65.4175H95.7661V66.0505H98.0324V65.4175Z" fill="#333333"/>
<path d="M86.3201 63.6831H87.1683L88.8649 66.8483L89.4852 65.7974L88.4091 63.6831H89.308L90.9285 66.8483L92.6124 63.6831H93.41L91.3843 67.4306C91.3843 67.4306 91.1691 67.9371 90.8906 67.9371C90.612 67.9371 90.4348 67.5699 90.4348 67.5699L89.8904 66.4938L89.2194 67.7092C89.2194 67.7092 89.0927 67.9371 88.8522 67.9371C88.6116 67.9371 88.4344 67.6206 88.4344 67.6206L86.3201 63.6831Z" fill="#333333"/>
</svg> */}
 <img src="./assets/images/site/Comp 1_1.gif" alt="" />
</div>
        </div>
      );
    }
    
    export default Loading;