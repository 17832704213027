import React from 'react'

const Footer = () => {
  return (
    <footer>
    <div className="wrapper">
      <div className="footer_row">
        <div className="copy_text">©Concept Design Codeflix Web LLC</div>
        <div className="social_link">
          <a href="https://www.facebook.com/share/1AudeMPLWy/?mibextid=wwXIfr" target='_blank'>
            <img src="./assets/images/site/social/Facebook.svg" alt="" />
          </a>
          <a href="https://www.linkedin.com/company/codeflix-web-llp/" target='_blank'>
            <img src="./assets/images/site/social/Linkedin.svg" alt="" />
          </a>
          <a href="https://www.instagram.com/codeflixwebllp/profilecard/?igsh=bnBkdWZqZjA1NmR1" target='_blank'>
            <img src="./assets/images/site/social/Instagram.svg" alt="" />
          </a>
          <a href="https://dribbble.com/CodeflixWeb" target='_blank'>
            <img src="./assets/images/site/social/Dribble.svg" alt="" />
          </a>
          <a href="https://www.behance.net/codeflix" target='_blank'>
            <img src="./assets/images/site/social/Behance.svg" alt="" />
          </a>
          <a href="https://in.pinterest.com/codeflix/" target='_blank'>
            <img src="./assets/images/site/social/Social.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
    <div className="static_link">
      <a href="https://wa.me/+19412984092" target='_blank' className='static_icon'><img src="./assets/images/site/Whatsapp.svg" alt="" /></a>
      {/* <a href="" className='static_icon'><img src="./assets/images/site/telegram.svg" alt="" /></a> */}
    </div>
  </footer>
  )
}

export default Footer
