const BlogArticle = ({ items }) => {
  const convertDateFormat = ({ dateString }) => {
    console.log(dateString);
    const dateObject = new Date(Date.parse(dateString));
    const formattedDate = dateObject.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  };
  return (
    <div className="articles">
      <div className="wrapper">
        <div className="articles_title">
          <div className="left_col">
            <h3 data-aos="zoom-out-up">Articles</h3>
            <p data-aos="zoom-out-up">
            Discover insights, trends, and expert tips through our curated collection of articles. Stay informed and inspired with the latest from the tech world and beyond.
            </p>
          </div>
          <div className="right_col">
           
          </div>
        </div>
        <div className="article_list">
          <div className="article_row">
            {items.map((item, index) => {
             // console.log(item.page_content?.match(/<h2>(.*?)<\/h2>/));
              //let page_content = item.page_content?.match(/<h2>(.*?)<\/h2>/);
              return (
                // href={`/blog/${item.id}`}
                <a
                  href={item.url}
                  className="article_col"
                  style={{
                    backgroundImage: `url(${item?.image})`,
                  }}
                >
                  <a href="javascript:void(0)" className="tags">
                    {item?.tags[0].name}
                  </a>
                  <div className="blog-extra">
                    <div
                      className="detail"
                      
                    ><h2>{item.name}</h2></div>
                    {/* dangerouslySetInnerHTML={{ __html: item.page_content?.match(/<h2>(.*?)<\/h2>/)[0]}} */}
                    <div className="labels">
                      <a href="javascript:void(0)" className="author">
                        <img src="./assets/images/new/author.svg" alt="" />{" "}
                        <span>@{item.author}</span>
                      </a>
                      <p>
                        { item.date } 
                      </p>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogArticle;
