import React from "react";
import { motion } from "framer-motion";

const SEOFlow = () => {
  return (
    <div className="serivces_flow serivces_flow6  " id="wrap">
      <div className="titles">
        <div className="wrapper">
          {/* <a href="javascript:void(0)" className="btn-blue">
            HEIGHTEN YOUR SPLENDOUR
          </a>
          <h3>Technology We Use</h3>
          <p>
            Reference site about Lorem Ipsum, giving informationon its origins,
            as well as a random Lipsum generator. Reference site about Lorem
            Ipsum, giving informationon its origins, as well as a random Lipsum
            generator.
          </p> */}
        </div>
      </div>
      <div className="wrapper">
        <div className="service_flow_wrap">
          <svg
            width="830"
            height="1550"
            viewBox="0 0 830 1550"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_i_110_350)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 207.501C109.048 207.708 106.351 207.812 103.629 207.812C46.4674 207.812 0.128906 161.474 0.128906 104.312C0.128906 47.151 46.4674 0.8125 103.629 0.8125C124.168 0.8125 143.31 6.79531 159.407 17.1133C173.432 26.1034 188.868 33.8125 205.527 33.8125H554.129C602.178 33.8125 641.129 72.7637 641.129 120.812C641.129 168.861 602.178 207.812 554.129 207.812H119.129C116.632 207.812 114.16 207.707 111.717 207.501Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 207.003L111.718 207L111.678 207.003C109.022 207.208 106.338 207.312 103.629 207.312C46.7436 207.312 0.628906 161.198 0.628906 104.312C0.628906 47.4271 46.7436 1.3125 103.629 1.3125C124.07 1.3125 143.118 7.26633 159.137 17.5342C173.181 26.5359 188.718 34.3125 205.527 34.3125H554.129C601.902 34.3125 640.629 73.0398 640.629 120.812C640.629 168.585 601.902 207.312 554.129 207.312H119.129C116.646 207.312 114.188 207.208 111.759 207.003Z"
              stroke="#00E1DB"
            />
            <circle
              cx="103.629"
              cy="104.312"
              r="82.5"
              fill="url(#paint0_linear_110_350)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="104.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip0_110_350)">
              <path
                d="M80.562 106.851C78.9027 106.851 77.2938 106.317 76.2465 105.78L75.4561 108.997C76.4259 109.533 78.3673 110.043 80.333 110.043C85.0568 110.043 87.2776 107.592 87.2776 104.707C87.2776 102.282 85.8473 100.699 82.8098 99.5751C80.5881 98.7315 79.6183 98.2475 79.6183 97.1497C79.6183 96.2556 80.4349 95.4895 82.1203 95.4895C83.8058 95.4895 85.0316 95.9753 85.7202 96.307L86.589 93.1668C85.567 92.7071 84.1375 92.2988 82.1969 92.2988C78.1374 92.2988 75.6859 94.5449 75.6859 97.4814C75.6859 99.9834 77.5498 101.566 80.4097 102.562C82.4773 103.303 83.2947 103.916 83.2947 104.987C83.2947 106.111 82.3493 106.851 80.562 106.851Z"
                fill="#1375B2"
              />
              <path
                d="M100.886 106.596H93.8144V102.512H100.145V99.3452H93.8144V95.7706H100.529V92.5791H89.9072V109.788H100.886V106.596Z"
                fill="#1375B2"
              />
              <path
                d="M114.045 111.094C116.461 111.094 118.675 110.224 120.399 108.784L123.826 111.983C123.821 112.339 123.95 112.693 124.231 112.955L129.26 117.651C129.512 117.886 129.83 118.002 130.15 118.002C130.499 118.002 130.848 117.862 131.105 117.586C131.597 117.06 131.568 116.231 131.042 115.74L126.013 111.045C125.733 110.783 125.369 110.679 125.015 110.708L121.633 107.552C123.088 105.826 123.97 103.599 123.97 101.169C123.97 95.6975 119.519 91.2471 114.048 91.2471C108.576 91.2471 104.123 95.6992 104.123 101.17C104.122 106.643 108.573 111.094 114.045 111.094ZM114.045 93.8597C118.077 93.8597 121.356 97.1392 121.356 101.17C121.356 105.201 118.075 108.481 114.045 108.481C110.015 108.481 106.734 105.201 106.734 101.17C106.734 97.1392 110.013 93.8597 114.045 93.8597Z"
                fill="#1375B2"
              />
            </g>
            <g filter="url(#filter1_i_110_350)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 644.501C109.048 644.708 106.351 644.812 103.629 644.812C46.4674 644.812 0.128906 598.474 0.128906 541.312C0.128906 484.151 46.4674 437.812 103.629 437.812C124.168 437.812 143.31 443.795 159.407 454.113C173.432 463.103 188.868 470.812 205.527 470.812H554.129C602.178 470.812 641.129 509.764 641.129 557.812C641.129 605.861 602.178 644.812 554.129 644.812H119.129C116.632 644.812 114.16 644.707 111.717 644.501Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 644.003L111.718 644L111.678 644.003C109.022 644.208 106.338 644.312 103.629 644.312C46.7436 644.312 0.628906 598.198 0.628906 541.312C0.628906 484.427 46.7436 438.312 103.629 438.312C124.07 438.312 143.118 444.266 159.137 454.534C173.181 463.536 188.718 471.312 205.527 471.312H554.129C601.902 471.312 640.629 510.04 640.629 557.812C640.629 605.585 601.902 644.312 554.129 644.312H119.129C116.646 644.312 114.188 644.208 111.759 644.003Z"
              stroke="#00E1DB"
            />
            <circle
              cx="103.629"
              cy="541.312"
              r="82.5"
              fill="url(#paint1_linear_110_350)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="541.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip1_110_350)">
              <path
                d="M80.8652 546.821H77.584C76.6779 546.821 75.9434 546.087 75.9434 545.181V533.696C75.9434 532.79 76.6779 532.056 77.584 532.056H80.8652V546.821Z"
                fill="#0051C4"
              />
              <path
                d="M92.3496 565.142H87.4277C86.5217 565.142 85.7871 564.407 85.7871 563.501V553.11H93.9902V563.501C93.9902 564.407 93.2557 565.142 92.3496 565.142Z"
                fill="#0051C4"
              />
              <path
                d="M93.9902 529.047H85.7871C84.881 529.047 84.1465 529.781 84.1465 530.688V548.188C84.1465 549.094 84.881 549.828 85.7871 549.828H93.9902V529.047Z"
                fill="#0051C4"
              />
              <path
                d="M124.687 518.409C124.05 517.964 123.213 518.026 122.653 518.521C115.638 524.73 106.627 528.439 97.2715 528.975V549.9C106.627 550.436 115.638 554.145 122.653 560.354C122.959 560.624 123.347 560.766 123.741 560.766C124.034 560.766 124.329 560.687 124.595 560.526C125.094 560.222 125.381 559.664 125.381 559.081V519.79C125.381 519.246 125.132 518.722 124.687 518.409Z"
                fill="#0051C4"
              />
              <path
                d="M128.662 532.88V545.996C130.653 544.498 131.943 542.116 131.943 539.438C131.943 536.76 130.653 534.378 128.662 532.88Z"
                fill="#0051C4"
              />
            </g>
            <g filter="url(#filter2_i_110_350)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 1078.5C109.048 1078.71 106.351 1078.81 103.629 1078.81C46.4674 1078.81 0.128906 1032.47 0.128906 975.312C0.128906 918.151 46.4674 871.812 103.629 871.812C124.168 871.812 143.31 877.795 159.407 888.113C173.432 897.103 188.868 904.812 205.527 904.812H554.129C602.178 904.812 641.129 943.764 641.129 991.812C641.129 1039.86 602.178 1078.81 554.129 1078.81H119.129C116.632 1078.81 114.16 1078.71 111.717 1078.5Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 1078L111.718 1078L111.678 1078C109.022 1078.21 106.338 1078.31 103.629 1078.31C46.7436 1078.31 0.628906 1032.2 0.628906 975.312C0.628906 918.427 46.7436 872.312 103.629 872.312C124.07 872.312 143.118 878.266 159.137 888.534C173.181 897.536 188.718 905.312 205.527 905.312H554.129C601.902 905.312 640.629 944.04 640.629 991.812C640.629 1039.59 601.902 1078.31 554.129 1078.31H119.129C116.646 1078.31 114.188 1078.21 111.759 1078Z"
              stroke="url(#paint2_linear_110_350)"
            />
            <circle
              cx="103.629"
              cy="975.312"
              r="82.5"
              fill="url(#paint3_linear_110_350)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="975.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip2_110_350)">
              <path
                d="M110.791 951.092H96.7058C85.12 951.092 75.6943 960.517 75.6943 972.103C75.6943 984.249 85.1906 989.718 86.2234 990.314C85.8351 995.481 82.3501 996.862 82.0934 996.956C81.3212 997.199 80.857 997.964 80.9783 998.765C81.0999 999.569 81.8074 1000.16 82.6202 1000.16C91.8417 1000.16 96.1949 995.209 97.6244 993.115H110.791C138.673 991.957 138.651 952.239 110.791 951.092ZM87.7854 973.864C87.6985 976.045 84.5838 976.043 84.4978 973.864V970.342C84.5847 968.161 87.6994 968.163 87.7854 970.342V973.864ZM94.8282 980.907C94.7413 983.088 91.6266 983.086 91.5406 980.907V963.3C91.6275 961.119 94.7422 961.12 94.8282 963.3V980.907ZM101.871 975.625C101.784 977.806 98.6695 977.804 98.5834 975.625V968.582C98.6703 966.401 101.785 966.402 101.871 968.582V975.625ZM108.914 984.428C108.827 986.609 105.712 986.607 105.626 984.428V959.778C105.713 957.597 108.828 957.599 108.914 959.778V984.428ZM115.957 979.146C115.87 981.327 112.755 981.325 112.669 979.146V965.06C112.756 962.879 115.871 962.881 115.957 965.06V979.146ZM122.999 973.864C122.912 976.045 119.798 976.043 119.712 973.864V970.342C119.799 968.161 122.913 968.163 122.999 970.342V973.864Z"
                fill="#1BB738"
              />
            </g>
            <g filter="url(#filter3_i_110_350)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 1513.5C109.048 1513.71 106.351 1513.81 103.629 1513.81C46.4674 1513.81 0.128906 1467.47 0.128906 1410.31C0.128906 1353.15 46.4674 1306.81 103.629 1306.81C124.168 1306.81 143.31 1312.8 159.407 1323.11C173.432 1332.1 188.868 1339.81 205.527 1339.81H554.129C602.178 1339.81 641.129 1378.76 641.129 1426.81C641.129 1474.86 602.178 1513.81 554.129 1513.81H119.129C116.632 1513.81 114.16 1513.71 111.717 1513.5Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 1513L111.718 1513L111.678 1513C109.022 1513.21 106.338 1513.31 103.629 1513.31C46.7436 1513.31 0.628906 1467.2 0.628906 1410.31C0.628906 1353.43 46.7436 1307.31 103.629 1307.31C124.07 1307.31 143.118 1313.27 159.137 1323.53C173.181 1332.54 188.718 1340.31 205.527 1340.31H554.129C601.902 1340.31 640.629 1379.04 640.629 1426.81C640.629 1474.59 601.902 1513.31 554.129 1513.31H119.129C116.646 1513.31 114.188 1513.21 111.759 1513Z"
              stroke="url(#paint4_linear_110_350)"
            />
            <circle
              cx="103.629"
              cy="1410.31"
              r="82.5"
              fill="url(#paint5_linear_110_350)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="1410.31"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip3_110_350)">
              <path
                d="M88.2136 1417.5L91.1184 1420.41L96.0793 1415.4C91.3125 1409.72 90.2984 1402.21 92.5141 1395.85L99.1152 1402.45C99.0589 1409.09 104.462 1414.89 111.513 1414.89C115.652 1414.89 119.323 1412.85 121.576 1409.73L114.081 1402.23L108.884 1407.43L89.5559 1388.1V1392.48H86.1664L86.1665 1382.32H96.3351V1385.7L91.9527 1385.7L108.884 1402.64L114.081 1397.44L123.219 1406.58C124.718 1402.29 123.806 1397.25 120.279 1393.73C115.761 1389.21 108.665 1388.92 103.817 1392.77L98.3373 1387.29C106.231 1380.43 118.21 1380.74 125.737 1388.27C129.536 1392.07 131.629 1397.12 131.629 1402.49C131.629 1413.59 122.605 1422.61 111.513 1422.61C106.676 1422.61 102.101 1420.91 98.4647 1417.81L93.5151 1422.8L96.4317 1425.72L83.8474 1438.3L75.6289 1430.09L88.2136 1417.5Z"
                fill="#14A99F"
              />
              <path
                d="M104.454 1428.14H131.57V1431.53H104.454V1428.14Z"
                fill="#14A99F"
              />
              <path
                d="M94.2842 1434.92H114.622V1438.31H94.2842V1434.92Z"
                fill="#14A99F"
              />
            </g>
            <g filter="url(#filter4_i_110_350)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M715.541 861.501C718.21 861.708 720.907 861.812 723.629 861.812C780.79 861.812 827.129 815.474 827.129 758.312C827.129 701.151 780.79 654.812 723.629 654.812C703.09 654.812 683.948 660.795 667.851 671.113C653.825 680.103 638.39 687.812 621.731 687.812H273.129C225.08 687.812 186.129 726.764 186.129 774.812C186.129 822.861 225.08 861.812 273.129 861.812H708.129C710.626 861.812 713.098 861.707 715.541 861.501Z"
                fill="white"
              />
            </g>
            <path
              d="M715.499 861.003L715.539 861L715.58 861.003C718.236 861.208 720.92 861.312 723.629 861.312C780.514 861.312 826.629 815.198 826.629 758.312C826.629 701.427 780.514 655.312 723.629 655.312C703.188 655.312 684.139 661.266 668.121 671.534C654.077 680.536 638.54 688.312 621.731 688.312H273.129C225.356 688.312 186.629 727.04 186.629 774.812C186.629 822.585 225.356 861.312 273.129 861.312H708.129C710.612 861.312 713.07 861.208 715.499 861.003Z"
              stroke="url(#paint6_radial_110_350)"
            />
            <circle
              cx="82.5"
              cy="82.5"
              r="82.5"
              transform="matrix(-1 0 0 1 806.129 675.812)"
              fill="url(#paint7_linear_110_350)"
            />
            <circle
              opacity="0.9"
              cx="59.1949"
              cy="59.1949"
              r="59.1949"
              transform="matrix(-1 0 0 1 782.823 699.117)"
              fill="white"
            />
            <g clip-path="url(#clip4_110_350)">
              <path
                d="M709.068 768.361V775.357L714.314 771.859L709.068 768.361Z"
                fill="#DDCA27"
              />
              <path
                d="M695.943 757.094V786.625H725.475V757.094H695.943ZM705.787 781.487V762.232L720.229 771.859L705.787 781.487Z"
                fill="#DDCA27"
              />
              <path
                d="M728.975 750.531V786.625H751.943V750.531H728.975ZM745.381 780.062H735.537V776.781H745.381V780.062ZM745.381 773.5H735.537V770.219H745.381V773.5ZM745.381 766.938H735.537V763.656H745.381V766.938ZM745.381 760.375H735.537V757.094H745.381V760.375Z"
                fill="#DDCA27"
              />
              <path
                d="M704.151 739.794L707.428 743.071L713.99 736.508L725.475 747.993V730.625H695.943V747.939L704.151 739.794Z"
                fill="#DDCA27"
              />
              <path
                d="M713.99 741.148L707.428 747.711L704.142 744.425L695.943 752.561V753.594H725.475V752.633L713.99 741.148Z"
                fill="#DDCA27"
              />
              <path
                d="M751.943 747.031H728.975V730.625H751.943V747.031Z"
                fill="#DDCA27"
              />
            </g>
            <g filter="url(#filter5_i_110_350)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M715.541 1295.5C718.21 1295.71 720.907 1295.81 723.629 1295.81C780.79 1295.81 827.129 1249.47 827.129 1192.31C827.129 1135.15 780.79 1088.81 723.629 1088.81C703.09 1088.81 683.948 1094.8 667.851 1105.11C653.825 1114.1 638.39 1121.81 621.731 1121.81H273.129C225.08 1121.81 186.129 1160.76 186.129 1208.81C186.129 1256.86 225.08 1295.81 273.129 1295.81H708.129C710.626 1295.81 713.098 1295.71 715.541 1295.5Z"
                fill="white"
              />
            </g>
            <path
              d="M715.499 1295L715.539 1295L715.58 1295C718.236 1295.21 720.92 1295.31 723.629 1295.31C780.514 1295.31 826.629 1249.2 826.629 1192.31C826.629 1135.43 780.514 1089.31 723.629 1089.31C703.188 1089.31 684.139 1095.27 668.121 1105.53C654.077 1114.54 638.54 1122.31 621.731 1122.31H273.129C225.356 1122.31 186.629 1161.04 186.629 1208.81C186.629 1256.59 225.356 1295.31 273.129 1295.31H708.129C710.612 1295.31 713.07 1295.21 715.499 1295Z"
              stroke="url(#paint8_linear_110_350)"
            />
            <circle
              cx="82.5"
              cy="82.5"
              r="82.5"
              transform="matrix(-1 0 0 1 806.129 1109.81)"
              fill="url(#paint9_linear_110_350)"
            />
            <circle
              opacity="0.9"
              cx="59.1949"
              cy="59.1949"
              r="59.1949"
              transform="matrix(-1 0 0 1 782.823 1133.12)"
              fill="white"
            />
            <g clip-path="url(#clip5_110_350)">
              <path
                d="M695.63 1164.31H699.728V1168.41H695.63V1164.31Z"
                fill="#1ED38D"
              />
              <path
                d="M695.63 1216.21H699.728V1220.31H695.63V1216.21Z"
                fill="#1ED38D"
              />
              <path
                d="M747.531 1164.31H751.63V1168.41H747.531V1164.31Z"
                fill="#1ED38D"
              />
              <path
                d="M747.531 1216.21H751.63V1220.31H747.531V1216.21Z"
                fill="#1ED38D"
              />
              <path
                d="M749.582 1197.23H745.068V1187.39H749.582V1171.69H744.251V1166.36H728.552V1170.88H718.708V1166.36H703.01V1171.69H697.679V1187.39H702.193V1197.23H697.679V1212.93H703.01V1218.39H718.708V1213.75H728.552V1218.39H744.251V1212.93H749.582V1197.23ZM735.935 1200.45L729.714 1206.54H711.325V1184.65L717.892 1178.08H727.841V1190.14H735.935V1200.45Z"
                fill="#1ED38D"
              />
              <path
                d="M724.56 1181.36H719.251L715.943 1184.67H721.169V1196.7H729.372V1202.29L732.654 1199.07V1193.42H724.56V1181.36Z"
                fill="#1ED38D"
              />
              <path
                d="M717.888 1199.98C717.888 1197.15 717.888 1190.72 717.888 1187.95H714.606V1203.26H726.091C726.091 1203.1 726.091 1200.24 726.091 1199.98C724.593 1199.98 719.47 1199.98 717.888 1199.98Z"
                fill="#1ED38D"
              />
              <path
                d="M725.271 1164.31H721.989V1167.59H725.271V1164.31Z"
                fill="#1ED38D"
              />
              <path
                d="M748.349 1190.67V1193.95H751.63V1190.67C750.86 1190.67 749.101 1190.67 748.349 1190.67Z"
                fill="#1ED38D"
              />
              <path
                d="M698.911 1193.95V1190.67C698.165 1190.67 696.393 1190.67 695.63 1190.67V1193.95H698.911Z"
                fill="#1ED38D"
              />
              <path
                d="M721.989 1220.31H725.271C725.271 1219.52 725.271 1217.82 725.271 1217.03H721.989V1220.31Z"
                fill="#1ED38D"
              />
            </g>
            <g filter="url(#filter6_i_110_350)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M717.541 424.501C720.21 424.708 722.907 424.812 725.629 424.812C782.79 424.812 829.129 378.474 829.129 321.312C829.129 264.151 782.79 217.812 725.629 217.812C705.09 217.812 685.948 223.795 669.851 234.113C655.825 243.103 640.39 250.812 623.731 250.812H275.129C227.08 250.812 188.129 289.764 188.129 337.812C188.129 385.861 227.08 424.812 275.129 424.812H710.129C712.626 424.812 715.098 424.707 717.541 424.501Z"
                fill="white"
              />
            </g>
            <path
              d="M717.499 424.003L717.539 424L717.58 424.003C720.236 424.208 722.92 424.312 725.629 424.312C782.514 424.312 828.629 378.198 828.629 321.312C828.629 264.427 782.514 218.312 725.629 218.312C705.188 218.312 686.139 224.266 670.121 234.534C656.077 243.536 640.54 251.312 623.731 251.312H275.129C227.356 251.312 188.629 290.04 188.629 337.812C188.629 385.585 227.356 424.312 275.129 424.312H710.129C712.612 424.312 715.07 424.208 717.499 424.003Z"
              stroke="#00E1DB"
            />
            <circle
              cx="724.629"
              cy="321.312"
              r="82.5"
              fill="url(#paint10_linear_110_350)"
            />
            <circle
              opacity="0.9"
              cx="724.629"
              cy="321.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip6_110_350)">
              <path
                d="M724.848 296.047C708.582 296.047 696.53 311.196 700.246 327.107C700.503 327.065 699.946 327.117 707.334 326.502C703.867 314.831 712.654 303.047 724.848 303.047C734.919 303.047 743.113 311.241 743.113 321.312C743.113 333.275 731.719 342.07 720.121 338.958C719.528 346.077 719.538 345.969 719.532 346.021C727.763 347.771 736.477 345.413 742.713 339.177C752.587 329.303 752.588 313.322 742.713 303.447C737.941 298.675 731.596 296.047 724.848 296.047Z"
                fill="#E04713"
              />
              <path
                d="M747.118 300.488L748.868 298.738C749.509 298.097 749.509 297.059 748.868 296.418C748.227 295.777 747.189 295.777 746.548 296.418L744.798 298.168C744.157 298.809 744.157 299.847 744.798 300.488C745.439 301.129 746.477 301.129 747.118 300.488Z"
                fill="#E04713"
              />
              <path
                d="M740.084 298.33C740.924 298.676 741.882 298.273 742.225 297.438L742.991 295.578C743.336 294.74 742.937 293.782 742.099 293.437C741.261 293.091 740.302 293.491 739.957 294.329L739.191 296.188C738.846 297.026 739.246 297.985 740.084 298.33Z"
                fill="#E04713"
              />
              <path
                d="M751.801 302.97C751.395 302.159 750.41 301.831 749.6 302.236L747.85 303.111C747.039 303.516 746.711 304.502 747.116 305.312C747.52 306.121 748.505 306.452 749.317 306.046L751.067 305.171C751.877 304.766 752.206 303.78 751.801 302.97Z"
                fill="#E04713"
              />
              <path
                d="M715.732 329.096L700.883 330.333C699.494 330.449 698.873 332.142 699.859 333.128L702.418 335.686L697.76 340.393C697.126 341.035 697.128 342.069 697.767 342.707L703.892 348.832C704.529 349.47 705.563 349.474 706.206 348.838L710.912 344.181L713.471 346.74C714.456 347.725 716.15 347.106 716.266 345.716L717.503 330.867C717.587 329.858 716.745 329.012 715.732 329.096Z"
                fill="#E04713"
              />
              <path
                d="M724.849 306.328C716.586 306.328 709.864 313.05 709.864 321.312C709.864 323.032 710.155 324.683 710.691 326.222C715.807 325.796 715.585 325.808 715.869 325.808C718.774 325.808 721.01 328.297 720.773 331.139L720.4 335.622C729.994 338.616 739.833 331.418 739.833 321.312C739.833 313.05 733.111 306.328 724.849 306.328ZM729.558 324.578C729.259 326.353 728.03 327.764 726.416 328.348V328.758C726.416 329.69 725.638 330.441 724.696 330.397C723.814 330.355 723.135 329.595 723.135 328.712V328.533C722.395 328.404 721.718 328.13 720.872 327.584C720.118 327.097 719.845 326.098 720.307 325.329C720.79 324.528 721.836 324.294 722.612 324.801C723.412 325.324 723.669 325.365 724.764 325.357C725.819 325.35 726.24 324.522 726.322 324.034C726.396 323.595 726.313 323.012 725.5 322.725C724.138 322.243 722.741 321.691 721.751 320.915C719.498 319.147 720.178 315.111 723.135 314.037V313.867C723.135 312.934 723.913 312.184 724.855 312.228C725.692 312.267 726.416 312.972 726.416 313.943C727.165 314.125 727.742 314.398 728.325 314.756C729.12 315.244 729.353 316.299 728.823 317.079C728.357 317.763 727.389 318.031 726.52 317.498C726.073 317.224 725.277 316.939 724.532 317.083C724.137 317.158 723.79 317.417 723.713 317.827C723.66 318.11 723.75 318.289 723.776 318.333C724.02 318.736 725.548 319.262 726.593 319.631C728.737 320.389 729.928 322.377 729.558 324.578Z"
                fill="#E04713"
              />
            </g>
            <path
              d="M285.129 13.8125H558.129C618.328 13.8125 667.129 62.6135 667.129 122.812C667.129 183.012 618.328 231.812 558.129 231.812H272.129C211.93 231.812 163.129 280.613 163.129 340.812C163.129 401.012 211.93 449.812 272.129 449.812H558.129C618.328 449.812 667.129 498.613 667.129 558.812C667.129 619.012 618.328 667.812 558.129 667.812H272.129C211.93 667.812 163.129 716.613 163.129 776.812C163.129 837.012 211.93 885.812 272.129 885.812H558.129C618.328 885.812 667.129 934.613 667.129 994.812C667.129 1055.01 618.328 1103.81 558.129 1103.81H272.129C211.93 1103.81 163.129 1152.61 163.129 1212.81C163.129 1273.01 211.93 1321.81 272.129 1321.81C383.819 1322.35 446.439 1321.86 558.129 1321.81C618.328 1321.81 664.433 1370.61 664.433 1430.81C664.433 1491.01 615.632 1539.81 555.433 1539.81H269.433"
              id="filling_path"
              stroke="url(#paint11_linear_110_350)"
              stroke-width="20"
              stroke-linecap="round"
            />
            <defs>
              <filter
                id="filter0_i_110_350"
                x="0.128906"
                y="0.8125"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_110_350"
                />
              </filter>
              <filter
                id="filter1_i_110_350"
                x="0.128906"
                y="437.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_110_350"
                />
              </filter>
              <filter
                id="filter2_i_110_350"
                x="0.128906"
                y="871.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_110_350"
                />
              </filter>
              <filter
                id="filter3_i_110_350"
                x="0.128906"
                y="1306.81"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_110_350"
                />
              </filter>
              <filter
                id="filter4_i_110_350"
                x="186.129"
                y="654.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_110_350"
                />
              </filter>
              <filter
                id="filter5_i_110_350"
                x="186.129"
                y="1088.81"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_110_350"
                />
              </filter>
              <filter
                id="filter6_i_110_350"
                x="188.129"
                y="217.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_110_350"
                />
              </filter>
              <linearGradient
                id="paint0_linear_110_350"
                x1="103.629"
                y1="21.8125"
                x2="103.629"
                y2="186.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#1B91DA" />
                <stop offset="1" stop-color="#0E4D74" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_110_350"
                x1="103.629"
                y1="458.812"
                x2="103.629"
                y2="623.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#0065F4" />
                <stop offset="1" stop-color="#0F4798" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_110_350"
                x1="665.536"
                y1="871.812"
                x2="9.05439"
                y2="871.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#21D342" />
                <stop offset="1" stop-color="#258136" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_110_350"
                x1="103.629"
                y1="892.812"
                x2="103.629"
                y2="1057.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#21D342" />
                <stop offset="1" stop-color="#258136" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_110_350"
                x1="665.536"
                y1="1306.81"
                x2="9.05439"
                y2="1306.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#21D3C8" />
                <stop offset="1" stop-color="#25817C" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_110_350"
                x1="103.629"
                y1="1327.81"
                x2="103.629"
                y2="1492.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#21D3C8" />
                <stop offset="1" stop-color="#25817C" />
              </linearGradient>
              <radialGradient
                id="paint6_radial_110_350"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(506.629 758.312) rotate(90) scale(103.5 320.5)"
              >
                <stop stop-color="#FFE81E" />
                <stop offset="1" stop-color="#C4B214" />
              </radialGradient>
              <linearGradient
                id="paint7_linear_110_350"
                x1="82.5"
                y1="0"
                x2="82.5"
                y2="165"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFE81E" />
                <stop offset="1" stop-color="#C4B214" />
              </linearGradient>
              <linearGradient
                id="paint8_linear_110_350"
                x1="506.629"
                y1="1088.81"
                x2="506.629"
                y2="1295.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#1EFFA8" />
                <stop offset="1" stop-color="#14C480" />
              </linearGradient>
              <linearGradient
                id="paint9_linear_110_350"
                x1="82.5"
                y1="0"
                x2="82.5"
                y2="165"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#1EFFA8" />
                <stop offset="1" stop-color="#14C480" />
              </linearGradient>
              <linearGradient
                id="paint10_linear_110_350"
                x1="724.629"
                y1="238.812"
                x2="724.629"
                y2="403.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FC490B" />
                <stop offset="1" stop-color="#C7471A" />
              </linearGradient>
              <linearGradient
                id="paint11_linear_110_350"
                x1="412.434"
                y1="2193.81"
                x2="412.434"
                y2="3065.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#09C2C1" />
                <stop offset="1" stop-color="#1D85D8" />
              </linearGradient>
              <clipPath id="clip0_110_350">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.4551 76.625)"
                />
              </clipPath>
              <clipPath id="clip1_110_350">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.9443 513.625)"
                />
              </clipPath>
              <clipPath id="clip2_110_350">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.6943 947.625)"
                />
              </clipPath>
              <clipPath id="clip3_110_350">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.6289 1382.31)"
                />
              </clipPath>
              <clipPath id="clip4_110_350">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(695.944 730.625)"
                />
              </clipPath>
              <clipPath id="clip5_110_350">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(695.63 1164.31)"
                />
              </clipPath>
              <clipPath id="clip6_110_350">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(696.629 293.312)"
                />
              </clipPath>
            </defs>
          </svg>

          <div className="content_detail">
            <div className="col_detail  ">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/seoflow1.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Search Engine Optimization
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#004875;" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Improve your rankings with on-page, off-page, and technical SEO
                strategies.
              </p>
            </div>
            <div className="col_detail  ">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/seoflow2.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Pay-Per-Click Advertising (PPC)
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#C7471A;" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Drive targeted traffic with optimized ad campaigns on Google and
                social media.
              </p>
            </div>
            <div className="col_detail  ">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/seoflow3.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Social Media Marketing
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#0F4798;" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Build your brand and engage audiences across platforms like
                Instagram, LinkedIn, and Facebook.
              </p>
            </div>
            <div className="col_detail  ">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/seoflow4.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Content Marketing
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#004875;" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Attract, inform, and convert with high-quality blogs, videos,
                and more.
              </p>
            </div>
            <div className="col_detail  ">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/seoflow5.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Brand Messaging & Voice
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#C7471A;" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Define how your brand communicates to connect authentically with
                your audience.
              </p>
            </div>
            <div className="col_detail  ">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/seoflow6.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                Logo & Collateral Design
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#0F4798;" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Create a consistent visual identity for your website, social
                media, and marketing materials.
              </p>
            </div>
            <div className="col_detail  ">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/seoflow7.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
                App Store /Play Store Optimization (ASO)
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#004875;" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
                Optimize your app listings to drive downloads and engagement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SEOFlow;
