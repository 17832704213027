import { useEffect, useState } from "react";
import { getBlogDetail, getBlogs } from "../services/api/api";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

const CustomAppServices = () => {
  const settings = {
    slidesToShow: 3.5,
    slidesToScroll: 1,
    draggable: true,
    arrows: false,
    className: "blog_list",
    focusOnSelect: true,
    adaptiveHeight: false,
    prevArrow: (
      <div className="slickbtn slick-prev">
        <svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="25.5"
            cy="25.5"
            r="25.5"
            transform="rotate(180 25.5 25.5)"
            fill="url(#paint0_linear_17_4)"
          ></circle>
          <path
            d="M21.414 26L31 26L31 24L21.414 24L25.707 19.707L24.293 18.293L17.586 25L24.293 31.707L25.707 30.293L21.414 26Z"
            fill="white"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_17_4"
              x1="-0.721698"
              y1="21.675"
              x2="51.0345"
              y2="22.22"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#09C2C1"></stop>
              <stop offset="1" stop-color="#1D85D8"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    nextArrow: (
      <div className="slickbtn slick-next">
        <svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="25.5"
            cy="25.5"
            r="25.5"
            fill="url(#paint0_linear_17_8)"
          ></circle>
          <path
            d="M29.586 25L20 25L20 27L29.586 27L25.293 31.293L26.707 32.707L33.414 26L26.707 19.293L25.293 20.707L29.586 25Z"
            fill="white"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_17_8"
              x1="-0.721698"
              y1="21.675"
              x2="51.0345"
              y2="22.22"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#09C2C1"></stop>
              <stop offset="1" stop-color="#1D85D8"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const [blog, setBlog] = useState([]);
  let { blogId } = useParams();
  useEffect(() => {
    getBlogDetail(blogId).then((res) => {
      setBlog(res.data.result);
    });
  }, []);
  console.log(blog);
  return (
    <>
      <div
        className="blog_banner blog-dtl"
        style={{ backgroundImage: "url(./assets/images/new/blog-dtl.png)" }}
      >
        <div className="wrapper">
          <div className="blog-dtl-block">
            <a href="/blogs">
              <img src="/assets/images/new/arrow-blog.svg" alt="arrow" />
            </a>
            {/* this is banner is dynamic */}
            <img src="/assets/images/blogs/blog16.jpg" alt="blog" />
          </div>
        </div>
      </div>
      <div className="block-dtl">
        <div className="wrapper">
        <div className="social">
            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcodeflixweb.com%2Fcustom-mobile-app-development-services">
              <img
                src="/assets/images/site/social/Facebook.svg"
                alt="facebook"
              />
            </a>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fcodeflixweb.com%2Fcustom-mobile-app-development-services">
              <img
                src="/assets/images/site/social/Linkedin.svg"
                alt="facebook"
              />
            </a>
            <a href="https://pinterest.com/pin/create/button/?url=https%3A%2F%2Fcodeflixweb.com%2Fcustom-mobile-app-development-services">
              <img src="/assets/images/site/social/Social.svg" alt="facebook" />
            </a>
            <a href="https://x.com/intent/tweet?url=https%3A%2F%2Fcodeflixweb.com%2Fcustom-mobile-app-development-services">
              <img
                src="/assets/images/site/social/twitter.png"
                alt="facebook"
              />
            </a>
            <a href="https://www.instagram.com/?url=https%3A%2F%2Fcodeflixweb.com%2Fcustom-mobile-app-development-services">
              <img
                src="/assets/images/site/social/Instagram.svg"
                alt="facebook"
              />
            </a>
          </div>
          <div className="title">
            <div className="title-head">
              <a
                data-aos="zoom-out-up"
                href="javascript:void(0)"
                className="button-container-1 aos-init aos-animate"
              >
                <span className="mas">
                  App Development
                  {/* this is tag name (dynamic) */}
                </span>
                <span className="mask_btn">
                App Development
                  {/* this is tag name (dynamic) */}
                </span>
              </a>

              <span>October 10, 2024</span>
            </div>
            {/* all this content is dynamic  */}
            <div
            // dangerouslySetInnerHTML={{ __html: blog.page_content }}
            >
              {" "}
              <h2>
                Elevate Your Business with Custom Mobile App Development
                Services
              </h2>
              <p>
                {" "}
                integral to business growth and customer engagement. From
                enhancing user experiences to driving revenue, custom mobile app
                development has become a cornerstone of successful digital
                strategies. Let’s explore how mobile app development can
                transform your business and why partnering with the best mobile
                app development company is essential.
              </p>
              <br />
              <p>
                <b>Why Invest in Mobile App Development?</b>
              </p>
              <p>
                {" "}
                <b>1. Increased Customer Engagement</b> <br />
                Mobile apps provide a direct channel for businesses to interact
                with their customers. Features like push notifications, in-app
                messages, and personalized recommendations keep users engaged
                and informed.
              </p>
              <p>
                {" "}
                <b>2. Enhanced Brand Visibility</b>
                <br />A well-designed mobile app increases your brand’s presence
                on user devices, ensuring they are constantly reminded of your
                products or services.
              </p>
              <p>
                {" "}
                <b>3. Improved Operational Efficiency</b>
                <br />
                Custom mobile apps streamline workflows, improve communication,
                and enhance productivity by automating processes and integrating
                with existing systems.
              </p>
              <p>
                {" "}
                <b>4. Higher Revenue Generation</b>
                <br />
                Mobile apps offer multiple monetization opportunities, including
                in-app purchases, subscriptions, and ad revenues. They also
                boost e-commerce conversions with intuitive designs and seamless
                payment options.
              </p>
              <br />
              <p>
                <b>Types of Mobile App Development Services</b>
              </p>
              <br />
              <p>
                <b>1. Native Mobile App Development </b>
                <br />
                Native apps are built specifically for platforms like Android
                and iOS using their respective programming languages (Kotlin,
                Swift). These apps deliver exceptional performance and leverage
                platform-specific features.
              </p>
              <p>
                <b>2. Cross-Platform App Development</b>
                <br />
                Using frameworks like Flutter and React Native, cross-platform
                apps are developed to run seamlessly on both Android and iOS,
                reducing time-to-market and development costs.
              </p>
              <p>
                <b>3. Progressive Web Apps (PWAs)</b>
                <br />
                PWAs combine the best features of web and mobile apps. They are
                lightweight, fast, and accessible via web browsers while
                offering an app-like experience.
              </p>
              <p>
                <b>4. Enterprise Mobile Apps </b>
                <br />
                Tailored to meet the specific needs of organizations, enterprise
                apps improve internal operations, facilitate communication, and
                drive efficiency.
              </p>
              <p>
                <b>Mobile App Development Technologies</b>
              </p>
              <br />
              <ul>
                <li>
                  <b>Android Development: </b>Using Kotlin, Java, and Android
                  Studio to create powerful apps for the Android ecosystem.
                </li>
                <li>
                  <b>iOS Development:</b> Leveraging Swift and Objective-C to
                  deliver premium apps for Apple devices.
                </li>
                <li>
                  <b>Flutter Development: </b>Building high-performance
                  cross-platform apps with a single codebase.
                </li>
                <li>
                  <b>React Native:</b> Creating cost-effective apps with
                  near-native performance for Android and iOS.
                </li>
                <li>
                  <b>Cloud Integration:</b> Incorporating AWS, Firebase, and
                  Azure to enhance app functionality and scalability.
                </li>
              </ul>
              <br />
              <p>
                <b>How Custom Mobile App Development Benefits Your Business</b>
              </p>
              <ul>
                <li>
                  <b>Personalized User Experiences: </b>Tailor apps to suit
                  specific user needs, enhancing satisfaction and loyalty.
                </li>
                <li>
                  <b>Scalability: </b>Custom apps grow with your business,
                  accommodating increasing user demands.
                </li>
                <li>
                  <b>Integration with Existing Systems:</b> Seamlessly connect
                  with CRMs, ERPs, and other enterprise tools.
                </li>
                <li>
                  <b> Data Security:</b> Advanced security measures protect
                  sensitive business and user data.
                </li>
              </ul>
              <br />
              <p>
                <b>Why Choose Us for Mobile App Development? </b>
              </p>
              <p>
                As a top mobile app development company, we offer end-to-end
                solutions tailored to your business goals. Our expertise spans:
              </p>
              <br />
              <ul>
                <li>Native and cross-platform app development</li>
                <li>Cutting-edge UI/UX design</li>
                <li>Agile development methodologies</li>
                <li>Post-launch support and maintenance</li>
                <li>
                  Whether you are searching for a mobile app development company
                  in the US, mobile app development company UK, or a web and
                  mobile app development company near you, we deliver tailored,
                  high-quality applications that drive results.
                </li>
              </ul>
              <br />
              <p>
                <b>Global Reach in Mobile App Development</b>
              </p>
              <br />
              <p>We proudly serve businesses across regions:</p>
              <ul>
                <li>
                  Mobile App Development Company in the USA: Delivering
                  scalable, innovative, and high-performing mobile applications
                  for businesses of all sizes in the United States.
                </li>
                <li>
                  Top Mobile App Development Companies in the UK: Offering
                  exceptional app solutions to businesses in the competitive UK
                  market.
                </li>
                <li>
                  Mobile App Development Company in Europe: Crafting customized,
                  feature-rich apps to meet the diverse needs of European
                  businesses.
                </li>
                <li>
                  Mobile App Development Company in India: Affordable,
                  quality-driven mobile app services tailored for SMEs and
                  enterprises in India.
                </li>
              </ul>
              <br />
              <p>
                <b>Final Thoughts</b>
              </p>
              <br />
              <p>
                In today’s competitive digital landscape, investing in custom
                mobile app development is crucial for businesses looking to
                enhance customer engagement, improve efficiency, and drive
                revenue. Partner with the best mobile app development company to
                bring your vision to life.
              </p>
              <p>
                We are recognized as one of the top mobile app development
                companies globally, offering tailored solutions for clients in
                the US, UK, Europe, and India. Whether you need a mobile app
                development company near me or the expertise of a web and mobile
                app development company, we are here to set your business apart
                from the competition.
              </p>
              <p>
                Let us help you elevate your business with innovative, secure,
                and high-performing mobile app development services—contact us
                today!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="topics-sec">
        <div className="wrapper">
          <div className="topics-head">
            <h3>related topics</h3>
            <a
              data-aos="zoom-out-up"
              href="/blogs"
              className="button-container-1 aos-init aos-animate"
            >
              <span className="mas">View More</span>
              <span className="mask_btn">View More</span>
            </a>
          </div>
          <div className="topics-block">
            <Slider {...settings}>
              {/* href id is dynamic like blog/item.id */}
              <a href={`/best-web-development-company`}>
                <div className="topics-slider">
                  <div className="element">
                    {/* this image is blog banner (logo.url) */}
                    <img src="./assets/images/blogs/blog1.jpg" alt="blogs" />
                    <div className="slide-dtls">
                      {/* {item.tags.map((tag, index) => {
                                  return <span>{tag.name}</span>;
                                })} */}
                      <span> Web Development</span>
                      {/* call page content h2 tag text */}
                      <div
                      // dangerouslySetInnerHTML={{
                      //   __html: page_content[0],
                      // }}
                      >
                        Best Web Development Company: Transforming Your Digital
                        Presence Globally
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <a href={`/custom-mobile-app-development-services`}>
                <div className="topics-slider">
                  <div className="element">
                    {/* this image is blog banner (logo.url) */}
                    <img src="./assets/images/blogs/blog16.jpg" alt="blogs" />
                    <div className="slide-dtls">
                      {/* {item.tags.map((tag, index) => {
                                  return <span>{tag.name}</span>;
                                })} */}
                      <span>App Development</span>
                      {/* call page content h2 tag text */}
                      <div
                      // dangerouslySetInnerHTML={{
                      //   __html: page_content[0],
                      // }}
                      >
                        Elevate Your Business with Custom Mobile App Development
                        Services
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <a href={`/leading-php-company`}>
                <div className="topics-slider">
                  <div className="element">
                    {/* this image is blog banner (logo.url) */}
                    <img src="./assets/images/blogs/blog15.jpg" alt="blogs" />
                    <div className="slide-dtls">
                      {/* {item.tags.map((tag, index) => {
                                  return <span>{tag.name}</span>;
                                })} */}
                      <span> Web Development</span>
                      {/* call page content h2 tag text */}
                      <div
                      // dangerouslySetInnerHTML={{
                      //   __html: page_content[0],
                      // }}
                      >
                        Leading PHP Development Company in India: Unlocking Your
                        Digital Potential
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <a href={`/best-UI-UX-design`}>
                <div className="topics-slider">
                  <div className="element">
                    {/* this image is blog banner (logo.url) */}
                    <img src="/assets/images/blogs/blog9.jpg" alt="blogs" />
                    <div className="slide-dtls">
                      {/* {item.tags.map((tag, index) => {
                                  return <span>{tag.name}</span>;
                                })} */}
                      <span>ui & ux</span>
                      {/* call page content h2 tag text */}
                      <div
                      // dangerouslySetInnerHTML={{
                      //   __html: page_content[0],
                      // }}
                      >
                        We Provide the Best UI/UX Design Solutions for
                        Exceptional User Experiences{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomAppServices;
