import React from "react";

const UIUXDevBanner = () => {
  return (
    <div
      class="inner_banner service-banner"
      style={{ backgroundImage: "url(/assets/images/site/uiux-banner.png)" }}
    >
      <div class="wrapper">
        <div class="banner_detail_1">
          <div class="details-ser">
            <h1 id="heading_line">UI / UX</h1>
            <h2 id="sub_heading1">
            A great design is about more than aesthetics it’s about creating meaningful interactions while showcasing your unique brand identity. At Codeflix Web LLC, we combine UI/UX design expertise with branding strategies to ensure your digital products captivate users and reinforce your business identity.

            </h2>
          </div>
          <div class="arrows">
            <img src="/assets/images/new/arrows-ser.svg" alt="arrows" />
          </div>
          <div class="slider-block">
            <img src="/assets/images/new/sercive_banner.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UIUXDevBanner;
