import { useEffect, useState } from "react";
import { getBlogDetail, getBlogs } from "../services/api/api";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

const BestwebCompany = () => {
  const settings = {
    slidesToShow: 3.5,
    slidesToScroll: 1,
    draggable: true,
    arrows: false,
    className: "blog_list",
    focusOnSelect: true,
    adaptiveHeight: false,
    prevArrow: (
      <div className="slickbtn slick-prev">
        <svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="25.5"
            cy="25.5"
            r="25.5"
            transform="rotate(180 25.5 25.5)"
            fill="url(#paint0_linear_17_4)"
          ></circle>
          <path
            d="M21.414 26L31 26L31 24L21.414 24L25.707 19.707L24.293 18.293L17.586 25L24.293 31.707L25.707 30.293L21.414 26Z"
            fill="white"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_17_4"
              x1="-0.721698"
              y1="21.675"
              x2="51.0345"
              y2="22.22"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#09C2C1"></stop>
              <stop offset="1" stop-color="#1D85D8"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    nextArrow: (
      <div className="slickbtn slick-next">
        <svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="25.5"
            cy="25.5"
            r="25.5"
            fill="url(#paint0_linear_17_8)"
          ></circle>
          <path
            d="M29.586 25L20 25L20 27L29.586 27L25.293 31.293L26.707 32.707L33.414 26L26.707 19.293L25.293 20.707L29.586 25Z"
            fill="white"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_17_8"
              x1="-0.721698"
              y1="21.675"
              x2="51.0345"
              y2="22.22"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#09C2C1"></stop>
              <stop offset="1" stop-color="#1D85D8"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const [blog, setBlog] = useState([]);
  let { blogId } = useParams();
  useEffect(() => {
    getBlogDetail(blogId).then((res) => {
      setBlog(res.data.result);
    });
  }, []);
  console.log(blog);
  return (
    <>
      <div
        className="blog_banner blog-dtl"
        style={{ backgroundImage: "url(./assets/images/new/blog-dtl.png)" }}
      >
        <div className="wrapper">
          <div className="blog-dtl-block">
            <a href="/blogs">
              <img src="/assets/images/new/arrow-blog.svg" alt="arrow" />
            </a>
            {/* this is banner is dynamic */}
            <img src="/assets/images/blogs/blog1.jpg" alt="blog" />
          </div>
        </div>
      </div>
      <div className="block-dtl">
        <div className="wrapper">
          <div className="social">
            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcodeflixweb.com%2Fbest-web-development-company">
              <img src="/assets/images/site/social/Facebook.svg" alt="facebook" />
            </a>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fcodeflixweb.com%2Fbest-web-development-company">
              <img src="/assets/images/site/social/Linkedin.svg" alt="facebook" />
            </a>
            <a href="https://pinterest.com/pin/create/button/?url=https%3A%2F%2Fcodeflixweb.com%2Fbest-web-development-company">
              <img src="/assets/images/site/social/Social.svg" alt="facebook" />
            </a>
            <a href="https://x.com/intent/tweet?url=https%3A%2F%2Fcodeflixweb.com%2Fbest-web-development-company">
              <img src="/assets/images/site/social/twitter.png" alt="facebook" />
            </a>
            <a href="https://www.instagram.com/?url=https%3A%2F%2Fcodeflixweb.com%2Fbest-web-development-company">
              <img src="/assets/images/site/social/Instagram.svg" alt="facebook" />
            </a>
          </div>
          <div className="title">
            <div className="title-head">
              <a
                data-aos="zoom-out-up"
                href="javascript:void(0)"
                className="button-container-1 aos-init aos-animate"
              >
                <span className="mas">
                  Web Development
                  {/* this is tag name (dynamic) */}
                </span>
                <span className="mask_btn">
                  Web Development
                  {/* this is tag name (dynamic) */}
                </span>
              </a>

              <span>July 29, 2024</span>
            </div>
            {/* all this content is dynamic  */}
            <div
            // dangerouslySetInnerHTML={{ __html: blog.page_content }}
            >
              {" "}
              <h2>
                Best Web Development Company: Transforming Your Digital Presence
                Globally
              </h2>
              <p>
                In today’s digital-first world, having a strong and functional
                online presence is crucial for businesses of all sizes. Whether
                you’re a small startup or an established enterprise, your
                website serves as the foundation for engaging customers,
                building brand recognition, and driving sales. That’s where our
                expert <b>web development services</b> come in.
              </p>
              <p>
                At [<b>CodeflixWeb</b>], we are recognized as one of the{" "}
                <b>best web development companies</b> with a proven track record
                of delivering custom solutions. We specialize in{" "}
                <b>web design & development services</b>,{" "}
                <b>
                  web app development services, and ecommerce web development
                  services
                </b>{" "}
                tailored to your business needs. Whether you're looking for a
                new website or an advanced custom{" "}
                <b>web app development service</b>, our experienced team can
                make your vision a reality.
              </p>
              <br />
              <p>
                <b>Why Choose Us as Your Web Development Company?</b>
              </p>
              <br />
              <p>
                When it comes to web development services, you need more than
                just a website. You need a comprehensive solution that blends
                the latest technologies with a focus on user experience,
                scalability, and performance. Here’s why choosing our{" "}
                <b>web development services company</b> is the right decision:
              </p>
              <br />
              <ol>
                <li>
                  <b>Custom Web Development</b>
                  <br /> Every business is unique, and your website should
                  reflect that. As a leading web development company near me, we
                  specialize in custom web app development services that are
                  specifically tailored to your business requirements. Our
                  expert team works closely with you to understand your goals,
                  target audience, and branding, ensuring a personalized,
                  effective solution that drives results.
                </li>
                <li>
                  <b>Responsive and Mobile-Friendly Design</b>
                  <br /> With mobile traffic now accounting for more than half
                  of all internet usage, having a responsive and mobile-friendly
                  website is essential. We ensure that every website we create,
                  whether for *web design & development services* or *ecommerce
                  web development services*, is optimized for all devices,
                  ensuring a seamless experience for your customers across
                  smartphones, tablets, and desktops.
                </li>
                <li>
                  <b>SEO-Friendly Websites</b> <br /> A visually stunning
                  website won’t matter if it's not visible to your target
                  audience. We integrate SEO best practices into every website
                  we develop, from the code to the content, ensuring your site
                  is search-engine friendly. This helps you rank higher in
                  search engine results and increases organic traffic, making us
                  one of the top <b>web development services in the USA</b>.
                </li>
                <li>
                  <b>E-Commerce Solutions</b> <br /> If you're looking to build
                  or improve your online store, we provide top-tier{" "}
                  <b>ecommerce web development services</b> that are secure,
                  scalable, and easy to manage. Our team helps you create an
                  efficient e-commerce platform that enhances the user shopping
                  experience and drives conversions, setting you apart as a
                  leading web development service USA
                </li>
                <li>
                  <b>Scalable Solutions</b>
                  <br /> As your business grows, so should your website. Our web
                  development services are designed with scalability in mind. We
                  ensure that your platform is built to handle increased traffic
                  and evolving business needs. Whether you need{" "}
                  <b>custom web app development services</b> or more advanced
                  features, our web development services are adaptable to your
                  changing requirements.
                </li>
                <li>
                  <b>Post-Launch Support</b> The launch of your website is just
                  the beginning. We offer continuous support, ensuring your site
                  remains up-to-date, secure, and optimized for the best user
                  experience. Our team provides ongoing maintenance and updates,
                  making us your trusted partner for the long-term success of
                  your website.
                </li>
              </ol>
              <br />
              <p>
                <b>Our Web Development Process</b>
              </p>
              <br />
              <p>
                We follow a structured, yet flexible process to ensure the final
                product meets your needs. Here’s how we approach each project:
              </p>
              <br />
              <ol>
                <li>
                  <b>Discovery & Planning</b> <br /> We begin by understanding
                  your business objectives and goals, ensuring we can create a
                  tailored plan that delivers results.
                </li>
                <li>
                  <b>Design & Prototyping</b>
                  <br /> We create high-quality design mockups and prototypes to
                  give you a clear idea of your website's look and feel,
                  ensuring it meets your expectations before development starts.{" "}
                </li>
                <li>
                  <b>Development</b> <br /> Our skilled developers bring the
                  design to life, using the latest technologies to ensure a
                  high-performing, secure website. From{" "}
                  <b>custom web app development services</b> to simple content
                  management systems, we’ve got you covered.{" "}
                </li>
                <li>
                  <b>Testing & Launch</b> <br /> Once developed, we thoroughly
                  test your website for performance, functionality, and
                  compatibility. We ensure that your site works flawlessly on
                  all devices and browsers before it goes live.
                </li>
                <li>
                  <b>Post-Launch Support</b> <br /> After launching your site,
                  we provide ongoing maintenance and support to keep your
                  website secure and updated, ensuring it continues to grow with
                  your business.
                </li>
              </ol>
              <br />
              <p>
                <b>Technologies We Use</b>
              </p>
              <p>
                {" "}
                Our team utilizes a wide array of cutting-edge technologies to
                build high-performing, reliable websites:
              </p>
              <ul>
                <li>
                  <b>HTML5, CSS3, JavaScript</b> for front-end development
                </li>
                <li>
                  <b>React, Angular, Vue.js</b> for dynamic user interfaces
                </li>
                <li>
                  <b>PHP, Python, Ruby, Node.js</b> for back-end development
                </li>
                <li>
                  <b>WordPress, Magento, Shopify, WooCommerce</b> for e-commerce
                  platforms
                </li>
                <li>
                  {" "}
                  <b>MySQL, MongoDB, PostgreSQL</b> for robust database
                  solutions
                </li>
                <li>
                  <b> Git, Docker, AWS </b> for version control, deployment, and
                  cloud hosting
                </li>
              </ul>
              <br />
              <p>
                <b>Serving Clients Globally: Top Web Development Companies</b>
              </p>
              <p>
                As one of the <b>top web development companies in India </b>and{" "}
                <b>top web development companies in the USA</b>, we have helped
                businesses around the world build innovative web solutions.
                Whether you’re looking for{" "}
                <b>web design and development services</b> in India or the USA,
                our global presence ensures we provide high-quality web
                development services wherever you are.
              </p>
              <p>
                Our reputation as one of the{" "}
                <b>top 10 web development companies</b> globally has been built
                on a foundation of trust, quality, and dedication. We continue
                to work with clients from diverse industries, delivering
                innovative solutions that drive business success.
              </p>
              <br />
              <p>
                <b>Ready to Start Your Web Development Journey?</b>
              </p>
              <br />
              <p>
                If you're looking for a <b>web development services company</b>{" "}
                that can transform your digital presence, look no further.
                Whether you're interested in{" "}
                <b>custom web app development services</b>,{" "}
                <b>web design & development services</b>, or{" "}
                <b>ecommerce web development services</b>, we have the expertise
                to deliver.
              </p>
              <p>
                <b>Get in touch with us today</b>, and let’s start building the
                future of your business with a powerful, customized website that
                meets all your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="topics-sec">
        <div className="wrapper">
          <div className="topics-head">
            <h3>related topics</h3>
            <a
              data-aos="zoom-out-up"
              href="/blogs"
              className="button-container-1 aos-init aos-animate"
            >
              <span className="mas">View More</span>
              <span className="mask_btn">View More</span>
            </a>
          </div>
          <div className="topics-block">
            <Slider {...settings}>
              {/* href id is dynamic like blog/item.id */}
              <a href={`/best-web-development-company`}>
                <div className="topics-slider">
                  <div className="element">
                    {/* this image is blog banner (logo.url) */}
                    <img src="./assets/images/blogs/blog1.jpg" alt="blogs" />
                    <div className="slide-dtls">
                      {/* {item.tags.map((tag, index) => {
                                  return <span>{tag.name}</span>;
                                })} */}
                      <span> Web Development</span>
                      {/* call page content h2 tag text */}
                      <div
                      // dangerouslySetInnerHTML={{
                      //   __html: page_content[0],
                      // }}
                      >
                        Best Web Development Company: Transforming Your Digital
                        Presence Globally
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <a href={`/custom-mobile-app-development-services`}>
                <div className="topics-slider">
                  <div className="element">
                    {/* this image is blog banner (logo.url) */}
                    <img src="./assets/images/blogs/blog16.jpg" alt="blogs" />
                    <div className="slide-dtls">
                      {/* {item.tags.map((tag, index) => {
                                  return <span>{tag.name}</span>;
                                })} */}
                      <span>App Development</span>
                      {/* call page content h2 tag text */}
                      <div
                      // dangerouslySetInnerHTML={{
                      //   __html: page_content[0],
                      // }}
                      >
                        Elevate Your Business with Custom Mobile App Development
                        Services
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <a href={`/leading-php-company`}>
                <div className="topics-slider">
                  <div className="element">
                    {/* this image is blog banner (logo.url) */}
                    <img src="./assets/images/blogs/blog15.jpg" alt="blogs" />
                    <div className="slide-dtls">
                      {/* {item.tags.map((tag, index) => {
                                  return <span>{tag.name}</span>;
                                })} */}
                      <span> Web Development</span>
                      {/* call page content h2 tag text */}
                      <div
                      // dangerouslySetInnerHTML={{
                      //   __html: page_content[0],
                      // }}
                      >
                        Leading PHP Development Company in India: Unlocking Your
                        Digital Potential
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <a href={`/best-UI-UX-design`}>
                <div className="topics-slider">
                  <div className="element">
                    {/* this image is blog banner (logo.url) */}
                    <img src="/assets/images/blogs/blog9.jpg" alt="blogs" />
                    <div className="slide-dtls">
                      {/* {item.tags.map((tag, index) => {
                                  return <span>{tag.name}</span>;
                                })} */}
                      <span>ui & ux</span>
                      {/* call page content h2 tag text */}
                      <div
                      // dangerouslySetInnerHTML={{
                      //   __html: page_content[0],
                      // }}
                      >
                        We Provide the Best UI/UX Design Solutions for
                        Exceptional User Experiences{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default BestwebCompany;
