import React, { useEffect, useState,Suspense  } from "react"; 
 
const Banner = React.lazy(() => import('../component/Banner'));
const HomeAbout = React.lazy(() => import("../component/HomeAbout"));
const Service = React.lazy(() => import( "../component/Service"));
const FeaturedWork = React.lazy(() => import( "../component/FeaturedWork"));
const Testimonial = React.lazy(() => import( "../component/Testimonial"));
const InnoSection = React.lazy(() => import( "../component/InnoSection"));
const Blog = React.lazy(() => import( "../component/Blog"));
const Trusted = React.lazy(() => import('../component/Trusted'));
// import Trusted from "../component/Trusted";

// import { getHome } from "../services/api/api";

const Home = () => {
  const [home, setHome] = useState([]);
  useEffect(() => {
    // getHome().then((res) => {
    //   setHome(res.data.result.data);
    // });
  }, []);
  return (
    <>
      <>
      <Suspense fallback={<div className="loading_screen"> <img src="./assets/images/site/Comp 1_1.gif" alt="" /></div>}>
        <Banner />
        <HomeAbout />
        <Service />
        <FeaturedWork />
        <Trusted />
        <Testimonial/>
        <InnoSection />
        <Blog />
        </Suspense>
      </>
    </>
  );
};

export default Home;
