import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const BlogSlider = ({ items }) => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    arrows: true,
    className: "blog_list",
    focusOnSelect: true,
    adaptiveHeight: false,
    prevArrow: (
      <div className="slickbtn slick-prev">
        <svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="25.5"
            cy="25.5"
            r="25.5"
            transform="rotate(180 25.5 25.5)"
            fill="url(#paint0_linear_17_4)"
          ></circle>
          <path
            d="M21.414 26L31 26L31 24L21.414 24L25.707 19.707L24.293 18.293L17.586 25L24.293 31.707L25.707 30.293L21.414 26Z"
            fill="white"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_17_4"
              x1="-0.721698"
              y1="21.675"
              x2="51.0345"
              y2="22.22"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#09C2C1"></stop>
              <stop offset="1" stop-color="#1D85D8"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    nextArrow: (
      <div className="slickbtn slick-next">
        <svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="25.5"
            cy="25.5"
            r="25.5"
            fill="url(#paint0_linear_17_8)"
          ></circle>
          <path
            d="M29.586 25L20 25L20 27L29.586 27L25.293 31.293L26.707 32.707L33.414 26L26.707 19.293L25.293 20.707L29.586 25Z"
            fill="white"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_17_8"
              x1="-0.721698"
              y1="21.675"
              x2="51.0345"
              y2="22.22"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#09C2C1"></stop>
              <stop offset="1" stop-color="#1D85D8"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const navigate = useNavigate();

  return (
    <div className="blog_slider">
      <div className="wrapper">
        <Slider {...settings}>
          { items.slice(0, 5).map((item, index) => {
            return (
              <a href={item.url}>
                <div className="blog_slide">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(${item.image})`,
                    }}
                  ></div>
                  <div className="detail">
                    <div className="title">
                      {item.tags.map((tag, index) => {
                        return (
                          <a
                            data-aos="zoom-out-up"
                            href="javascript:void(0)"
                            className="  button-container-1"
                          >
                            <span className="mas">
                              
                              {tag.name}
                            </span>
                            <span className="mask_btn">
                              
                              {tag.name}
                            </span>
                          </a>
                        );
                      })}

                      <div
                       // dangerouslySetInnerHTML={{ __html: item.page_content }}
                      >
                        <h2>{item.name}</h2>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
export default BlogSlider;
