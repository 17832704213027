import React, { useEffect } from 'react';
import ServiceBanner from '../component/ServiceBanner';
import $ from 'jquery'; // Import jQuery
import ServiceFlow from '../component/ServiceFlow';
import CustomNeed from '../component/CustomNeed';


const Service = () => {
    useEffect(() => {
        const win = window;

        function isElementVisible($el) {
            const viewTop = win.scrollY;
            const viewBottom = viewTop + window.innerHeight;
            const elTop = $el.offset().top;
            const elBottom = elTop + $el.height();
            return elBottom <= viewBottom && elTop >= viewTop;
        }

        function addAnimation($el, delay) {
            setTimeout(() => {
                $el.addClass('come-in');
                $el.attr('class', 'webflow_circle come-in');
            }, delay);
        }

        const allMods1 = Array.from(document.querySelectorAll('.webflow_circle'));
        const allMods2 = Array.from(document.querySelectorAll('.html_circle'));
        const allMods3 = Array.from(document.querySelectorAll('.css_circle'));
        const allMods4 = Array.from(document.querySelectorAll('.js_circle'));

        win.addEventListener('scroll', () => {
            allMods1.forEach((el, i) => {
                const $el = $(el);
                if (isElementVisible($el)) {
                    addAnimation($el, i * 200);
                }
            });

            allMods2.forEach((el, i) => {
                const $el = $(el);
                if (isElementVisible($el)) {
                    addAnimation($el, i * 200);
                }
            });

            allMods3.forEach((el, i) => {
                const $el = $(el);
                if (isElementVisible($el)) {
                    addAnimation($el, i * 200);
                }
            });

            allMods4.forEach((el, i) => {
                const $el = $(el);
                if (isElementVisible($el)) {
                    addAnimation($el, i * 200);
                }
            });
        });

        const handleScroll = () => {
            const offset = window.pageYOffset;
            const wheight = window.innerHeight;
            const html = document.documentElement;
            const docheight = Math.max(
              document.body.scrollHeight,
              document.body.offsetHeight,
              html.clientHeight,
              html.scrollHeight,
              html.offsetHeight
            );
            let progress = (offset / (docheight - wheight)) * 3000;
      
            // Give the line a CSS gradient based on scroll position
            progress = 3000 - progress * 1.6;
      
            // Update the SVG element with the new stroke-dashoffset
            const fillingPath = document.getElementById('filling_path');
            if (fillingPath) {
              fillingPath.setAttribute('stroke-dashoffset', progress);
            }
          };
      
          // Add scroll event listener when the component mounts
          window.addEventListener('scroll', handleScroll);
      
          // Remove the event listener when the component unmounts
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
    }, []);

    return (
        <>
           <ServiceBanner />
          <ServiceFlow /> 
            {/* <CustomNeed /> */}
            <div className="why_us">
        <div className="wrapper">
          <h2>Why Choose Us?</h2>
          <div className="col_row">
            <div className="col_item" style={{backgroundImage: `url(./assets/images/portfolio/usbg1.svg)`}}>
              <div className="icon">
                <img src="./assets/images/portfolio/us1.svg" alt="" />
              </div>
              <hr />
              <h3>Expert Developers</h3>
              <p>
                Our team consists of highly skilled and experienced web
                developers who stay ahead of the curve with the latest
                technologies.
              </p>
            </div>
            <div className="col_item" style={{backgroundImage: `url(./assets/images/portfolio/usbg2.svg)`}}>
              <div className="icon">
                <img src="./assets/images/portfolio/us2.svg" alt="" />
              </div>
              <hr />
              <h3>Fast and Secure</h3>
              <p>
              We deliver fast-loading, secure websites to ensure an optimal experience for your users and protect your data.
              </p>
            </div>
            <div className="col_item" style={{backgroundImage: `url(./assets/images/portfolio/usbg3.svg)`}}>
              <div className="icon">
                <img src="./assets/images/portfolio/us3.svg" alt="" />
              </div>
              <hr />
              <h3>Tailored Solutions</h3>
              <p>
              We understand your business goals and tailor every web development project to match your specific needs, ensuring the end result is exactly what you envision.
              </p>
            </div>
            <div className="col_item" style={{backgroundImage: `url(./assets/images/portfolio/usbg4.svg)`}}>
              <div className="icon">
                <img src="./assets/images/portfolio/us4.svg" alt="" />
              </div>
              <hr />
              <h3>Ongoing Support</h3>
              <p>
              Our job doesn’t end once your site is live. We provide continuous support and maintenance to ensure your website stays up-to-date and runs smoothly.
              </p>
            </div>
          </div>
        </div>
      </div>
        </>
    );
};

export default Service;