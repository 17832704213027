import React, { useState , useRef }  from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toast";
import { ThreeDots } from "react-loader-spinner";
import { contactUs } from "../services/api/api.js";
import emailjs from '@emailjs/browser'; 

const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#fff");
  const [error, setError] = useState(); 
  const navigate = useNavigate();

  const handleFormSubmit = () => {
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("email", email);
    formData.append("message", message);

    return contactUs(formData);
  }; 
  const handleSubmit = async (e) => {
    // Prevent the default submit and page reload
    e.preventDefault(); 
    
    // // Handle validations
    // setLoading(true);
    // await handleFormSubmit()
    //   .then((res) => {
    //     toast("Thank you for contacting us, we will get in thouch soon.", {
    //       backgroundColor: "#323131",
    //       color: "#ffffff",
    //     });
    //     setEmail("");
    //     setSubject("");
    //     setMessage("");
    //     setError("");
    //     setLoading(false);
    //   })
    //   .catch((e, res) => {  
    //     if (e.response.status == 422) {
    //       setError(e.response.data.result);
    //       console.log(e.response.data.result);
    //       console.log(res);
    //     }
    //     setLoading(false);
    //   });
  };
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true); 
    emailjs
      .sendForm('service_gk513cd', 'template_903dfnd', form.current, {
        publicKey: 'SFJCQjC1l21XCj7VA',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setEmail("");
            setSubject("");
               setMessage(""); 
               alert("SUCCESS!!!");
           setLoading(false);
        },
        (error) => {
          setError('FAILED...', error.text);
          setLoading(false);
        },
      );
  };

  return (
    <div className="contact_us">
      <div className="wrapper">
        <div className="contact_sec">
          <div className="left_col">
            <div className="title">
              <h3 data-aos="zoom-out-up" className="aos-init aos-animate">
                <span>Got a project?</span>Let’s Have a Chat
              </h3>
            </div>
            <div className="contact_form1">
              <form action="" method="post"  ref={form} onSubmit={sendEmail}>
                <input
                  type="email"
                  name="email"
                  data-aos="zoom-out-up"
                  placeholder="Email"
                  className="input_col aos-init aos-animate"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                required />
                <p>{error?.email}</p>
                <input
                 type="text"
                  name="subject"
                  placeholder="subject"
                  data-aos="zoom-out-up"
                  className="input_col aos-init aos-animate"
                  id="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                >
                  
                </input>

                <p>{error?.subject}</p>
                <textarea
                  data-aos="zoom-out-up"
                  name="message"
                  id="message"
                  cols="10"
                  rows="1"
                  placeholder="Message"
                  className="input_col aos-init aos-animate"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required ></textarea>

                <p>{error?.message}</p>
                {/* <button
                    data-aos="zoom-out-up"
                    type="submit"
                    className="submitBtn aos-init aos-animate"
                    value="Get In Touch"
                  /> */}
                <button
                  data-aos="zoom-out-up"
                  type="submit"
                  className="submitBtn aos-init aos-animate"
                  value=""
                  onMouseEnter={() => setColor("#000")}
                  onMouseLeave={() => setColor("#fff")}
                  disabled={loading}
                >
                  {loading ? (
                    <ThreeDots
                      height="16"
                      // width="80"
                      radius="9"
                      color={color}
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass="three-dots-loading"
                      visible={loading}
                    />
                  ) : (
                    "Get In Touch"
                  )}
                </button> 
              </form>
            </div>
          </div>
          <div className="right_col">
          <div id="map" >
            <img src="./assets/images/new/map_cf.jpg" alt="" />
            <a className="map_link map_link1" href="https://maps.app.goo.gl/5hStNMS1gx7MdSpV8" target="_blank"></a>
            <a className="map_link map_link2" href="https://maps.app.goo.gl/7jxhDH6N2i4mvYhcA" target="_blank"></a>
            <a className="map_link map_link3" href="https://maps.app.goo.gl/AdCzGQvjU1VWKKYS7" target="_blank"></a>
          </div>
            {/* <h4 data-aos="zoom-out-up" className="aos-init aos-animate">
              Contact Us
            </h4>
            <div className="contact_detail">
              <h5 data-aos="zoom-out-up" className="aos-init aos-animate">
                Office
              </h5>
              <p
                data-aos="zoom-out-up"
                className="address aos-init aos-animate"
              >
                407 Athena Avenue Bh- Jaguar showroom, Sarkhej - Gandhinagar
                Hwy, Gota, <br /> Ahmedabad, Gujarat <br /> 382481
              </p>
            </div>
            <div className="contact_detail">
              <h5 data-aos="zoom-out-up" className="aos-init aos-animate">
                Contact Info
              </h5>
              <p data-aos="zoom-out-up" className="tel aos-init aos-animate">
                Hardik Soni
              </p>
              <p data-aos="zoom-out-up" className="tel aos-init aos-animate">
                <a href="tel:+919998872716">+91 99988 72716</a>
              </p>
              <p data-aos="zoom-out-up" className="mailto aos-init aos-animate">
                <a href="mailto:info@codeflixweb.com">info@codeflixweb.com</a>
              </p>
            </div> */}
          </div>
          <div className="more-ctn">
            <h3>Contact Us</h3>
            <div className="more-block">
            <div className='more-card'>
                  <span>USA - Office</span>
                  <p>4833 Antrim Dr, Sarasota, FL 34240</p>
                  <p><a href="tel:+1(941)298-4092">+1 (941) 298-4092</a></p>
                  <p><a href="mailto:contact@codeflixweb.com">contact@codeflixweb.com</a></p>
                </div>
           
              <div className="more-card">
                <span>France - Office</span>
                <p>CODEFLIX SAS 1bis, Rue Stendhal, 75020 Paris, France</p>
                <p><a href="tel:+33750410834">+33 7 50 41 08 34</a></p>
                <p><a href="mailto:info@codeflixweb.com">info@codeflixweb.com</a></p>
              </div>
              <div className='more-card'>
                  <span>India - Office</span>
                  <p>407 Athena Avenue Bh- Jaguar showroom, SG Highway, Gota, Ahmedabad, Gujarat - 382481</p>
                  <p><a href="tel:+919998872716">+91 99988 72716</a></p>
                  <p><a href="mailto:info@codeflixweb.com">info@codeflixweb.com</a></p>
                </div>
                
              {/* <div className='more-card'>
                  <span>Kuwait - Office</span>
                  <p>2464 Royal Ln. Mesa, New Jersey 45463</p>
                </div>
                <div className='more-card'>
                  <span>Dubai - Office</span>
                  <p>2464 Royal Ln. Mesa, New Jersey 45463</p>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
