import React, { useEffect } from "react";
import ServiceBanner from "../component/WebDevBanner";
import $ from "jquery"; // Import jQuery
import CustomNeed from "../component/CustomNeed";
import CustomePhpServiceFlow from "../component/CustomePhpServiceFlow";

const CustomPhpDev = () => {
  useEffect(() => {
    const win = window;

    function isElementVisible($el) {
      const viewTop = win.scrollY;
      const viewBottom = viewTop + window.innerHeight;
      const elTop = $el.offset().top;
      const elBottom = elTop + $el.height();
      return elBottom <= viewBottom && elTop >= viewTop;
    }

    function addAnimation($el, delay) {
      setTimeout(() => {
        $el.addClass("come-in");
        $el.attr("class", "webflow_circle come-in");
      }, delay);
    }

    const allMods1 = Array.from(document.querySelectorAll(".webflow_circle"));
    const allMods2 = Array.from(document.querySelectorAll(".html_circle"));
    const allMods3 = Array.from(document.querySelectorAll(".css_circle"));
    const allMods4 = Array.from(document.querySelectorAll(".js_circle"));

    win.addEventListener("scroll", () => {
      allMods1.forEach((el, i) => {
        const $el = $(el);
        if (isElementVisible($el)) {
          addAnimation($el, i * 200);
        }
      });

      allMods2.forEach((el, i) => {
        const $el = $(el);
        if (isElementVisible($el)) {
          addAnimation($el, i * 200);
        }
      });

      allMods3.forEach((el, i) => {
        const $el = $(el);
        if (isElementVisible($el)) {
          addAnimation($el, i * 200);
        }
      });

      allMods4.forEach((el, i) => {
        const $el = $(el);
        if (isElementVisible($el)) {
          addAnimation($el, i * 200);
        }
      });
    });

    const handleScroll = () => {
      const offset = window.pageYOffset;
      const wheight = window.innerHeight;
      const html = document.documentElement;
      const docheight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      let progress = (offset / (docheight - wheight)) * 3000;

      // Give the line a CSS gradient based on scroll position
      progress = 3000 - progress * 1.6;

      // Update the SVG element with the new stroke-dashoffset
      const fillingPath = document.getElementById("filling_path");
      if (fillingPath) {
        fillingPath.setAttribute("stroke-dashoffset", progress);
      }
    };

    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className="inner_banner service-banner"
        style={{ backgroundImage: "url(/assets/images/site/phpbanner.png)" }}
      >
        <div className="wrapper">
          <div className="banner_detail_1">
            <div className="details-ser">
              <h1 id="heading_line">Dedicated Developers: Your Remote Tech Team, Your Rules</h1>
              <h2 id="sub_heading1">
              Expand your capabilities with our dedicated developers. At Codeflix Web, we provide skilled professionals who work exclusively for you, on your terms, whether monthly or hourly.
              </h2>
            </div>
            <div className="arrows">
              <img src="/assets/images/new/arrows-ser.svg" alt="arrows" />
            </div>
            <div class="slider-block">
              <img src="/assets/images/new/sercive_banner.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <CustomePhpServiceFlow />
{/* 
      <CustomNeed /> */}
    </>
  );
};

export default CustomPhpDev;
