import React from 'react'
import { motion } from 'framer-motion'
import LineImage from './LineImage'
import BulbImage from './BulbImage'


const Career = () => {

    const titleButton = {
        hidden: { opacity: 0 },
        show: {
            x: 20,
            opacity: 1,
            transition: {
                duration: 1,
                scale: 1.4,
                staggerChildren: 0.1,
                delayChildren: 0.3,
            },
        },
    }

    const textAnimation = {
        hidden: {
            opacity: 0,
            y: 50,
        },
        show: {
            opacity: 1,
            y: 0
        }
    }

    const headingAnimation = {
        hidden: {
            opacity: 0,
            // y: 50,
        },
        show: {
            opacity: 1,
            // y: 0
        }
    }

    const itemB = {
        hidden: { scale: 0, top: 200 },
        show: { scale: 1, top: 80 },
    }


    return (
        <div className="career_banner">
            <div className="wrapper">
                <div className="career_detail">
                    <div className="left_col">
                        <div className="title">
                            <motion.a
                                href="javascript:void(0)"
                                className="  button-container-1"
                                variants={titleButton}
                                initial="hidden"
                                animate="show"
                            >
                                <span
                                    className="mas"
                                >
                                     Career
                                </span>
                                <span className="mask_btn">
                                      Career
                                </span>
                            </motion.a>
                            <motion.h1
                                variants={textAnimation}
                                initial="hidden"
                                animate="show"
                                transition={{
                                    delay: 1,
                                    duration: 1,
                                    // delayChildren: 0.3,
                                }}
                            >
                                Innovators Wanted Join the Codeflix Web Team and
                                <motion.span
                                    variants={headingAnimation}
                                    initial="hidden"
                                    animate="show"
                                    transition={{
                                        delay: 2,
                                        duration: 2
                                    }}
                                >
                                    &nbsp; Create the Future !
                                </motion.span>
                            </motion.h1>

                        </div>
                    </div>
                    <div className="right_col">
                        <div className="bubble_wrap">
                            <div className="bubble_img">
                                <img src="./assets/images/new/bubble_2.svg" alt="" />
                            </div>
                            <div
                                className="image"
                            >
                                <BulbImage />
                            </div>
                        </div>
                    </div>
                </div>
                <LineImage />
            </div>
            {/* <img  src={} alt="Line 2" />, */}
        </div>
    )
}

export default Career
