import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Culture = () => {

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        prevArrow: <button type="button" className="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M4.41394 8.00003L13.9999 8.00003L13.9999 6.00003L4.41394 6.00003L8.70694 1.70703L7.29294 0.293032L0.585938 7.00003L7.29294 13.707L8.70694 12.293L4.41394 8.00003Z" fill="black"/></svg></button>,
        nextArrow: <button type="button" className="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M15.5861 11L6.00006 11L6.00006 13L15.5861 13L11.2931 17.293L12.7071 18.707L19.4141 12L12.7071 5.29297L11.2931 6.70697L15.5861 11Z" fill="black"/></svg></button>,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                }
            }
        ]
    };

    return (
        <div className="culture">
            <div className="wrapper">
                <div className="culture_wrap">
                    <a data-aos="zoom-out-up" href="javascript:void(0)" className="  button-container-1">
                        <span className="mas">
                             culture
                        </span>
                        <span className="mask_btn">
                             culture
                        </span>
                    </a>
                    <div className="culture_detail">
                        <div className="title">
                            <h2 data-aos="zoom-out-up"><b>Work. Connect. Inspire</b> The Spirit of Codeflix Web Culture</h2>
                            <p data-aos="zoom-out-up">It's a way of life woven into every project, every interaction, and every idea. Our culture is where innovative work thrives, connections flourish, and inspiration fuels growth. Join us in shaping the digital future with a team that embodies collaboration, embraces diversity, and lives to inspire.</p>
                        </div>
                    </div>
                </div>
                <div className="culture_slider">
                    <Slider {...settings}>
                        <div className="slide_col">
                            <div className="slide_item">
                                <div className="image_full">
                                    <div className="image">
                                        <img data-aos="zoom-out-up" src="./assets/images/culture/1.png" alt="" />
                                    </div>
                                </div>
                                <div className="image_half">
                                    <div className="image">
                                        <img data-aos="zoom-out-up" src="./assets/images/culture/2.png" alt="" />
                                    </div>
                                    <div className="image">
                                        <img data-aos="zoom-out-up" src="./assets/images/culture/3.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide_col">
                            <div className="slide_item">
                                <div className="image_full">
                                    <div className="image">
                                        <img data-aos="zoom-out-up" src="./assets/images/culture/4.png" alt="" />
                                    </div>
                                </div>
                                <div className="image_half">
                                    <div className="image">
                                        <img data-aos="zoom-out-up" src="./assets/images/culture/5.png" alt="" />
                                    </div>
                                    <div className="image">
                                        <img data-aos="zoom-out-up" src="./assets/images/culture/6.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide_col">
                            <div className="slide_item">
                                <div className="image_full">
                                    <div className="image">
                                        <img data-aos="zoom-out-up" src="./assets/images/culture/7.png" alt="" />
                                    </div>
                                </div>
                                <div className="image_half">
                                    <div className="image">
                                        <img data-aos="zoom-out-up" src="./assets/images/culture/8.png" alt="" />
                                    </div>
                                    <div className="image">
                                        <img data-aos="zoom-out-up" src="./assets/images/culture/9.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Culture
