import React, { useState, Component,useRef  } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css"; 
import Modal from 'react-bootstrap/Modal';
import { Button} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'; 
import emailjs from '@emailjs/browser'; 
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toast";

const CurrentOpening = () => {
 
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures the animation occurs only once
    threshold: 0.2, // Change as needed; it's the percentage of the element's visibility to trigger the animation
  });

  const item1 = {
    hidden: { scale: 0, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1,
        fade: "fadeIn",
      },
    },
  };

  const item2 = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 1,
        duration: 1,
        // fade: 'fadeIn'
      },
    },
  };
  const item3 = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 1.5,
        duration: 1,
        // fade: 'fadeIn'
      },
    },
  };
  const item4 = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 2,
        duration: 1,
        // fade: 'fadeIn'
      },
    },
  };
  const item5 = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 2.5,
        duration: 1,
        // fade: 'fadeIn'
      },
    },
  };
  const item6 = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 3,
        duration: 1,
        // fade: 'fadeIn'
      },
    },
  };
  const item7 = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 3.5,
        duration: 1,
        // fade: 'fadeIn'
      },
    },
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [color, setColor] = useState("#fff");
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState({
    name: "",
    reason: "",
    current_pkg: "",
    expected_pkg: "",
    available_date: "",
    resume: "",
  });
  const form = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();

    // setLoading(true);
    // await jobApplication(application)
    //   .then((res) => {
    //     console.log(res);
    //     toast(res.data.message, {
    //       backgroundColor: "#323131",
    //       color: "#ffffff",
    //     });
    //     setLoading(false);
    //     handleClose();
    //   })
    //   .catch((e, res) => {
    //     if (e.response.status == 422) {
    //       setError(e.response.data.result);
    //     }
    //     setLoading(false);
    //   });

    setLoading(true);
    console.log('setLoading(true);');
    emailjs
      .sendForm('service_gk513cd', 'template_fuwivk9', form.current, {
        publicKey: 'SFJCQjC1l21XCj7VA',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          handleClose();
          alert("SUCCESS!!!");
          setLoading(false);
        },
        (error) => {
          setError('FAILED...', error.text);
          setLoading(false);
        },
      );
  };

  const handleChange = (event) => {
   // setError([]);


    if (event.target.name == "resume") {
      
    console.log(event.target.files[0]);
      setApplication({
        ...application,
        [event.target.name]: event.target.files[0],
      });
    } else {
     console.log('event.target.value');
      setApplication({
        ...application,
        [event.target.name]: event.target.value,
      });
    }
  };
   return (
    <div className="current_opening" ref={ref}>
      <div className="wrapper">
        <div className="jobs_wrap">
          <motion.div
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={item1}
            className="title"
          >
            <h2>Recommended Jobs</h2>
            <p>Explore suggested job searches</p>
          </motion.div>
        </div>
        <div className="job_listing">
          <Tabs
            className="job_position"
            onSelect={(index) => console.log(index)}
            defaultIndex={1}
          >
            <TabList className="job_leftcol">
              <Tab disabled>
                <div><p>Job Categories</p></div>
              </Tab>
              <Tab>
                <div><p>All Jobs</p></div>
              </Tab>
              <Tab>
                <div><p>Frontend Developer</p> <span>All closed</span></div>
              </Tab>
              <Tab>
                <div><p>Backend Developer</p> <span>1 Opening</span></div>
              </Tab>
              <Tab>
                <div><p>Mobile Development </p> <span>All Closed</span></div>
              </Tab> 
              <Tab>
                <div><p>Management & sales  </p> <span>1 Opening</span></div>
              </Tab> 
              <Tab>
                <div><p>UI / UX</p> <span>All Closed</span></div>
              </Tab> 
            </TabList>
            <div className="job_rightcol"> 
            <TabPanel></TabPanel>
            <TabPanel>
                <div className="project_manager">
                    <div className="top">
                        <p>Experience: <span>2 Years+</span></p>
                        <p>Job Type: <span>Full-Time | On-site</span></p>
                    </div>
                    <h4> React JS Developer(Intermediate)</h4>
                    <h5>Knowledge of HTML, CSS, and JavaScript (ES6+).</h5>
                    <h5>Familiarity with Git and version control systems.</h5>
                    <div className="apply_now">
                        <div className="app_location">
                            <a href="avascript:void(0)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g clip-path="url(#clip0_24_88232)"><path d="M8.08559 17.2946C8.17796 17.4331 8.33349 17.5164 8.5 17.5164C8.66651 17.5164 8.82204 17.4332 8.91441 17.2946C10.0931 15.5266 11.8292 13.3431 13.039 11.1225C14.0063 9.34689 14.4766 7.83253 14.4766 6.49292C14.4766 3.19744 11.7955 0.516357 8.5 0.516357C5.20452 0.516357 2.52344 3.19744 2.52344 6.49292C2.52344 7.83253 2.99369 9.34689 3.96103 11.1225C5.16993 13.3414 6.90934 15.5302 8.08559 17.2946ZM8.5 1.51245C11.2462 1.51245 13.4805 3.74669 13.4805 6.49292C13.4805 7.6618 13.0499 9.02028 12.1643 10.6459C11.1215 12.5601 9.63336 14.5017 8.5 16.1341C7.36681 14.502 5.87861 12.5602 4.83574 10.6459C3.95008 9.02028 3.51953 7.6618 3.51953 6.49292C3.51953 3.74669 5.75377 1.51245 8.5 1.51245Z" fill="black"/><path d="M8.5 9.4812C10.1477 9.4812 11.4883 8.14066 11.4883 6.49292C11.4883 4.84518 10.1477 3.50464 8.5 3.50464C6.85226 3.50464 5.51172 4.84518 5.51172 6.49292C5.51172 8.14066 6.85226 9.4812 8.5 9.4812ZM8.5 4.50073C9.59849 4.50073 10.4922 5.39443 10.4922 6.49292C10.4922 7.59141 9.59849 8.48511 8.5 8.48511C7.40151 8.48511 6.50781 7.59141 6.50781 6.49292C6.50781 5.39443 7.40151 4.50073 8.5 4.50073Z" fill="black"/></g><defs><clipPath id="clip0_24_88232"><rect width="17" height="17" fill="white" transform="translate(0 0.516357)"/></clipPath></defs></svg> India
                            </a>
                            <a href="javascript:void(0)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><g clip-path="url(#clip0_24_88238)"><path d="M15.5342 2.86023C15.5332 2.86023 15.5322 2.86011 15.5312 2.86011H11.2812V2.39136C11.2812 1.61597 10.6504 0.985107 9.875 0.985107H6.125C5.34961 0.985107 4.71875 1.61597 4.71875 2.39136V2.86011H0.46875C0.207764 2.86011 0 3.07397 0 3.32886V13.6414C0 14.4167 0.630859 15.0476 1.40625 15.0476H14.5938C15.3691 15.0476 16 14.4167 16 13.6414V3.33875C16 3.33813 16 3.33752 16 3.33691C15.9821 3.02905 15.7927 2.86194 15.5342 2.86023ZM5.65625 2.39136C5.65625 2.13293 5.86658 1.92261 6.125 1.92261H9.875C10.1334 1.92261 10.3438 2.13293 10.3438 2.39136V2.86011H5.65625V2.39136ZM14.8809 3.79761L13.4252 8.16455C13.3613 8.35632 13.1826 8.48511 12.9806 8.48511H10.3438V8.01636C10.3438 7.75745 10.1339 7.54761 9.875 7.54761H6.125C5.86609 7.54761 5.65625 7.75745 5.65625 8.01636V8.48511H3.01941C2.81738 8.48511 2.63867 8.35632 2.57483 8.16455L1.11914 3.79761H14.8809ZM9.40625 8.48511V9.42261H6.59375V8.48511H9.40625ZM15.0625 13.6414C15.0625 13.8998 14.8522 14.1101 14.5938 14.1101H1.40625C1.14783 14.1101 0.9375 13.8998 0.9375 13.6414V6.21741L1.68542 8.46106C1.87708 9.03625 2.41321 9.42261 3.01941 9.42261H5.65625V9.89136C5.65625 10.1503 5.86609 10.3601 6.125 10.3601H9.875C10.1339 10.3601 10.3438 10.1503 10.3438 9.89136V9.42261H12.9806C13.5868 9.42261 14.1229 9.03625 14.3146 8.46106L15.0625 6.21741V13.6414Z" fill="black"/></g><defs><clipPath id="clip0_24_88238"><rect width="16" height="16" fill="white" transform="translate(0 0.0163574)"/></clipPath></defs></svg> Web Development
                            </a>
                        </div>
                        <a href="#" className="apply_btn inactive">Apply Now</a>
                    </div>
                </div>
                <div className="project_manager">
                    <div className="top">
                        <p>Experience: <span>2 Years+</span></p>
                        <p>Job Type: <span>Full-Time | On-site</span></p>
                    </div>
                    <h4>Node JS Developer(Intermediate)</h4>
                    <h5>Understanding of RESTful APIs and asynchronous programming.</h5>
                    <h5>Familiarity with front-end frameworks (React, Angular) is a plus.</h5>
                    <div className="apply_now">
                        <div className="app_location">
                            <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g clip-path="url(#clip0_24_88232)"><path d="M8.08559 17.2946C8.17796 17.4331 8.33349 17.5164 8.5 17.5164C8.66651 17.5164 8.82204 17.4332 8.91441 17.2946C10.0931 15.5266 11.8292 13.3431 13.039 11.1225C14.0063 9.34689 14.4766 7.83253 14.4766 6.49292C14.4766 3.19744 11.7955 0.516357 8.5 0.516357C5.20452 0.516357 2.52344 3.19744 2.52344 6.49292C2.52344 7.83253 2.99369 9.34689 3.96103 11.1225C5.16993 13.3414 6.90934 15.5302 8.08559 17.2946ZM8.5 1.51245C11.2462 1.51245 13.4805 3.74669 13.4805 6.49292C13.4805 7.6618 13.0499 9.02028 12.1643 10.6459C11.1215 12.5601 9.63336 14.5017 8.5 16.1341C7.36681 14.502 5.87861 12.5602 4.83574 10.6459C3.95008 9.02028 3.51953 7.6618 3.51953 6.49292C3.51953 3.74669 5.75377 1.51245 8.5 1.51245Z" fill="black"/><path d="M8.5 9.4812C10.1477 9.4812 11.4883 8.14066 11.4883 6.49292C11.4883 4.84518 10.1477 3.50464 8.5 3.50464C6.85226 3.50464 5.51172 4.84518 5.51172 6.49292C5.51172 8.14066 6.85226 9.4812 8.5 9.4812ZM8.5 4.50073C9.59849 4.50073 10.4922 5.39443 10.4922 6.49292C10.4922 7.59141 9.59849 8.48511 8.5 8.48511C7.40151 8.48511 6.50781 7.59141 6.50781 6.49292C6.50781 5.39443 7.40151 4.50073 8.5 4.50073Z" fill="black"/></g><defs><clipPath id="clip0_24_88232"><rect width="17" height="17" fill="white" transform="translate(0 0.516357)"/></clipPath></defs></svg> India
                            </a>
                            <a href="javascript:void(0)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><g clip-path="url(#clip0_24_88238)"><path d="M15.5342 2.86023C15.5332 2.86023 15.5322 2.86011 15.5312 2.86011H11.2812V2.39136C11.2812 1.61597 10.6504 0.985107 9.875 0.985107H6.125C5.34961 0.985107 4.71875 1.61597 4.71875 2.39136V2.86011H0.46875C0.207764 2.86011 0 3.07397 0 3.32886V13.6414C0 14.4167 0.630859 15.0476 1.40625 15.0476H14.5938C15.3691 15.0476 16 14.4167 16 13.6414V3.33875C16 3.33813 16 3.33752 16 3.33691C15.9821 3.02905 15.7927 2.86194 15.5342 2.86023ZM5.65625 2.39136C5.65625 2.13293 5.86658 1.92261 6.125 1.92261H9.875C10.1334 1.92261 10.3438 2.13293 10.3438 2.39136V2.86011H5.65625V2.39136ZM14.8809 3.79761L13.4252 8.16455C13.3613 8.35632 13.1826 8.48511 12.9806 8.48511H10.3438V8.01636C10.3438 7.75745 10.1339 7.54761 9.875 7.54761H6.125C5.86609 7.54761 5.65625 7.75745 5.65625 8.01636V8.48511H3.01941C2.81738 8.48511 2.63867 8.35632 2.57483 8.16455L1.11914 3.79761H14.8809ZM9.40625 8.48511V9.42261H6.59375V8.48511H9.40625ZM15.0625 13.6414C15.0625 13.8998 14.8522 14.1101 14.5938 14.1101H1.40625C1.14783 14.1101 0.9375 13.8998 0.9375 13.6414V6.21741L1.68542 8.46106C1.87708 9.03625 2.41321 9.42261 3.01941 9.42261H5.65625V9.89136C5.65625 10.1503 5.86609 10.3601 6.125 10.3601H9.875C10.1339 10.3601 10.3438 10.1503 10.3438 9.89136V9.42261H12.9806C13.5868 9.42261 14.1229 9.03625 14.3146 8.46106L15.0625 6.21741V13.6414Z" fill="black"/></g><defs><clipPath id="clip0_24_88238"><rect width="16" height="16" fill="white" transform="translate(0 0.0163574)"/></clipPath></defs></svg> Development
                            </a>
                        </div>
                        <a href="javascript:void(0)" className="apply_btn " onClick={handleShow} >Apply Now</a>
                    </div>
                </div>
                <div className="project_manager">
                    <div className="top">
                        <p>Experience: <span>1 Years+</span></p>
                        <p>Job Type: <span> Full-Time | On-site</span></p>
                    </div>
                    <h4>Jr Native Android Developer</h4>
                    <h5>Familiarity with Android Studio and third-party libraries.</h5>
                    <h5>Understanding of mobile UI/UX principles.</h5>
                    <div className="apply_now">
                        <div className="app_location">
                            <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g clip-path="url(#clip0_24_88232)"><path d="M8.08559 17.2946C8.17796 17.4331 8.33349 17.5164 8.5 17.5164C8.66651 17.5164 8.82204 17.4332 8.91441 17.2946C10.0931 15.5266 11.8292 13.3431 13.039 11.1225C14.0063 9.34689 14.4766 7.83253 14.4766 6.49292C14.4766 3.19744 11.7955 0.516357 8.5 0.516357C5.20452 0.516357 2.52344 3.19744 2.52344 6.49292C2.52344 7.83253 2.99369 9.34689 3.96103 11.1225C5.16993 13.3414 6.90934 15.5302 8.08559 17.2946ZM8.5 1.51245C11.2462 1.51245 13.4805 3.74669 13.4805 6.49292C13.4805 7.6618 13.0499 9.02028 12.1643 10.6459C11.1215 12.5601 9.63336 14.5017 8.5 16.1341C7.36681 14.502 5.87861 12.5602 4.83574 10.6459C3.95008 9.02028 3.51953 7.6618 3.51953 6.49292C3.51953 3.74669 5.75377 1.51245 8.5 1.51245Z" fill="black"/><path d="M8.5 9.4812C10.1477 9.4812 11.4883 8.14066 11.4883 6.49292C11.4883 4.84518 10.1477 3.50464 8.5 3.50464C6.85226 3.50464 5.51172 4.84518 5.51172 6.49292C5.51172 8.14066 6.85226 9.4812 8.5 9.4812ZM8.5 4.50073C9.59849 4.50073 10.4922 5.39443 10.4922 6.49292C10.4922 7.59141 9.59849 8.48511 8.5 8.48511C7.40151 8.48511 6.50781 7.59141 6.50781 6.49292C6.50781 5.39443 7.40151 4.50073 8.5 4.50073Z" fill="black"/></g><defs><clipPath id="clip0_24_88232"><rect width="17" height="17" fill="white" transform="translate(0 0.516357)"/></clipPath></defs></svg> India
                            </a>
                            <a href="javascript:void(0)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><g clip-path="url(#clip0_24_88238)"><path d="M15.5342 2.86023C15.5332 2.86023 15.5322 2.86011 15.5312 2.86011H11.2812V2.39136C11.2812 1.61597 10.6504 0.985107 9.875 0.985107H6.125C5.34961 0.985107 4.71875 1.61597 4.71875 2.39136V2.86011H0.46875C0.207764 2.86011 0 3.07397 0 3.32886V13.6414C0 14.4167 0.630859 15.0476 1.40625 15.0476H14.5938C15.3691 15.0476 16 14.4167 16 13.6414V3.33875C16 3.33813 16 3.33752 16 3.33691C15.9821 3.02905 15.7927 2.86194 15.5342 2.86023ZM5.65625 2.39136C5.65625 2.13293 5.86658 1.92261 6.125 1.92261H9.875C10.1334 1.92261 10.3438 2.13293 10.3438 2.39136V2.86011H5.65625V2.39136ZM14.8809 3.79761L13.4252 8.16455C13.3613 8.35632 13.1826 8.48511 12.9806 8.48511H10.3438V8.01636C10.3438 7.75745 10.1339 7.54761 9.875 7.54761H6.125C5.86609 7.54761 5.65625 7.75745 5.65625 8.01636V8.48511H3.01941C2.81738 8.48511 2.63867 8.35632 2.57483 8.16455L1.11914 3.79761H14.8809ZM9.40625 8.48511V9.42261H6.59375V8.48511H9.40625ZM15.0625 13.6414C15.0625 13.8998 14.8522 14.1101 14.5938 14.1101H1.40625C1.14783 14.1101 0.9375 13.8998 0.9375 13.6414V6.21741L1.68542 8.46106C1.87708 9.03625 2.41321 9.42261 3.01941 9.42261H5.65625V9.89136C5.65625 10.1503 5.86609 10.3601 6.125 10.3601H9.875C10.1339 10.3601 10.3438 10.1503 10.3438 9.89136V9.42261H12.9806C13.5868 9.42261 14.1229 9.03625 14.3146 8.46106L15.0625 6.21741V13.6414Z" fill="black"/></g><defs><clipPath id="clip0_24_88238"><rect width="16" height="16" fill="white" transform="translate(0 0.0163574)"/></clipPath></defs></svg> App Development
                            </a>
                        </div>
                        <a href="#" className="apply_btn inactive">Apply Now</a>
                    </div>
                </div>
                <div className="project_manager">
                    <div className="top">
                        <p>Job Type: <span>Full-Time | On-site</span></p>
                    </div>
                    <h4>Project Manager</h4>
                    <h5>Proven experience in managing IT/software projects.</h5>
                    <h5>Strong organizational and problem-solving skills.</h5>
                    <h5>Familiarity with Agile methodologies is a plus.</h5>
                    <div className="apply_now">
                        <div className="app_location">
                            <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g clip-path="url(#clip0_24_88232)"><path d="M8.08559 17.2946C8.17796 17.4331 8.33349 17.5164 8.5 17.5164C8.66651 17.5164 8.82204 17.4332 8.91441 17.2946C10.0931 15.5266 11.8292 13.3431 13.039 11.1225C14.0063 9.34689 14.4766 7.83253 14.4766 6.49292C14.4766 3.19744 11.7955 0.516357 8.5 0.516357C5.20452 0.516357 2.52344 3.19744 2.52344 6.49292C2.52344 7.83253 2.99369 9.34689 3.96103 11.1225C5.16993 13.3414 6.90934 15.5302 8.08559 17.2946ZM8.5 1.51245C11.2462 1.51245 13.4805 3.74669 13.4805 6.49292C13.4805 7.6618 13.0499 9.02028 12.1643 10.6459C11.1215 12.5601 9.63336 14.5017 8.5 16.1341C7.36681 14.502 5.87861 12.5602 4.83574 10.6459C3.95008 9.02028 3.51953 7.6618 3.51953 6.49292C3.51953 3.74669 5.75377 1.51245 8.5 1.51245Z" fill="black"/><path d="M8.5 9.4812C10.1477 9.4812 11.4883 8.14066 11.4883 6.49292C11.4883 4.84518 10.1477 3.50464 8.5 3.50464C6.85226 3.50464 5.51172 4.84518 5.51172 6.49292C5.51172 8.14066 6.85226 9.4812 8.5 9.4812ZM8.5 4.50073C9.59849 4.50073 10.4922 5.39443 10.4922 6.49292C10.4922 7.59141 9.59849 8.48511 8.5 8.48511C7.40151 8.48511 6.50781 7.59141 6.50781 6.49292C6.50781 5.39443 7.40151 4.50073 8.5 4.50073Z" fill="black"/></g><defs><clipPath id="clip0_24_88232"><rect width="17" height="17" fill="white" transform="translate(0 0.516357)"/></clipPath></defs></svg> India
                            </a>
                            
                        </div>
                        <a href="#" className="apply_btn inactive">Apply Now</a>
                    </div>
                </div>
                <div className="project_manager">
                    <div className="top">
                        <p>Experience: <span>2-3 Years</span></p>
                        <p>Job Type: <span> Full-Time | On-site</span></p>
                    </div>
                    <h4>Business Development Executive (Intermediate)</h4>
                    <h5>2-3 years of experience in IT sales or similar roles.</h5>
                    <h5>Proven track record on platforms like Upwork, Fiverr, etc.</h5>
                    <h5>Strong communication and negotiation skills.</h5>
                    <h5>Ability to work in a target-driven environment.</h5>
                    <div className="apply_now">
                        <div className="app_location">
                            <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g clip-path="url(#clip0_24_88232)"><path d="M8.08559 17.2946C8.17796 17.4331 8.33349 17.5164 8.5 17.5164C8.66651 17.5164 8.82204 17.4332 8.91441 17.2946C10.0931 15.5266 11.8292 13.3431 13.039 11.1225C14.0063 9.34689 14.4766 7.83253 14.4766 6.49292C14.4766 3.19744 11.7955 0.516357 8.5 0.516357C5.20452 0.516357 2.52344 3.19744 2.52344 6.49292C2.52344 7.83253 2.99369 9.34689 3.96103 11.1225C5.16993 13.3414 6.90934 15.5302 8.08559 17.2946ZM8.5 1.51245C11.2462 1.51245 13.4805 3.74669 13.4805 6.49292C13.4805 7.6618 13.0499 9.02028 12.1643 10.6459C11.1215 12.5601 9.63336 14.5017 8.5 16.1341C7.36681 14.502 5.87861 12.5602 4.83574 10.6459C3.95008 9.02028 3.51953 7.6618 3.51953 6.49292C3.51953 3.74669 5.75377 1.51245 8.5 1.51245Z" fill="black"/><path d="M8.5 9.4812C10.1477 9.4812 11.4883 8.14066 11.4883 6.49292C11.4883 4.84518 10.1477 3.50464 8.5 3.50464C6.85226 3.50464 5.51172 4.84518 5.51172 6.49292C5.51172 8.14066 6.85226 9.4812 8.5 9.4812ZM8.5 4.50073C9.59849 4.50073 10.4922 5.39443 10.4922 6.49292C10.4922 7.59141 9.59849 8.48511 8.5 8.48511C7.40151 8.48511 6.50781 7.59141 6.50781 6.49292C6.50781 5.39443 7.40151 4.50073 8.5 4.50073Z" fill="black"/></g><defs><clipPath id="clip0_24_88232"><rect width="17" height="17" fill="white" transform="translate(0 0.516357)"/></clipPath></defs></svg> India
                            </a> 
                        </div>
                        <a href="#" className="apply_btn" onClick={handleShow} >Apply Now</a>
                    </div>
                </div>
                <div className="project_manager">
                    <div className="top">
                        <p>Experience: <span>1–3 Years+</span></p>
                        <p>Job Type: <span>Full-Time | On-site</span></p>
                    </div>
                    <h4>UI/UX Designer </h4>
                    <h5>Proficiency in design tools like Figma, Adobe XD, or Sketch.</h5>
                    <h5>Strong portfolio showcasing web and mobile app designs.</h5>
                    <h5>Excellent understanding of responsive design principles.</h5>
                    <h5>Attention to detail and creative problem-solving skills.</h5>
                    <div className="apply_now">
                        <div className="app_location">
                            <a href="avascript:void(0)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g clip-path="url(#clip0_24_88232)"><path d="M8.08559 17.2946C8.17796 17.4331 8.33349 17.5164 8.5 17.5164C8.66651 17.5164 8.82204 17.4332 8.91441 17.2946C10.0931 15.5266 11.8292 13.3431 13.039 11.1225C14.0063 9.34689 14.4766 7.83253 14.4766 6.49292C14.4766 3.19744 11.7955 0.516357 8.5 0.516357C5.20452 0.516357 2.52344 3.19744 2.52344 6.49292C2.52344 7.83253 2.99369 9.34689 3.96103 11.1225C5.16993 13.3414 6.90934 15.5302 8.08559 17.2946ZM8.5 1.51245C11.2462 1.51245 13.4805 3.74669 13.4805 6.49292C13.4805 7.6618 13.0499 9.02028 12.1643 10.6459C11.1215 12.5601 9.63336 14.5017 8.5 16.1341C7.36681 14.502 5.87861 12.5602 4.83574 10.6459C3.95008 9.02028 3.51953 7.6618 3.51953 6.49292C3.51953 3.74669 5.75377 1.51245 8.5 1.51245Z" fill="black"/><path d="M8.5 9.4812C10.1477 9.4812 11.4883 8.14066 11.4883 6.49292C11.4883 4.84518 10.1477 3.50464 8.5 3.50464C6.85226 3.50464 5.51172 4.84518 5.51172 6.49292C5.51172 8.14066 6.85226 9.4812 8.5 9.4812ZM8.5 4.50073C9.59849 4.50073 10.4922 5.39443 10.4922 6.49292C10.4922 7.59141 9.59849 8.48511 8.5 8.48511C7.40151 8.48511 6.50781 7.59141 6.50781 6.49292C6.50781 5.39443 7.40151 4.50073 8.5 4.50073Z" fill="black"/></g><defs><clipPath id="clip0_24_88232"><rect width="17" height="17" fill="white" transform="translate(0 0.516357)"/></clipPath></defs></svg> India
                            </a> 
                        </div>
                        <a href="#" className="apply_btn inactive">Apply Now</a>
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <div className="project_manager">
                    <div className="top">
                        <p>Experience: <span>2 Years+</span></p>
                        <p>Job Type: <span>Full-Time | On-site</span></p>
                    </div>
                    <h4> React JS Developer(Intermediate)</h4>
                    <h5>Knowledge of HTML, CSS, and JavaScript (ES6+).</h5>
                    <h5>Familiarity with Git and version control systems.</h5>
                    <div className="apply_now">
                        <div className="app_location">
                            <a href="avascript:void(0)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g clip-path="url(#clip0_24_88232)"><path d="M8.08559 17.2946C8.17796 17.4331 8.33349 17.5164 8.5 17.5164C8.66651 17.5164 8.82204 17.4332 8.91441 17.2946C10.0931 15.5266 11.8292 13.3431 13.039 11.1225C14.0063 9.34689 14.4766 7.83253 14.4766 6.49292C14.4766 3.19744 11.7955 0.516357 8.5 0.516357C5.20452 0.516357 2.52344 3.19744 2.52344 6.49292C2.52344 7.83253 2.99369 9.34689 3.96103 11.1225C5.16993 13.3414 6.90934 15.5302 8.08559 17.2946ZM8.5 1.51245C11.2462 1.51245 13.4805 3.74669 13.4805 6.49292C13.4805 7.6618 13.0499 9.02028 12.1643 10.6459C11.1215 12.5601 9.63336 14.5017 8.5 16.1341C7.36681 14.502 5.87861 12.5602 4.83574 10.6459C3.95008 9.02028 3.51953 7.6618 3.51953 6.49292C3.51953 3.74669 5.75377 1.51245 8.5 1.51245Z" fill="black"/><path d="M8.5 9.4812C10.1477 9.4812 11.4883 8.14066 11.4883 6.49292C11.4883 4.84518 10.1477 3.50464 8.5 3.50464C6.85226 3.50464 5.51172 4.84518 5.51172 6.49292C5.51172 8.14066 6.85226 9.4812 8.5 9.4812ZM8.5 4.50073C9.59849 4.50073 10.4922 5.39443 10.4922 6.49292C10.4922 7.59141 9.59849 8.48511 8.5 8.48511C7.40151 8.48511 6.50781 7.59141 6.50781 6.49292C6.50781 5.39443 7.40151 4.50073 8.5 4.50073Z" fill="black"/></g><defs><clipPath id="clip0_24_88232"><rect width="17" height="17" fill="white" transform="translate(0 0.516357)"/></clipPath></defs></svg> India
                            </a>
                            <a href="javascript:void(0)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><g clip-path="url(#clip0_24_88238)"><path d="M15.5342 2.86023C15.5332 2.86023 15.5322 2.86011 15.5312 2.86011H11.2812V2.39136C11.2812 1.61597 10.6504 0.985107 9.875 0.985107H6.125C5.34961 0.985107 4.71875 1.61597 4.71875 2.39136V2.86011H0.46875C0.207764 2.86011 0 3.07397 0 3.32886V13.6414C0 14.4167 0.630859 15.0476 1.40625 15.0476H14.5938C15.3691 15.0476 16 14.4167 16 13.6414V3.33875C16 3.33813 16 3.33752 16 3.33691C15.9821 3.02905 15.7927 2.86194 15.5342 2.86023ZM5.65625 2.39136C5.65625 2.13293 5.86658 1.92261 6.125 1.92261H9.875C10.1334 1.92261 10.3438 2.13293 10.3438 2.39136V2.86011H5.65625V2.39136ZM14.8809 3.79761L13.4252 8.16455C13.3613 8.35632 13.1826 8.48511 12.9806 8.48511H10.3438V8.01636C10.3438 7.75745 10.1339 7.54761 9.875 7.54761H6.125C5.86609 7.54761 5.65625 7.75745 5.65625 8.01636V8.48511H3.01941C2.81738 8.48511 2.63867 8.35632 2.57483 8.16455L1.11914 3.79761H14.8809ZM9.40625 8.48511V9.42261H6.59375V8.48511H9.40625ZM15.0625 13.6414C15.0625 13.8998 14.8522 14.1101 14.5938 14.1101H1.40625C1.14783 14.1101 0.9375 13.8998 0.9375 13.6414V6.21741L1.68542 8.46106C1.87708 9.03625 2.41321 9.42261 3.01941 9.42261H5.65625V9.89136C5.65625 10.1503 5.86609 10.3601 6.125 10.3601H9.875C10.1339 10.3601 10.3438 10.1503 10.3438 9.89136V9.42261H12.9806C13.5868 9.42261 14.1229 9.03625 14.3146 8.46106L15.0625 6.21741V13.6414Z" fill="black"/></g><defs><clipPath id="clip0_24_88238"><rect width="16" height="16" fill="white" transform="translate(0 0.0163574)"/></clipPath></defs></svg> Web Development
                            </a>
                        </div>
                        <a href="#" className="apply_btn inactive">Apply Now</a>
                    </div>
                </div>
               
               
            </TabPanel>
            <TabPanel>
                <div className="project_manager">
                    <div className="top">
                        <p>Experience: <span>2 Years+</span></p>
                        <p>Job Type: <span>Full-Time | On-site</span></p>
                    </div>
                    <h4>Node JS Developer(Intermediate)</h4>
                    <h5>Understanding of RESTful APIs and asynchronous programming.</h5>
                    <h5>Familiarity with front-end frameworks (React, Angular) is a plus.</h5>
                    <div className="apply_now">
                        <div className="app_location">
                            <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g clip-path="url(#clip0_24_88232)"><path d="M8.08559 17.2946C8.17796 17.4331 8.33349 17.5164 8.5 17.5164C8.66651 17.5164 8.82204 17.4332 8.91441 17.2946C10.0931 15.5266 11.8292 13.3431 13.039 11.1225C14.0063 9.34689 14.4766 7.83253 14.4766 6.49292C14.4766 3.19744 11.7955 0.516357 8.5 0.516357C5.20452 0.516357 2.52344 3.19744 2.52344 6.49292C2.52344 7.83253 2.99369 9.34689 3.96103 11.1225C5.16993 13.3414 6.90934 15.5302 8.08559 17.2946ZM8.5 1.51245C11.2462 1.51245 13.4805 3.74669 13.4805 6.49292C13.4805 7.6618 13.0499 9.02028 12.1643 10.6459C11.1215 12.5601 9.63336 14.5017 8.5 16.1341C7.36681 14.502 5.87861 12.5602 4.83574 10.6459C3.95008 9.02028 3.51953 7.6618 3.51953 6.49292C3.51953 3.74669 5.75377 1.51245 8.5 1.51245Z" fill="black"/><path d="M8.5 9.4812C10.1477 9.4812 11.4883 8.14066 11.4883 6.49292C11.4883 4.84518 10.1477 3.50464 8.5 3.50464C6.85226 3.50464 5.51172 4.84518 5.51172 6.49292C5.51172 8.14066 6.85226 9.4812 8.5 9.4812ZM8.5 4.50073C9.59849 4.50073 10.4922 5.39443 10.4922 6.49292C10.4922 7.59141 9.59849 8.48511 8.5 8.48511C7.40151 8.48511 6.50781 7.59141 6.50781 6.49292C6.50781 5.39443 7.40151 4.50073 8.5 4.50073Z" fill="black"/></g><defs><clipPath id="clip0_24_88232"><rect width="17" height="17" fill="white" transform="translate(0 0.516357)"/></clipPath></defs></svg> India
                            </a>
                            <a href="javascript:void(0)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><g clip-path="url(#clip0_24_88238)"><path d="M15.5342 2.86023C15.5332 2.86023 15.5322 2.86011 15.5312 2.86011H11.2812V2.39136C11.2812 1.61597 10.6504 0.985107 9.875 0.985107H6.125C5.34961 0.985107 4.71875 1.61597 4.71875 2.39136V2.86011H0.46875C0.207764 2.86011 0 3.07397 0 3.32886V13.6414C0 14.4167 0.630859 15.0476 1.40625 15.0476H14.5938C15.3691 15.0476 16 14.4167 16 13.6414V3.33875C16 3.33813 16 3.33752 16 3.33691C15.9821 3.02905 15.7927 2.86194 15.5342 2.86023ZM5.65625 2.39136C5.65625 2.13293 5.86658 1.92261 6.125 1.92261H9.875C10.1334 1.92261 10.3438 2.13293 10.3438 2.39136V2.86011H5.65625V2.39136ZM14.8809 3.79761L13.4252 8.16455C13.3613 8.35632 13.1826 8.48511 12.9806 8.48511H10.3438V8.01636C10.3438 7.75745 10.1339 7.54761 9.875 7.54761H6.125C5.86609 7.54761 5.65625 7.75745 5.65625 8.01636V8.48511H3.01941C2.81738 8.48511 2.63867 8.35632 2.57483 8.16455L1.11914 3.79761H14.8809ZM9.40625 8.48511V9.42261H6.59375V8.48511H9.40625ZM15.0625 13.6414C15.0625 13.8998 14.8522 14.1101 14.5938 14.1101H1.40625C1.14783 14.1101 0.9375 13.8998 0.9375 13.6414V6.21741L1.68542 8.46106C1.87708 9.03625 2.41321 9.42261 3.01941 9.42261H5.65625V9.89136C5.65625 10.1503 5.86609 10.3601 6.125 10.3601H9.875C10.1339 10.3601 10.3438 10.1503 10.3438 9.89136V9.42261H12.9806C13.5868 9.42261 14.1229 9.03625 14.3146 8.46106L15.0625 6.21741V13.6414Z" fill="black"/></g><defs><clipPath id="clip0_24_88238"><rect width="16" height="16" fill="white" transform="translate(0 0.0163574)"/></clipPath></defs></svg> Development
                            </a>
                        </div>
                        <a href="#" className="apply_btn" onClick={handleShow} >Apply Now</a>
                    </div>
                </div> 
            </TabPanel>
            <TabPanel>
                <div className="project_manager">
                    <div className="top">
                        <p>Experience: <span>1 Years+</span></p>
                        <p>Job Type: <span> Full-Time | On-site</span></p>
                    </div>
                    <h4>Jr Native Android Developer</h4>
                    <h5>Familiarity with Android Studio and third-party libraries.</h5>
                    <h5>Understanding of mobile UI/UX principles.</h5>
                    <div className="apply_now">
                        <div className="app_location">
                            <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g clip-path="url(#clip0_24_88232)"><path d="M8.08559 17.2946C8.17796 17.4331 8.33349 17.5164 8.5 17.5164C8.66651 17.5164 8.82204 17.4332 8.91441 17.2946C10.0931 15.5266 11.8292 13.3431 13.039 11.1225C14.0063 9.34689 14.4766 7.83253 14.4766 6.49292C14.4766 3.19744 11.7955 0.516357 8.5 0.516357C5.20452 0.516357 2.52344 3.19744 2.52344 6.49292C2.52344 7.83253 2.99369 9.34689 3.96103 11.1225C5.16993 13.3414 6.90934 15.5302 8.08559 17.2946ZM8.5 1.51245C11.2462 1.51245 13.4805 3.74669 13.4805 6.49292C13.4805 7.6618 13.0499 9.02028 12.1643 10.6459C11.1215 12.5601 9.63336 14.5017 8.5 16.1341C7.36681 14.502 5.87861 12.5602 4.83574 10.6459C3.95008 9.02028 3.51953 7.6618 3.51953 6.49292C3.51953 3.74669 5.75377 1.51245 8.5 1.51245Z" fill="black"/><path d="M8.5 9.4812C10.1477 9.4812 11.4883 8.14066 11.4883 6.49292C11.4883 4.84518 10.1477 3.50464 8.5 3.50464C6.85226 3.50464 5.51172 4.84518 5.51172 6.49292C5.51172 8.14066 6.85226 9.4812 8.5 9.4812ZM8.5 4.50073C9.59849 4.50073 10.4922 5.39443 10.4922 6.49292C10.4922 7.59141 9.59849 8.48511 8.5 8.48511C7.40151 8.48511 6.50781 7.59141 6.50781 6.49292C6.50781 5.39443 7.40151 4.50073 8.5 4.50073Z" fill="black"/></g><defs><clipPath id="clip0_24_88232"><rect width="17" height="17" fill="white" transform="translate(0 0.516357)"/></clipPath></defs></svg> India
                            </a>
                            <a href="javascript:void(0)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><g clip-path="url(#clip0_24_88238)"><path d="M15.5342 2.86023C15.5332 2.86023 15.5322 2.86011 15.5312 2.86011H11.2812V2.39136C11.2812 1.61597 10.6504 0.985107 9.875 0.985107H6.125C5.34961 0.985107 4.71875 1.61597 4.71875 2.39136V2.86011H0.46875C0.207764 2.86011 0 3.07397 0 3.32886V13.6414C0 14.4167 0.630859 15.0476 1.40625 15.0476H14.5938C15.3691 15.0476 16 14.4167 16 13.6414V3.33875C16 3.33813 16 3.33752 16 3.33691C15.9821 3.02905 15.7927 2.86194 15.5342 2.86023ZM5.65625 2.39136C5.65625 2.13293 5.86658 1.92261 6.125 1.92261H9.875C10.1334 1.92261 10.3438 2.13293 10.3438 2.39136V2.86011H5.65625V2.39136ZM14.8809 3.79761L13.4252 8.16455C13.3613 8.35632 13.1826 8.48511 12.9806 8.48511H10.3438V8.01636C10.3438 7.75745 10.1339 7.54761 9.875 7.54761H6.125C5.86609 7.54761 5.65625 7.75745 5.65625 8.01636V8.48511H3.01941C2.81738 8.48511 2.63867 8.35632 2.57483 8.16455L1.11914 3.79761H14.8809ZM9.40625 8.48511V9.42261H6.59375V8.48511H9.40625ZM15.0625 13.6414C15.0625 13.8998 14.8522 14.1101 14.5938 14.1101H1.40625C1.14783 14.1101 0.9375 13.8998 0.9375 13.6414V6.21741L1.68542 8.46106C1.87708 9.03625 2.41321 9.42261 3.01941 9.42261H5.65625V9.89136C5.65625 10.1503 5.86609 10.3601 6.125 10.3601H9.875C10.1339 10.3601 10.3438 10.1503 10.3438 9.89136V9.42261H12.9806C13.5868 9.42261 14.1229 9.03625 14.3146 8.46106L15.0625 6.21741V13.6414Z" fill="black"/></g><defs><clipPath id="clip0_24_88238"><rect width="16" height="16" fill="white" transform="translate(0 0.0163574)"/></clipPath></defs></svg> App Development
                            </a>
                        </div>
                        <a href="#" className="apply_btn inactive">Apply Now</a>
                    </div>
                </div>
               
            </TabPanel>
            <TabPanel>
                <div className="project_manager">
                    <div className="top">
                        <p>Job Type: <span>Full-Time | On-site</span></p>
                    </div>
                    <h4>Project Manager</h4>
                    <h5>Proven experience in managing IT/software projects.</h5>
                    <h5>Strong organizational and problem-solving skills.</h5>
                    <h5>Familiarity with Agile methodologies is a plus.</h5>
                    <div className="apply_now">
                        <div className="app_location">
                            <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g clip-path="url(#clip0_24_88232)"><path d="M8.08559 17.2946C8.17796 17.4331 8.33349 17.5164 8.5 17.5164C8.66651 17.5164 8.82204 17.4332 8.91441 17.2946C10.0931 15.5266 11.8292 13.3431 13.039 11.1225C14.0063 9.34689 14.4766 7.83253 14.4766 6.49292C14.4766 3.19744 11.7955 0.516357 8.5 0.516357C5.20452 0.516357 2.52344 3.19744 2.52344 6.49292C2.52344 7.83253 2.99369 9.34689 3.96103 11.1225C5.16993 13.3414 6.90934 15.5302 8.08559 17.2946ZM8.5 1.51245C11.2462 1.51245 13.4805 3.74669 13.4805 6.49292C13.4805 7.6618 13.0499 9.02028 12.1643 10.6459C11.1215 12.5601 9.63336 14.5017 8.5 16.1341C7.36681 14.502 5.87861 12.5602 4.83574 10.6459C3.95008 9.02028 3.51953 7.6618 3.51953 6.49292C3.51953 3.74669 5.75377 1.51245 8.5 1.51245Z" fill="black"/><path d="M8.5 9.4812C10.1477 9.4812 11.4883 8.14066 11.4883 6.49292C11.4883 4.84518 10.1477 3.50464 8.5 3.50464C6.85226 3.50464 5.51172 4.84518 5.51172 6.49292C5.51172 8.14066 6.85226 9.4812 8.5 9.4812ZM8.5 4.50073C9.59849 4.50073 10.4922 5.39443 10.4922 6.49292C10.4922 7.59141 9.59849 8.48511 8.5 8.48511C7.40151 8.48511 6.50781 7.59141 6.50781 6.49292C6.50781 5.39443 7.40151 4.50073 8.5 4.50073Z" fill="black"/></g><defs><clipPath id="clip0_24_88232"><rect width="17" height="17" fill="white" transform="translate(0 0.516357)"/></clipPath></defs></svg> India
                            </a>
                            
                        </div>
                        <a href="#" className="apply_btn inactive">Apply Now</a>
                    </div>
                </div>
                <div className="project_manager">
                    <div className="top">
                        <p>Experience: <span>2-3 Years</span></p>
                        <p>Job Type: <span> Full-Time | On-site</span></p>
                    </div>
                    <h4>Business Development Executive (Intermediate)</h4>
                    <h5>2-3 years of experience in IT sales or similar roles.</h5>
                    <h5>Proven track record on platforms like Upwork, Fiverr, etc.</h5>
                    <h5>Strong communication and negotiation skills.</h5>
                    <h5>Ability to work in a target-driven environment.</h5>
                    <div className="apply_now">
                        <div className="app_location">
                            <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g clip-path="url(#clip0_24_88232)"><path d="M8.08559 17.2946C8.17796 17.4331 8.33349 17.5164 8.5 17.5164C8.66651 17.5164 8.82204 17.4332 8.91441 17.2946C10.0931 15.5266 11.8292 13.3431 13.039 11.1225C14.0063 9.34689 14.4766 7.83253 14.4766 6.49292C14.4766 3.19744 11.7955 0.516357 8.5 0.516357C5.20452 0.516357 2.52344 3.19744 2.52344 6.49292C2.52344 7.83253 2.99369 9.34689 3.96103 11.1225C5.16993 13.3414 6.90934 15.5302 8.08559 17.2946ZM8.5 1.51245C11.2462 1.51245 13.4805 3.74669 13.4805 6.49292C13.4805 7.6618 13.0499 9.02028 12.1643 10.6459C11.1215 12.5601 9.63336 14.5017 8.5 16.1341C7.36681 14.502 5.87861 12.5602 4.83574 10.6459C3.95008 9.02028 3.51953 7.6618 3.51953 6.49292C3.51953 3.74669 5.75377 1.51245 8.5 1.51245Z" fill="black"/><path d="M8.5 9.4812C10.1477 9.4812 11.4883 8.14066 11.4883 6.49292C11.4883 4.84518 10.1477 3.50464 8.5 3.50464C6.85226 3.50464 5.51172 4.84518 5.51172 6.49292C5.51172 8.14066 6.85226 9.4812 8.5 9.4812ZM8.5 4.50073C9.59849 4.50073 10.4922 5.39443 10.4922 6.49292C10.4922 7.59141 9.59849 8.48511 8.5 8.48511C7.40151 8.48511 6.50781 7.59141 6.50781 6.49292C6.50781 5.39443 7.40151 4.50073 8.5 4.50073Z" fill="black"/></g><defs><clipPath id="clip0_24_88232"><rect width="17" height="17" fill="white" transform="translate(0 0.516357)"/></clipPath></defs></svg> India
                            </a> 
                        </div>
                        <a href="#" className="apply_btn" onClick={handleShow} >Apply Now</a>
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <div className="project_manager">
                    <div className="top">
                        <p>Experience: <span>1–3 Years+</span></p>
                        <p>Job Type: <span>Full-Time | On-site</span></p>
                    </div>
                    <h4>UI/UX Designer </h4>
                    <h5>Proficiency in design tools like Figma, Adobe XD, or Sketch.</h5>
                    <h5>Strong portfolio showcasing web and mobile app designs.</h5>
                    <h5>Excellent understanding of responsive design principles.</h5>
                    <h5>Attention to detail and creative problem-solving skills.</h5>
                    <div className="apply_now">
                        <div className="app_location">
                            <a href="avascript:void(0)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g clip-path="url(#clip0_24_88232)"><path d="M8.08559 17.2946C8.17796 17.4331 8.33349 17.5164 8.5 17.5164C8.66651 17.5164 8.82204 17.4332 8.91441 17.2946C10.0931 15.5266 11.8292 13.3431 13.039 11.1225C14.0063 9.34689 14.4766 7.83253 14.4766 6.49292C14.4766 3.19744 11.7955 0.516357 8.5 0.516357C5.20452 0.516357 2.52344 3.19744 2.52344 6.49292C2.52344 7.83253 2.99369 9.34689 3.96103 11.1225C5.16993 13.3414 6.90934 15.5302 8.08559 17.2946ZM8.5 1.51245C11.2462 1.51245 13.4805 3.74669 13.4805 6.49292C13.4805 7.6618 13.0499 9.02028 12.1643 10.6459C11.1215 12.5601 9.63336 14.5017 8.5 16.1341C7.36681 14.502 5.87861 12.5602 4.83574 10.6459C3.95008 9.02028 3.51953 7.6618 3.51953 6.49292C3.51953 3.74669 5.75377 1.51245 8.5 1.51245Z" fill="black"/><path d="M8.5 9.4812C10.1477 9.4812 11.4883 8.14066 11.4883 6.49292C11.4883 4.84518 10.1477 3.50464 8.5 3.50464C6.85226 3.50464 5.51172 4.84518 5.51172 6.49292C5.51172 8.14066 6.85226 9.4812 8.5 9.4812ZM8.5 4.50073C9.59849 4.50073 10.4922 5.39443 10.4922 6.49292C10.4922 7.59141 9.59849 8.48511 8.5 8.48511C7.40151 8.48511 6.50781 7.59141 6.50781 6.49292C6.50781 5.39443 7.40151 4.50073 8.5 4.50073Z" fill="black"/></g><defs><clipPath id="clip0_24_88232"><rect width="17" height="17" fill="white" transform="translate(0 0.516357)"/></clipPath></defs></svg> India
                            </a> 
                        </div>
                        <a href="#" className="apply_btn inactive">Apply Now</a>
                    </div>
                </div>
            </TabPanel>
            </div>
          </Tabs>

          {/* <motion.a
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        variants={item7}
                        href="javascript:void(0)"
                        className="job_col"
                    >
                        <div className="mask">
                            <div className="col">
                                <div className="image">
                                    <img src="./assets/images/new/j66.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>Human Recruiter</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>Soft Skills</li>
                                        <li>Adaptability</li>
                                        <li>Strategic</li>
                                        <li>Talent Acquisition</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mask_wrap">
                            <div className="col">
                                <div className="image">
                                    <img src="./assets/images/new/j6.svg" alt="" />
                                </div>
                                <div className="detail">
                                    <h3>Human Recruiter</h3>
                                    <span className="time">Full-time</span>
                                    <ul>
                                        <li>Communication Skills</li>
                                        <li>Adaptability and Flexibility</li>
                                        <li>Strategic</li>
                                        <li>Talent Acquisition</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </motion.a> */}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton >
          <h2>Apply Now !</h2>
          
        </Modal.Header>
        <Modal.Body className="contact_us open_model">
          <div className="contact_sec">
        <div className="left_col">
            
            <div className="contact_form1">
              <form
                action=""
                method="post"
                onSubmit={handleSubmit}
                enctype="multipart/form-data"
              >
                <input
                  type="text"
                  name="name"
                  data-aos="zoom-out-up"
                  placeholder="Name"
                  className="input_col aos-init aos-animate"
                  onChange={handleChange}
                />
                <p>{error?.name}</p>
                <textarea
                  data-aos="zoom-out-up"
                  name="reason"
                  id="reason"
                  cols="10"
                  rows="1"
                  placeholder="Reason"
                  className="input_col aos-init aos-animate"
                  onChange={handleChange}
                ></textarea>
                <p>{error?.reason}</p>
          
                <input
                  type="text"
                  name="expected_pkg"
                  data-aos="zoom-out-up"
                  placeholder="Expected Package (in INR)"
                  className="input_col aos-init aos-animate"
                  onChange={handleChange}
                />
                <p>{error?.expected_pkg}</p>
                 
                <div className="fileUpload">
                  <span>+ Add Your CV</span>
                  <input
                    type="file"
                    name="resume"
                    className="upload"
                    onChange={handleChange}
                  />
                </div>
                <p>
                  {application?.resume instanceof File ? "File Uploaded" : ""}
                </p>
                <p>{error?.resume}</p>
                <button
                  data-aos="zoom-out-up"
                  type="submit"
                  className="submitBtn aos-init aos-animate"
                  value=""
                  disabled={loading}
                  onMouseEnter={() => setColor("#000")}
                  onMouseLeave={() => setColor("#fff")}
                >
                  {loading ? (
                    <ThreeDots
                      height="16"
                      
                      radius="9"
                      color={color}
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass="three-dots-loading"
                      visible={loading}
                    />
                  ) : (
                    "Apply"
                  )}
                </button>
              </form>
            </div>
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer  >
          {/* <Button variant="secondary" className="submitBtn " onClick={handleClose}>
            Close
          </Button> */}
          {/* <Button type="submit" className="submitBtn "  variant="primary" onClick={handleClose}>
            Submit
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
    
  );
};

export default CurrentOpening;
