import React from "react";
import { motion } from "framer-motion";

const ServiceFlow = () => {
    
  return (
    <div className="serivces_flow serivces_flow6" id="wrap">
      <div className="titles">
        <div className="wrapper">
          {/* <a href="javascript:void(0)" className="btn-blue">
                    Transforming Ideas into Reality
                    </a>
                    <motion.h3
                    >
                       Technology We Use
                    </motion.h3>
                    <p>
                    Powered by a diverse array of sophisticated tools and innovative platforms, our suite of technology stands at the forefront of industry standards.
                    </p> */}
        </div>
      </div>
      <div className="wrapper">
        <div className="service_flow_wrap">
          <svg
            width="830"
            height="1330"
            viewBox="0 0 830 1330"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_i_99_124)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 207.501C109.048 207.708 106.351 207.812 103.629 207.812C46.4674 207.812 0.128906 161.474 0.128906 104.312C0.128906 47.151 46.4674 0.8125 103.629 0.8125C124.168 0.8125 143.31 6.79531 159.407 17.1133C173.432 26.1034 188.868 33.8125 205.527 33.8125H554.129C602.178 33.8125 641.129 72.7637 641.129 120.812C641.129 168.861 602.178 207.812 554.129 207.812H119.129C116.632 207.812 114.16 207.707 111.717 207.501Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 207.003L111.718 207L111.678 207.003C109.022 207.208 106.338 207.312 103.629 207.312C46.7436 207.312 0.628906 161.198 0.628906 104.312C0.628906 47.4271 46.7436 1.3125 103.629 1.3125C124.07 1.3125 143.118 7.26633 159.137 17.5342C173.181 26.5359 188.718 34.3125 205.527 34.3125H554.129C601.902 34.3125 640.629 73.0398 640.629 120.812C640.629 168.585 601.902 207.312 554.129 207.312H119.129C116.646 207.312 114.188 207.208 111.759 207.003Z"
              stroke="#00E1DB"
            />
            <circle
              cx="103.629"
              cy="104.312"
              r="82.5"
              fill="url(#paint0_linear_99_124)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="104.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip0_99_124)">
              <path
                d="M112.3 104.546C112.049 106.601 111.125 108.56 109.602 110.083C107.752 111.935 105.165 112.806 101.916 112.675C99.6381 112.584 98.5859 113.18 98.4131 113.289C97.7152 113.9 96.8293 114.235 95.8941 114.235C94.8715 114.235 93.9101 113.837 93.1871 113.114C91.9391 111.866 91.7379 109.941 92.5899 108.474L93.2013 107.505C93.4091 106.998 93.6902 105.999 93.6246 104.383C93.4944 101.134 94.3662 98.5481 96.2168 96.6975C97.7327 95.1826 99.6709 94.2442 101.767 93.9937C102.063 92.5685 102.705 91.2352 103.631 90.1152H81.649C79.234 90.1152 77.2686 92.0796 77.2686 94.4946V116.216H122.966V94.6478L117.413 101.453C116.107 103.049 114.293 104.135 112.3 104.546Z"
                fill="#1375B3"
              />
              <path
                d="M77.2687 120.897C77.2687 123.311 79.2341 125.275 81.6492 125.275H94.0982V129.031H91.3389C90.4328 129.031 89.6982 129.766 89.6982 130.672C89.6982 131.578 90.4328 132.313 91.3389 132.313H108.895C109.801 132.313 110.536 131.578 110.536 130.672C110.536 129.766 109.801 129.031 108.895 129.031H106.136V125.275H118.585C121 125.275 122.966 123.311 122.966 120.897V119.497H77.2686V120.897H77.2687Z"
                fill="#1375B3"
              />
              <path
                d="M95.5471 109.982C95.5467 109.982 95.5464 109.982 95.5462 109.982C95.546 109.983 95.5459 109.983 95.5457 109.983L95.5453 109.983C95.5451 109.983 95.545 109.984 95.5448 109.984C95.5446 109.984 95.5445 109.984 95.5442 109.984L95.5441 109.985C95.544 109.985 95.5438 109.985 95.5436 109.985C95.5436 109.985 95.5436 109.985 95.5434 109.985C95.5433 109.985 95.5431 109.986 95.543 109.986C95.543 109.986 95.543 109.986 95.5429 109.986C95.5427 109.986 95.5426 109.986 95.5424 109.986C95.5424 109.986 95.5424 109.986 95.5422 109.987L95.5417 109.987C95.3343 110.201 95.3364 110.543 95.5478 110.754C95.6547 110.861 95.7946 110.914 95.9346 110.914C96.0733 110.914 96.2119 110.862 96.3184 110.757C96.3518 110.725 98.0085 109.194 102.09 109.358C104.386 109.45 106.146 108.9 107.323 107.724C109.734 105.313 109.734 101.39 107.323 98.9791C106.155 97.8112 104.602 97.168 102.951 97.168C101.299 97.168 99.7461 97.8111 98.5782 98.9791C97.4017 100.156 96.8519 101.916 96.9442 104.212C97.1091 108.316 95.5603 109.968 95.5478 109.981C95.5474 109.981 95.5473 109.981 95.5471 109.982Z"
                fill="#1375B3"
              />
              <path
                d="M112.158 101.174C113.228 100.853 114.191 100.219 114.91 99.3404L117.006 96.7714L109.538 89.3037L106.98 91.3932C106.1 92.1107 105.463 93.0732 105.142 94.1429C106.833 94.5399 108.383 95.4007 109.642 96.6596C110.931 97.9481 111.77 99.5176 112.158 101.174Z"
                fill="#1375B3"
              />
              <path
                d="M129.005 77.2977C127.783 76.076 125.852 75.9786 124.513 77.0724L112.093 87.2172L119.091 94.2158L129.232 81.7887C130.324 80.451 130.227 78.5194 129.005 77.2977Z"
                fill="#1375B3"
              />
            </g>
            <g filter="url(#filter1_i_99_124)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 644.501C109.048 644.708 106.351 644.812 103.629 644.812C46.4674 644.812 0.128906 598.474 0.128906 541.312C0.128906 484.151 46.4674 437.812 103.629 437.812C124.168 437.812 143.31 443.795 159.407 454.113C173.432 463.103 188.868 470.812 205.527 470.812H554.129C602.178 470.812 641.129 509.764 641.129 557.812C641.129 605.861 602.178 644.812 554.129 644.812H119.129C116.632 644.812 114.16 644.707 111.717 644.501Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 644.003L111.718 644L111.678 644.003C109.022 644.208 106.338 644.312 103.629 644.312C46.7436 644.312 0.628906 598.198 0.628906 541.312C0.628906 484.427 46.7436 438.312 103.629 438.312C124.07 438.312 143.118 444.266 159.137 454.534C173.181 463.536 188.718 471.312 205.527 471.312H554.129C601.902 471.312 640.629 510.04 640.629 557.812C640.629 605.585 601.902 644.312 554.129 644.312H119.129C116.646 644.312 114.188 644.208 111.759 644.003Z"
              stroke="#00E1DB"
            />
            <circle
              cx="103.629"
              cy="541.312"
              r="82.5"
              fill="url(#paint1_linear_99_124)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="541.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip1_99_124)">
              <path
                d="M110.423 517.923L106.737 517.399L105.094 514.151C104.537 513.046 102.723 513.046 102.166 514.151L100.523 517.399L96.8367 517.923C95.4843 518.114 94.9441 519.79 95.9364 520.736L98.5815 523.252L97.9583 526.796C97.725 528.129 99.1252 529.155 100.325 528.539L103.63 526.841L106.935 528.539C108.151 529.166 109.532 528.115 109.302 526.796L108.678 523.252L111.324 520.736C112.316 519.79 111.776 518.114 110.423 517.923Z"
                fill="#0051C5"
              />
              <path
                d="M130.22 527.928L126.533 527.404L124.891 524.156C124.334 523.051 122.52 523.051 121.962 524.156L120.32 527.404L116.634 527.928C115.281 528.119 114.741 529.794 115.733 530.741L118.378 533.257L117.755 536.801C117.522 538.134 118.922 539.16 120.122 538.544L123.427 536.845L126.732 538.544C127.954 539.169 129.328 538.113 129.099 536.8L128.475 533.256L131.121 530.741C132.113 529.794 131.572 528.119 130.22 527.928Z"
                fill="#0051C5"
              />
              <path
                d="M90.6264 527.928L86.9398 527.404L85.2974 524.156C84.7398 523.051 82.9262 523.051 82.3687 524.156L80.7264 527.404L77.0399 527.928C75.6874 528.119 75.1472 529.794 76.1395 530.741L78.7847 533.257L78.1615 536.801C77.9282 538.134 79.3284 539.16 80.5279 538.544L83.833 536.845L87.1383 538.544C88.3571 539.169 89.7349 538.116 89.5048 536.801L88.8815 533.257L91.5268 530.741C92.5189 529.794 91.9787 528.119 90.6264 527.928Z"
                fill="#0051C5"
              />
              <path
                d="M109.545 549.231C111.916 547.432 113.474 544.612 113.474 541.412C113.474 535.984 109.058 531.568 103.63 531.568C98.2017 531.568 93.7861 535.984 93.7861 541.412C93.7861 544.612 95.3438 547.431 97.7152 549.231C92.5139 551.517 88.8643 556.703 88.8643 562.74V567.662C88.8643 568.569 89.5981 569.303 90.5049 569.303H116.755C117.662 569.303 118.395 568.569 118.395 567.662V562.74C118.395 556.703 114.746 551.517 109.545 549.231Z"
                fill="#0051C5"
              />
            </g>
            <g filter="url(#filter2_i_99_124)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M111.717 1078.5C109.048 1078.71 106.351 1078.81 103.629 1078.81C46.4674 1078.81 0.128906 1032.47 0.128906 975.312C0.128906 918.151 46.4674 871.812 103.629 871.812C124.168 871.812 143.31 877.795 159.407 888.113C173.432 897.103 188.868 904.812 205.527 904.812H554.129C602.178 904.812 641.129 943.764 641.129 991.812C641.129 1039.86 602.178 1078.81 554.129 1078.81H119.129C116.632 1078.81 114.16 1078.71 111.717 1078.5Z"
                fill="white"
              />
            </g>
            <path
              d="M111.759 1078L111.718 1078L111.678 1078C109.022 1078.21 106.338 1078.31 103.629 1078.31C46.7436 1078.31 0.628906 1032.2 0.628906 975.312C0.628906 918.427 46.7436 872.312 103.629 872.312C124.07 872.312 143.118 878.266 159.137 888.534C173.181 897.536 188.718 905.312 205.527 905.312H554.129C601.902 905.312 640.629 944.04 640.629 991.812C640.629 1039.59 601.902 1078.31 554.129 1078.31H119.129C116.646 1078.31 114.188 1078.21 111.759 1078Z"
              stroke="url(#paint2_linear_99_124)"
            />
            <circle
              cx="103.629"
              cy="975.312"
              r="82.5"
              fill="url(#paint3_linear_99_124)"
            />
            <circle
              opacity="0.9"
              cx="103.629"
              cy="975.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip2_99_124)">
              <path
                d="M120.036 998.281H114.655L113.562 995H93.6981L92.6044 998.281H87.2236C86.317 998.281 85.583 999.015 85.583 999.922C85.583 1000.83 86.317 1001.56 87.2236 1001.56H120.036C120.943 1001.56 121.677 1000.83 121.677 999.922C121.677 999.015 120.943 998.281 120.036 998.281Z"
                fill="#1BB738"
              />
              <path
                d="M120.036 958.906C119.131 958.906 118.396 959.642 118.396 960.547C118.396 961.452 119.131 962.188 120.036 962.188C120.941 962.188 121.677 961.452 121.677 960.547C121.677 959.642 120.941 958.906 120.036 958.906Z"
                fill="#1BB738"
              />
              <path
                d="M126.599 949.062H80.5518C77.8379 949.062 75.6299 951.271 75.6299 953.984V981.875H85.583V976.953C85.583 976.047 86.317 975.312 87.2236 975.312H89.7585C89.9281 974.835 90.1221 974.367 90.3417 973.907L88.5485 972.113C87.9077 971.472 87.9077 970.434 88.5485 969.793L93.1884 965.153C93.8293 964.512 94.8675 964.512 95.5084 965.153L97.3028 966.947C97.7625 966.728 98.2303 966.533 98.708 966.363V963.828C98.708 962.922 99.442 962.188 100.349 962.188H106.911C107.818 962.188 108.552 962.922 108.552 963.828V966.363C109.029 966.533 109.497 966.728 109.957 966.947L111.751 965.153C112.392 964.513 113.43 964.513 114.071 965.153L118.711 969.793C119.352 970.434 119.352 971.472 118.711 972.113L116.918 973.907C117.138 974.367 117.332 974.835 117.501 975.312H120.036C120.943 975.312 121.677 976.047 121.677 976.953V981.875H131.63V953.984C131.63 951.271 129.312 949.062 126.599 949.062ZM120.036 965.469C117.322 965.469 115.114 963.261 115.114 960.547C115.114 957.833 117.322 955.625 120.036 955.625C122.75 955.625 124.958 957.833 124.958 960.547C124.958 963.261 122.75 965.469 120.036 965.469Z"
                fill="#1BB738"
              />
              <path
                d="M98.708 980.234C98.708 980.801 98.8387 981.348 99.0263 981.875H108.233C108.421 981.348 108.552 980.801 108.552 980.234C108.552 977.521 106.344 975.312 103.63 975.312C100.916 975.312 98.708 977.521 98.708 980.234Z"
                fill="#1BB738"
              />
              <path
                d="M92.5587 977.363C92.3715 978.087 91.7178 978.594 90.9693 978.594H88.8643V981.875H95.6425C95.5323 981.335 95.4268 980.792 95.4268 980.234C95.4268 975.712 99.1071 972.031 103.63 972.031C108.153 972.031 111.833 975.712 111.833 980.234C111.833 980.792 111.727 981.335 111.617 981.875H118.396V978.594H116.29C115.542 978.594 114.888 978.087 114.701 977.363C114.44 976.346 114.031 975.362 113.486 974.439C113.107 973.795 113.211 972.975 113.739 972.446L115.231 970.953L112.911 968.633L111.418 970.126C110.889 970.657 110.067 970.762 109.423 970.378C108.502 969.835 107.52 969.425 106.501 969.163C105.777 968.976 105.271 968.322 105.271 967.574V965.469H101.989V967.574C101.989 968.322 101.483 968.976 100.759 969.163C99.7398 969.425 98.7576 969.835 97.8364 970.378C97.1956 970.762 96.3718 970.657 95.8416 970.126L94.3484 968.633L92.0284 970.953L93.5204 972.446C94.0489 972.975 94.1531 973.795 93.7733 974.439C93.2286 975.362 92.8201 976.346 92.5587 977.363Z"
                fill="#1BB738"
              />
              <path
                d="M75.6299 986.797C75.6299 989.511 77.8379 991.719 80.5518 991.719H126.599C129.312 991.719 131.63 989.511 131.63 986.797V985.156H75.6299V986.797Z"
                fill="#1BB738"
              />
            </g>
            <g filter="url(#filter3_i_99_124)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M715.541 861.501C718.21 861.708 720.907 861.812 723.629 861.812C780.79 861.812 827.129 815.474 827.129 758.312C827.129 701.151 780.79 654.812 723.629 654.812C703.09 654.812 683.948 660.795 667.851 671.113C653.825 680.103 638.39 687.812 621.731 687.812H273.129C225.08 687.812 186.129 726.764 186.129 774.812C186.129 822.861 225.08 861.812 273.129 861.812H708.129C710.626 861.812 713.098 861.707 715.541 861.501Z"
                fill="white"
              />
            </g>
            <path
              d="M715.499 861.003L715.539 861L715.58 861.003C718.236 861.208 720.92 861.312 723.629 861.312C780.514 861.312 826.629 815.198 826.629 758.312C826.629 701.427 780.514 655.312 723.629 655.312C703.188 655.312 684.139 661.266 668.121 671.534C654.077 680.536 638.54 688.312 621.731 688.312H273.129C225.356 688.312 186.629 727.04 186.629 774.812C186.629 822.585 225.356 861.312 273.129 861.312H708.129C710.612 861.312 713.07 861.208 715.499 861.003Z"
              stroke="url(#paint4_radial_99_124)"
            />
            <circle
              cx="82.5"
              cy="82.5"
              r="82.5"
              transform="matrix(-1 0 0 1 806.129 675.812)"
              fill="url(#paint5_linear_99_124)"
            />
            <circle
              opacity="0.9"
              cx="59.1949"
              cy="59.1949"
              r="59.1949"
              transform="matrix(-1 0 0 1 782.823 699.117)"
              fill="white"
            />
            <g clip-path="url(#clip3_99_124)">
              <path
                d="M745.06 730.312H702.196C698.574 730.312 695.628 733.259 695.628 736.88V743.438H751.628V736.88C751.628 733.259 748.681 730.312 745.06 730.312ZM702.737 738.516C701.832 738.516 701.097 737.781 701.097 736.875C701.097 735.969 701.832 735.234 702.737 735.234C703.643 735.234 704.378 735.969 704.378 736.875C704.378 737.781 703.643 738.516 702.737 738.516ZM709.3 738.516C708.394 738.516 707.659 737.781 707.659 736.875C707.659 735.969 708.394 735.234 709.3 735.234C710.205 735.234 710.94 735.969 710.94 736.875C710.94 737.781 710.205 738.516 709.3 738.516ZM744.519 738.516H718.05C717.144 738.516 716.409 737.781 716.409 736.875C716.409 735.969 717.144 735.234 718.05 735.234H744.519C745.424 735.234 746.159 735.969 746.159 736.875C746.159 737.781 745.424 738.516 744.519 738.516Z"
                fill="#DDCA27"
              />
              <path
                d="M718.042 778.109C718.948 778.109 719.683 777.375 719.683 776.469C719.683 775.563 718.948 774.828 718.042 774.828C717.136 774.828 716.401 775.563 716.401 776.469C716.401 777.375 717.136 778.109 718.042 778.109Z"
                fill="#DDCA27"
              />
              <path
                d="M733.464 778.109C734.37 778.109 735.104 777.375 735.104 776.469C735.104 775.563 734.37 774.828 733.464 774.828C732.558 774.828 731.823 775.563 731.823 776.469C731.823 777.375 732.558 778.109 733.464 778.109Z"
                fill="#DDCA27"
              />
              <path
                d="M736.04 761.584C736.484 762.191 736.607 762.951 736.379 763.668L733.864 771.545H717.031L715.01 763.201V763.196L714.396 760.627H734.157C734.91 760.627 735.596 760.976 736.04 761.584Z"
                fill="#DDCA27"
              />
              <path
                d="M695.628 746.719V779.745C695.628 783.366 698.574 786.312 702.196 786.312H745.06C748.681 786.312 751.628 783.366 751.628 779.745V746.719H695.628ZM739.504 764.666L736.869 772.921C737.803 773.816 738.386 775.075 738.386 776.469C738.386 779.182 736.178 781.391 733.464 781.391C730.75 781.391 728.542 779.182 728.542 776.469C728.542 775.892 728.643 775.34 728.825 774.826H722.681C722.863 775.34 722.964 775.892 722.964 776.469C722.964 779.182 720.756 781.391 718.042 781.391C715.329 781.391 713.12 779.182 713.12 776.469C713.12 775.35 713.495 774.318 714.127 773.49L709.656 754.922H704.749C703.842 754.922 703.108 754.187 703.108 753.281C703.108 752.376 703.842 751.641 704.749 751.641H710.951C711.693 751.634 712.381 752.177 712.547 752.9L713.61 757.346H734.157C735.969 757.346 737.621 758.185 738.69 759.648C739.759 761.11 740.055 762.94 739.504 764.666Z"
                fill="#DDCA27"
              />
            </g>
            <g filter="url(#filter4_i_99_124)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M715.541 1295.5C718.21 1295.71 720.907 1295.81 723.629 1295.81C780.79 1295.81 827.129 1249.47 827.129 1192.31C827.129 1135.15 780.79 1088.81 723.629 1088.81C703.09 1088.81 683.948 1094.8 667.851 1105.11C653.825 1114.1 638.39 1121.81 621.731 1121.81H273.129C225.08 1121.81 186.129 1160.76 186.129 1208.81C186.129 1256.86 225.08 1295.81 273.129 1295.81H708.129C710.626 1295.81 713.098 1295.71 715.541 1295.5Z"
                fill="white"
              />
            </g>
            <path
              d="M715.499 1295L715.539 1295L715.58 1295C718.236 1295.21 720.92 1295.31 723.629 1295.31C780.514 1295.31 826.629 1249.2 826.629 1192.31C826.629 1135.43 780.514 1089.31 723.629 1089.31C703.188 1089.31 684.139 1095.27 668.121 1105.53C654.077 1114.54 638.54 1122.31 621.731 1122.31H273.129C225.356 1122.31 186.629 1161.04 186.629 1208.81C186.629 1256.59 225.356 1295.31 273.129 1295.31H708.129C710.612 1295.31 713.07 1295.21 715.499 1295Z"
              stroke="url(#paint6_linear_99_124)"
            />
            <circle
              cx="82.5"
              cy="82.5"
              r="82.5"
              transform="matrix(-1 0 0 1 806.129 1109.81)"
              fill="url(#paint7_linear_99_124)"
            />
            <circle
              opacity="0.9"
              cx="59.1949"
              cy="59.1949"
              r="59.1949"
              transform="matrix(-1 0 0 1 782.823 1133.12)"
              fill="white"
            />
            <g clip-path="url(#clip4_99_124)">
              <path
                d="M708.213 1199.5L711.117 1202.41L716.078 1197.4C711.312 1191.72 710.297 1184.21 712.513 1177.85L719.114 1184.45C719.058 1191.09 724.461 1196.89 731.512 1196.89C735.651 1196.89 739.322 1194.85 741.575 1191.73L734.08 1184.23L728.883 1189.43L709.555 1170.1V1174.48H706.165L706.166 1164.32H716.334V1167.7L711.952 1167.7L728.883 1184.64L734.08 1179.44L743.218 1188.58C744.717 1184.29 743.805 1179.25 740.278 1175.73C735.76 1171.21 728.664 1170.92 723.816 1174.77L718.336 1169.29C726.23 1162.43 738.209 1162.74 745.736 1170.27C749.535 1174.07 751.628 1179.12 751.628 1184.49C751.628 1195.59 742.604 1204.61 731.512 1204.61C726.675 1204.61 722.1 1202.91 718.464 1199.81L713.514 1204.8L716.431 1207.72L703.846 1220.3L695.628 1212.09L708.213 1199.5Z"
                fill="#1ED38D"
              />
              <path
                d="M724.453 1210.14H751.57V1213.53H724.453V1210.14Z"
                fill="#1ED38D"
              />
              <path
                d="M714.283 1216.92H734.621V1220.31H714.283V1216.92Z"
                fill="#1ED38D"
              />
            </g>
            <g filter="url(#filter5_i_99_124)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M717.541 424.501C720.21 424.708 722.907 424.812 725.629 424.812C782.79 424.812 829.129 378.474 829.129 321.312C829.129 264.151 782.79 217.812 725.629 217.812C705.09 217.812 685.948 223.795 669.851 234.113C655.825 243.103 640.39 250.812 623.731 250.812H275.129C227.08 250.812 188.129 289.764 188.129 337.812C188.129 385.861 227.08 424.812 275.129 424.812H710.129C712.626 424.812 715.098 424.707 717.541 424.501Z"
                fill="white"
              />
            </g>
            <path
              d="M717.499 424.003L717.539 424L717.58 424.003C720.236 424.208 722.92 424.312 725.629 424.312C782.514 424.312 828.629 378.198 828.629 321.312C828.629 264.427 782.514 218.312 725.629 218.312C705.188 218.312 686.139 224.266 670.121 234.534C656.077 243.536 640.54 251.312 623.731 251.312H275.129C227.356 251.312 188.629 290.04 188.629 337.812C188.629 385.585 227.356 424.312 275.129 424.312H710.129C712.612 424.312 715.07 424.208 717.499 424.003Z"
              stroke="#00E1DB"
            />
            <circle
              cx="724.629"
              cy="321.312"
              r="82.5"
              fill="url(#paint8_linear_99_124)"
            />
            <circle
              opacity="0.9"
              cx="724.629"
              cy="321.312"
              r="59.1949"
              fill="white"
            />
            <g clip-path="url(#clip5_99_124)">
              <path
                d="M699.91 342.641H730.573C730.008 341.672 729.66 340.56 729.66 339.359V336.078H698.27C697.363 336.078 696.629 336.812 696.629 337.719V339.359C696.629 341.171 698.098 342.641 699.91 342.641Z"
                fill="#E04713"
              />
              <path
                d="M699.91 304.906V332.797H729.66V313.109C729.66 309.49 732.603 306.547 736.223 306.547H749.348V304.906C749.348 302.192 747.14 299.984 744.426 299.984H704.832C702.118 299.984 699.91 302.192 699.91 304.906Z"
                fill="#E04713"
              />
              <path
                d="M749.348 309.828H736.223C734.414 309.828 732.941 311.301 732.941 313.109V339.359C732.941 341.168 734.414 342.641 736.223 342.641H749.348C751.157 342.641 752.629 341.168 752.629 339.359V313.109C752.629 311.301 751.157 309.828 749.348 309.828ZM742.785 314.75C743.691 314.75 744.426 315.484 744.426 316.391C744.426 317.297 743.691 318.031 742.785 318.031C741.879 318.031 741.145 317.297 741.145 316.391C741.145 315.484 741.879 314.75 742.785 314.75ZM744.426 337.719H741.145C740.238 337.719 739.504 336.985 739.504 336.078C739.504 335.171 740.238 334.438 741.145 334.438H744.426C745.333 334.438 746.066 335.171 746.066 336.078C746.066 336.985 745.333 337.719 744.426 337.719Z"
                fill="#E04713"
              />
            </g>
            <path
              d="M285.129 11.8125H558.129C618.328 11.8125 667.129 60.6135 667.129 120.812C667.129 181.012 618.328 229.812 558.129 229.812H272.129C211.93 229.812 163.129 278.613 163.129 338.812C163.129 399.012 211.93 447.812 272.129 447.812H558.129C618.328 447.812 667.129 496.613 667.129 556.812C667.129 617.012 618.328 665.812 558.129 665.812H272.129C211.93 665.812 163.129 714.613 163.129 774.812C163.129 835.012 211.93 883.812 272.129 883.812H558.129C618.328 883.812 667.129 932.613 667.129 992.812C667.129 1053.01 618.328 1101.81 558.129 1101.81H272.129C211.93 1101.81 163.129 1150.61 163.129 1210.81C163.129 1271.01 211.93 1319.81 272.129 1319.81H558.129"
              stroke="url(#paint9_linear_99_124)"
              stroke-width="20"
              stroke-linecap="round" id="filling_path"
            />
            <defs>
              <filter
                id="filter0_i_99_124"
                x="0.128906"
                y="0.8125"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_99_124"
                />
              </filter>
              <filter
                id="filter1_i_99_124"
                x="0.128906"
                y="437.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_99_124"
                />
              </filter>
              <filter
                id="filter2_i_99_124"
                x="0.128906"
                y="871.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_99_124"
                />
              </filter>
              <filter
                id="filter3_i_99_124"
                x="186.129"
                y="654.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_99_124"
                />
              </filter>
              <filter
                id="filter4_i_99_124"
                x="186.129"
                y="1088.81"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_99_124"
                />
              </filter>
              <filter
                id="filter5_i_99_124"
                x="188.129"
                y="217.812"
                width="641"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="11" />
                <feGaussianBlur stdDeviation="10" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_99_124"
                />
              </filter>
              <linearGradient
                id="paint0_linear_99_124"
                x1="103.629"
                y1="21.8125"
                x2="103.629"
                y2="186.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#1B91DA" />
                <stop offset="1" stop-color="#0E4D74" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_99_124"
                x1="103.629"
                y1="458.812"
                x2="103.629"
                y2="623.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#0065F4" />
                <stop offset="1" stop-color="#0F4798" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_99_124"
                x1="665.536"
                y1="871.812"
                x2="9.05439"
                y2="871.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#21D342" />
                <stop offset="1" stop-color="#258136" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_99_124"
                x1="103.629"
                y1="892.812"
                x2="103.629"
                y2="1057.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#21D342" />
                <stop offset="1" stop-color="#258136" />
              </linearGradient>
              <radialGradient
                id="paint4_radial_99_124"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(506.629 758.312) rotate(90) scale(103.5 320.5)"
              >
                <stop stop-color="#FFE81E" />
                <stop offset="1" stop-color="#C4B214" />
              </radialGradient>
              <linearGradient
                id="paint5_linear_99_124"
                x1="82.5"
                y1="0"
                x2="82.5"
                y2="165"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFE81E" />
                <stop offset="1" stop-color="#C4B214" />
              </linearGradient>
              <linearGradient
                id="paint6_linear_99_124"
                x1="506.629"
                y1="1088.81"
                x2="506.629"
                y2="1295.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#1EFFA8" />
                <stop offset="1" stop-color="#14C480" />
              </linearGradient>
              <linearGradient
                id="paint7_linear_99_124"
                x1="82.5"
                y1="0"
                x2="82.5"
                y2="165"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#1EFFA8" />
                <stop offset="1" stop-color="#14C480" />
              </linearGradient>
              <linearGradient
                id="paint8_linear_99_124"
                x1="724.629"
                y1="238.812"
                x2="724.629"
                y2="403.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FC490B" />
                <stop offset="1" stop-color="#C7471A" />
              </linearGradient>
              <linearGradient
                id="paint9_linear_99_124"
                x1="415.129"
                y1="883.812"
                x2="415.129"
                y2="1755.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#09C2C1" />
                <stop offset="1" stop-color="#1D85D8" />
              </linearGradient>
              <clipPath id="clip0_99_124">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.6299 76.3125)"
                />
              </clipPath>
              <clipPath id="clip1_99_124">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.6299 513.312)"
                />
              </clipPath>
              <clipPath id="clip2_99_124">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(75.6299 947.312)"
                />
              </clipPath>
              <clipPath id="clip3_99_124">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(695.628 730.312)"
                />
              </clipPath>
              <clipPath id="clip4_99_124">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(695.628 1164.31)"
                />
              </clipPath>
              <clipPath id="clip5_99_124">
                <rect
                  width="56"
                  height="56"
                  fill="white"
                  transform="translate(696.629 293.312)"
                />
              </clipPath>
            </defs>
          </svg>
          <div className="content_detail">
            <div className="col_detail">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/webflow1.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600" >
              Custom Website Development
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#004875" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
              We create fully customized websites that align with your brand, vision, and business goals. No templates, no limits – just unique, high-performing websites.
              </p>
            </div>
            <div className="col_detail html">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/webflow2.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
              Responsive & Mobile-Optimized Designs
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#C7471A" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
               In today’s mobile-first world, we ensure your website looks and works beautifully on any device – whether it's a phone, tablet, or desktop.
              </p>
            </div>
            <div className="col_detail css">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/webflow3.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
              User-Centric Experience
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#0F4798" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
              We focus on crafting intuitive and engaging user interfaces (UI) to provide visitors with seamless navigation and enhance overall user experience (UX).
              </p>
            </div>
            <div className="col_detail">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/webflow4.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
              E-commerce Solutions
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#C3B214" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
              Whether you’re starting an online store or scaling an existing one, our e-commerce solutions are designed to drive conversions and sales with secure, fast, and easy-to-manage platforms
              </p>
            </div>
            <div className="col_detail">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/webflow5.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
              Content Management Systems
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#21D342" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
              Manage your website with ease. We integrate user-friendly CMS platforms like WordPress, Joomla, and Drupal to let you update your content whenever you need.
              </p>
            </div>
            <div className="col_detail">
              <div data-aos="zoom-in-up" className="icons" data-aos-delay="600">
                <img src="./assets/images/portfolio/webflow6.svg" alt="" />
              </div>
              <h4 data-aos="zoom-in-up" data-aos-delay="600">
              SEO-Optimized Web Development
              </h4>
              <hr
                data-aos="zoom-in-up"
                data-aos-delay="600"
                style={{ borderColor: "#1EFFA8" }}
              />
              <p data-aos="zoom-in-up" data-aos-delay="600">
              We build websites that are not just visually appealing but also optimized for search engines to help you rank higher and be found online.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceFlow;
